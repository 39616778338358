import React,{useState, useEffect} from 'react';
import { Container, Col } from "react-bootstrap";

// img
import appLogo from '../../assets/img/app-logo.png';
// import android from '../../assets/img/android.svg';
// import androidThm from '../../assets/img/android_thm.svg';
// import iPhone from '../../assets/img/apple.svg';

function GetApp(props) {
    const [language, setLang] = useState(null);
    const [sitesetting, setSite] = useState([]);
    const getStarts = async () => {
            const url = props.api_url + 'api/getSiteSettings';
            const response = await fetch(url);
            const res = await response.json();
            let data = res.data;

            //return data;

            setSite(data);
    };

    if(sitesetting.length === 0){

        getStarts();
    }

    useEffect(() => {
       
        setTimeout(function(){
            let config_data = props.config;
           setLang(config_data.LANGUAGE[config_data.LANG_ID]);
            
        },2000)

        
    }, [props.config,language]);

    return (
        <section className="get-app">
            <Container fluid>
                <Col>
                    <div className="app-cont">
                        <div className="app-left">
                            <div className="app-icon">
                                <img src={appLogo} alt="Menuhuts" />
                            </div>

                            <h1 className="container-heading">
                            {language != null ? language.GET_YOUR : ''} <span>{language != null ? language.RESTAURANTS : ''} </span> {language != null ? language.IN_YOUR_OWN_POCKET : ''}
                        </h1>
                        </div>

                        <div className="app-redirect">
                            
                                
                                    <a className="outline" href={sitesetting.APP_LINK} target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-android"></i>
                                        <span>Android</span>
                                    </a>
                              
                            
                               
                                    <a className="outline" href={sitesetting.IPA_LINK} target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-apple"></i>
                                        <span>iPhone</span>
                                    </a>    
                                
                          
                        </div>
                    </div>
                </Col>
            </Container>
        </section>
    )
}


export default GetApp;