import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Form, InputGroup, Button, Row, Col, } from "react-bootstrap";


// img
import logo1 from '../../assets/img/rest-logo1.png';
import logo2 from '../../assets/img/rest-logo2.png';
import logo3 from '../../assets/img/rest-logo3.png';

import Skeleton from 'react-loading-skeleton';

//custom alert

import CustomAlert from '../alert/CustomAlert'

import BookingDetails from '../modal/BookingDetails'


function BookingHistory(props) {

    const [language, setLang] = useState(null);
    const [bookinghistory, setBookingHistory] = useState([]);
    const [foundbooking, setFoundBooking] = useState([]);
    const [loding, setLoding] = useState(true);

    useEffect(() => {

        setTimeout(function(){
            let config_data = props.config.config;
            setLang(config_data.LANGUAGE[config_data.LANG_ID]);
            getBookingHistory();
        },2000)
   
   }, [props.config.config,language]);

     // the value of the search field 
     const [search, setSearch] = useState('');

     //alert start
    const [alert_coustom, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }

    //alert end 

    async function getBookingHistory(){

        let userId = sessionStorage.getItem('userId') ;
        
            let res = await fetch(props.config.config.api.url + 'api/order/order', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    f: 'fetchbookingbyuser',
                    langId : sessionStorage.getItem('langId'),
                    userId: userId,
                    bId: (props.config.config.APP_TYPE) ? 0 : props.config.config.BUSINESS_ID
                })
            });
            let result = await res.json();
            
            if (result) {
                setLoding(false);
                setBookingHistory(result);
                setFoundBooking(result);  
            } 
       }

       const filter = (e) => {
        const keyword = e;
    
        if (keyword !== '') {
          const results = bookinghistory.filter((bus) => {
            return bus.bname.toLowerCase().startsWith(keyword.toLowerCase());
            // Use the toLowerCase() method to make it case-insensitive
          });
          setFoundBooking(results);
        } else {
            setFoundBooking(bookinghistory);
          // If the text field is empty, show all users
        }
    
        setSearch(keyword);
      };

    return (
        <>
            <h1 className="container-heading"><span>Booking</span> history</h1>
            <Form>
                <Form.Group>
                    <Row>
                        <Col md={12} sm={12}>
                            <InputGroup className="grey">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><i className="fas fa-search"></i></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control id="" placeholder="Search" onChange={(e)=> { setSearch(e.target.value); filter(e.target.value)}}/>
                            </InputGroup>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>

            <CustomAlert
                    header={language != null ? language.BOOKING_HISTORY : ''}
                    btnText={language != null ? language.ACCEPT : ''}
                    text={alert_coustom.text}
                    type={alert_coustom.type}
                    show={alert_coustom.show}
                    onClosePress={onCloseAlert}
                    pressCloseOnOutsideClick={true}
                    alertStyles={{}}
                    headerStyles={{}}
                    textStyles={{}}
                    buttonStyles={{}}
             />  

                { loding === false && foundbooking.length > 0 ?

                <Business BookingHistory={foundbooking} language={language} config={props.config.config}/>   


                : loding === true ?
                <div className="business-item-cont">
                        {[...Array(3)].map((item, i) => (
                        
                            <div key={i} className="business-item">
                            
                                <Skeleton className="business-logo"  />
                                <div className="business-description">
                                    <div className="busn-details">
                                        <h3><Skeleton width={300} height={25} /></h3>
                                        <ul>
                                            <li className="address"><Skeleton width={300} height={25} /></li>
                                        </ul>
                                    </div>

                                </div>
                            </div> 
                        ))}       
                </div>   

                : loding === false && foundbooking.length == 0 ? 
                    <div className="business-item-cont">
                            
                        <div className="business-item">
                            
                            <div className="business-description">
                                <div className="busn-details">
                                    <h3>{language != null ? language.NO_BOOKING : ""}</h3>
                                    
                                </div>

                            </div>
                        </div> 
                                    
                    </div>   
                : ""
                }  

           {/* <div className="business-item-cont">
                <div className="business-item">
                    <img src={logo1} className="business-logo" alt="Menuhuts" />
                    <div className="business-description">
                        <div className="busn-details">
                            <h3>Jack in the Box (400 Geary St)</h3>
                            <ul className="other">
                                <li className="ordered">2 items for $31.98</li>
                                <li className="delivery-status">21-01-2020  11:00</li>
                                <li className="receipt"><Link to={``}>View receipt</Link></li>
                            </ul>
                        </div>

                        <div className="busn-right">
                            <span className="order-status">Pending</span>
                        </div>
                    </div>
                </div>

                <div className="business-item">
                    <img src={logo3} className="business-logo" alt="Menuhuts" />
                    <div className="business-description">
                        <div className="busn-details">
                            <h3>Jack in the Box (400 Geary St)</h3>
                            <ul className="other">
                                <li className="ordered">2 items for $31.98</li>
                                <li className="delivery-status">21-01-2020  11:00</li>
                                <li className="receipt"><Link to={``}>View receipt</Link></li>
                            </ul>
                        </div>

                        <div className="busn-right">
                            <span className="order-status">Pending</span>
                        </div>
                    </div>
                </div>

           </div>*/}
        </>
    );
}


const Business = React.memo(({ BookingHistory, language, config}) => {

    return (
 
             <div className="business-item-cont">
       
            {BookingHistory.map((booking) => {
               // const { id, name, mobile, address, ratings, is_img, is_banner, open, slug } = bus;
                let bImage;
                if (booking.is_img.is_img === 1) {
                    bImage = booking.is_img.data.secure_url;
                } else {
                    bImage = logo1;
                }
               
                return (
                    
                    <div key={booking.id} className="business-item">
                        <img src={bImage} className="business-logo" alt="Menuhuts" />
                        <div className="business-description">
                            <div className="busn-details">
                                <h3>{booking.bname}</h3>
                                <ul className="other">
                                    
                                    <li className="delivery-status">{booking.bookingdate} - {booking.bookingtime}</li>
                                    <li className="ordered">{language != null ? language.PERSON : ""} - {booking.person}</li>
                                    <li className="receipt"><BookingDetails config={config} language={language} booking={booking}></BookingDetails></li>
                                </ul>
                            </div>

                            <div className="busn-right">

                                {
                                   booking.status == '0' ?
                                   
                                   <span className="order-status pending-color">&nbsp;{booking.statustext}</span>

                                   : booking.status == '6' || booking.status == '7' || booking.status == '8' ?

                                   <span className="order-status cencel-color"><i className=" fa fa-times" aria-hidden="true"></i> &nbsp;{booking.statustext}</span>

                                   : booking.status == '1' || booking.status == '2' || booking.status == '3' || booking.status == '4' || booking.status == '5' ? 
                                   <span className="order-status success-color"><i className="fa fa-check" aria-hidden="true"></i> &nbsp;{booking.statustext}</span>
                                   : ""
                                   
                                }
                               

                            </div>
                        </div>
                    </div>
                    
                );
            })}

          </div>  
       
    )

})

export default BookingHistory;