import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import { Container, Col, Form, Button, InputGroup, } from "react-bootstrap";


// Own Pages
//import Cuisine from './Cuisine';
import Btype from './Btype';


let autoComplete;

const loadScript = (url, callback) => {
    let id = 'googleMaps'
    if (document.getElementById(id) === null) {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.id = id;

        if (script.readyState) {
            script.onreadystatechange = function () {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {
            script.onload = () => callback();
        }

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    }else{
        callback(); 
    }
};

function handleScriptLoad(updateQuery, updateLocation, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current
    );
    autoComplete.setFields(["address_components", "formatted_address", "geometry"]);
    autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(updateQuery, updateLocation)
    );
}

async function handlePlaceSelect(updateQuery, updateLocation) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    console.log(addressObject);
    let zip;
    for (let i = 0; i < addressObject.address_components.length; i++) {
        for (let j = 0; j < addressObject.address_components[i].types.length; j++) {
            if (addressObject.address_components[i].types[j] === 'postal_code') {
                zip = addressObject.address_components[i].long_name;
            }
        }
    }
    let lctn;
    lctn = {
        address: addressObject.formatted_address,
        latitude: addressObject.geometry.location.lat(),
        longitude: addressObject.geometry.location.lng(),
        zipcode: zip,
        zoom: 12
    }
    updateLocation(lctn);
}

const Banner = props => {
    const [query, setQuery] = useState("");
    const [location, setLocation] = useState({
        address: '',
        latitude: '',
        longitude: '',
        zipcode: '',
        zoom: 12
    });

    const [language, setLang] = useState(null);

    const [isLoading, setLoading] = useState(false);
    const autoCompleteRef = useRef(null);

    const history = useHistory();

    const doSearch = () => {
        if (location.latitude === '' && location.longitude === '') {
            alert('Choose Address from google autocomplete')
            return
        }
        setLoading(true);
        history.push('/search/' + location.latitude + ',' + location.longitude);
        console.log(location, query);
    }

    //AIzaSyAwWmYexCDWu-rTj2J2ngCje4JP0MFnYSk

    useEffect(() => {

        setTimeout(function(){
            let config_data = props.config;
            setLang(config_data.LANGUAGE[config_data.LANG_ID]);
           
        },2000)
        loadScript(
            'https://maps.googleapis.com/maps/api/js?key=' + props.config.google_map_api_key + '&libraries=places',
            () => handleScriptLoad(setQuery, setLocation, autoCompleteRef)
        );
    }, [props.config,language]);

    return (
        <section className="banner">
            <Container fluid>
                <Col>
                    <h1>{language != null ? language.YOUR_FAVORITE_RESTAURANTS : ''}</h1>
                    <h4>{language != null ? language.DIRECT_TO_YOUR_DOOR_IN_FEW_MIN : ''}</h4>
                                       

                    <Form>
                        <Form.Group>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text><i className="fas fa-map-marker-alt"></i></InputGroup.Text>
                                </InputGroup.Prepend>
                                <input ref={autoCompleteRef}
                                    onChange={event => setQuery(event.target.value)}
                                    className="form-control" placeholder={language != null ? language.ENTER_BUSINESS_NEAR_ME_BY_ZIPCODE : ''}
                                    value={query} />
                            </InputGroup>

                            <Button variant="primary" disabled={isLoading} onClick={!isLoading ? doSearch : null} >{isLoading ? 'Loading…' : language != null ? language.SEARCH : ''}</Button>

                        </Form.Group>
                    </Form>
                </Col>
            </Container>
            { /*<Cuisine config={props.config} />*/}
            <Btype config={props.config} />
        </section>
    );
}

export default Banner;