import React from "react";

// components
import Banner from "./Banner";
import BreadCrumbs from "./BreadCrumbs";
import Listing from "./Listing";

import { loginsubscriber } from "../../components/loginService";

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      business: [],
      favbusiness: [],
      user: {},
      lastid: 0,
      endId: 0,
      isLoading: false,
      btype: [],
      selected_btype: 0,
      business_fetched: 0,
      business_autoload_complete: 0,
      allCategory: [],
      allCuisine: [],
      allCuisineIds: [],
      filterBusiness: [],
      ip_lat: "",
      ip_long: "",
    };
  }

  businessTypeChange = async (bid) => {
    await this.setState({
      business: [],
      favbusiness: [],
      user: {},
      lastid: 0,
      endId: 0,
      isLoading: false,
      selected_btype: bid,
      business_fetched: 0,
      business_autoload_complete: 0,
      allCategory: [],
      allCuisine: [],
      allCuisineIds: [],
      filterBusiness: [],
    });

    const query = window.location.pathname;
    const pathArr = query.split("/");

    if (pathArr[1] === "city") {
      this.cityCall(pathArr[2], 0, bid);
    } else if (pathArr[1] === "cuisine") {
      this.cuisineCall(pathArr[2], 0, bid);
    } else if (pathArr[1] === "businesstype") {
      this.businesstypeCall(bid, 0);
    } else if (pathArr[1] === "search") {
      let lctn;
      lctn = pathArr[2].split(",");
      this.doSearch(lctn, bid);
    }
  };

  componentDidMount() {
    const query = window.location.pathname;
    const pathArr = query.split("/");
    if (pathArr[1] === "city") {
      this.cityCall(pathArr[2], this.state.lastid, this.state.selected_btype);
      // this.getBusiness(this.props.config.api.url + 'api/businessByCity?' + pathArr[2]);
      this.businessType();
    } else if (pathArr[1] === "cuisine") {
      this.cuisineCall(
        pathArr[2],
        this.state.lastid,
        this.state.selected_btype
      );
      this.businessType();
    } else if (pathArr[1] === "businesstype") {
      this.businesstypeCall(pathArr[2], this.state.lastid);
      this.businessType();
    } else if (pathArr[1] === "search") {
      let lctn;
      lctn = pathArr[2].split(",");
      this.doSearch(lctn, this.state.selected_btype);
      this.businessType();
    }

    loginsubscriber.subscribe((v) => {
      if (v) {
        let user = {
          id: sessionStorage.getItem("userId"),
          name: sessionStorage.getItem("userName"),
          last_name: sessionStorage.getItem("userLastName"),
        };
        this.setState({
          user: user,
        });
        if (
          sessionStorage.getItem("userId") !== undefined &&
          sessionStorage.getItem("userId") !== null
        ) {
          let userId = sessionStorage.getItem("userId");
          this.getFavBusiness(this.props.config.api.url + "api/fav?" + userId);
        }
      }
    });

    if (
      sessionStorage.getItem("userId") !== undefined &&
      sessionStorage.getItem("userId") !== null
    ) {
      let userId = sessionStorage.getItem("userId");
      this.getFavBusiness(this.props.config.api.url + "api/fav?" + userId);
    }
  }
  getFavBusiness = async (urlFav) => {
    const response = await fetch(urlFav);
    const favbusiness = await response.json();
    let data = favbusiness.data;
    this.setState({
      favbusiness: data,
    });
  };
  cityCall = async (cityID, lid, businesstypeId) => {
    let ip_lat = sessionStorage.getItem("ip_lat");

    if (ip_lat == null) {
      const response = await fetch(this.props.config.api.url + "api/ipinfo");
      let ip = await response.json();
      await this.setState({ ip_lat: ip[0] });
      await this.setState({ ip_long: ip[1] });

      sessionStorage.setItem("ip_lat", ip[0]);
      sessionStorage.setItem("ip_long", ip[1]);
    } else {
      let ip_lat = sessionStorage.getItem("ip_lat");
      let ip_long = sessionStorage.getItem("ip_lat");

      await this.setState({ ip_lat: ip_lat });
      await this.setState({ ip_long: ip_long });
    }

    if (this.state.ip_lat != "" && this.state.ip_long != "") {
      this.setState({ business_fetched: 0 });
      this.setState({ business_autoload_complete: 0 });
      this.setState({ isLoading: true });
      let res = await fetch(this.props.config.api.url + "api/citySearch", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          city: cityID,
          lastid: lid,
          businesstype: businesstypeId,
          lat: this.state.ip_lat,
          long: this.state.ip_long,
          userid: sessionStorage.getItem("userId"),
        }),
      });
      let result = await res.json();
      if (result && result.success === true) {
        var joined = this.state.business.concat(result.data);
        await this.setState({ business: joined });

        lid = result.lastid;
        this.setState({ lastid: lid });
        this.setState({ endId: result.endId });

        this.getAllCategoryCuisine();

        sessionStorage.setItem(
          "searchbusiness",
          JSON.stringify(this.state.business)
        );

        //if (result.lastid < result.endId) {
        if (result.endId > result.lastid) {
          setTimeout(() => this.cityCall(cityID, lid, businesstypeId), 3000);
        } else {
          this.setState({ business_autoload_complete: 1 });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState({ btn: false });
      }
    }
  };
  cuisineCall = async (cuisineId, lid, businesstypeId) => {
    this.setState({ business_fetched: 0 });
    this.setState({ business_autoload_complete: 0 });
    this.setState({ isLoading: true });
    let res = await fetch(this.props.config.api.url + "api/cuisineSearch", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cuisine: cuisineId,
        lastid: lid,
        businesstype: businesstypeId,
      }),
    });
    let result = await res.json();
    if (result && result.success === true) {
      var joined = this.state.business.concat(result.data);
      await this.setState({ business: joined });

      lid = result.lastid;
      this.setState({ lastid: lid });
      this.setState({ endId: result.endId });
      this.setState({ business_fetched: 1 });

      this.getAllCategoryCuisine();

      sessionStorage.setItem(
        "searchbusiness",
        JSON.stringify(this.state.business)
      );

      if (result.lastid < result.endId) {
        setTimeout(
          () => this.cuisineCall(cuisineId, lid, businesstypeId),
          3000
        );
      } else {
        this.setState({ business_autoload_complete: 1 });
      }
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  };

  applyFilter = async (
    filterObject_order,
    filterObject_status,
    filterObject_cat,
    filterObject_cuisine
  ) => {
    await this.setState({
      business: JSON.parse(sessionStorage.getItem("searchbusiness")),
    });

    let filterBusiness = JSON.parse(sessionStorage.getItem("searchbusiness"));

    this.setState({ filterBusiness: sessionStorage.getItem("searchbusiness") });
    sessionStorage.setItem(
      "filteredbusiness",
      sessionStorage.getItem("searchbusiness")
    );

    if (filterObject_order.length !== 0) {
      await this.setState({ business: [] });

      for (const i of filterBusiness) {
        if (
          filterObject_order.indexOf(1) !== -1 &&
          filterObject_order.indexOf(2) !== -1
        ) {
          if (i.is_delivery === "1" && i.is_pickup === "1") {
            var order = this.state.business.concat(i);
            await this.setState({ business: order });
          }
        } else if (
          filterObject_order.indexOf(1) !== -1 &&
          filterObject_order.indexOf(2) === -1
        ) {
          if (i.is_delivery === "1") {
            var order_1 = this.state.business.concat(i);
            await this.setState({ business: order_1 });
          }
        } else if (
          filterObject_order.indexOf(1) === -1 &&
          filterObject_order.indexOf(2) !== -1
        ) {
          if (i.is_pickup === "1") {
            var order_2 = this.state.business.concat(i);
            await this.setState({ business: order_2 });
          }
        } else {
          var order_3 = this.state.business.concat(i);
          await this.setState({ business: order_3 });
        }
      }

      this.setState({ filterBusiness: this.state.business });
      sessionStorage.setItem(
        "filteredbusiness",
        JSON.stringify(this.state.business)
      );
    }

    let filterBusiness_2 = JSON.parse(
      sessionStorage.getItem("filteredbusiness")
    );

    if (filterObject_status.length !== 0) {
      await this.setState({ business: [] });
      for (const i of filterBusiness_2) {
        if (
          filterObject_status.indexOf(1) === -1 &&
          filterObject_status.indexOf(0) === -1
        ) {
          var status_1 = this.state.business.concat(i);
          await this.setState({ business: status_1 });
        } else {
          if (filterObject_status.indexOf(1) !== -1) {
            if (i.open === true) {
              var status_2 = this.state.business.concat(i);
              await this.setState({ business: status_2 });
            }
          }
          if (filterObject_status.indexOf(0) !== -1) {
            if (i.open === false) {
              var status_3 = this.state.business.concat(i);
              await this.setState({ business: status_3 });
            }
          }
        }
      }

      this.setState({ filterBusiness: this.state.business });
      sessionStorage.setItem(
        "filteredbusiness",
        JSON.stringify(this.state.business)
      );
    }

    let filterBusiness_3 = JSON.parse(
      sessionStorage.getItem("filteredbusiness")
    );

    if (filterObject_cuisine.length !== 0) {
      await this.setState({ business: [] });
      for (const i of filterBusiness_3) {
        let counter = 0;
        for (const k of filterObject_cuisine) {
          for (const j of i.cuisinelist) {
            if (k.id == j.id && k.selected == true && counter == 0) {
              var cuisine_1 = this.state.business.concat(i);
              await this.setState({ business: cuisine_1 });
              counter++;
            }
          }
        }
      }
      this.setState({ filterBusiness: this.state.business });
      sessionStorage.setItem(
        "filteredbusiness",
        JSON.stringify(this.state.business)
      );
    }

    let filterBusiness_4 = JSON.parse(
      sessionStorage.getItem("filteredbusiness")
    );

    if (filterObject_cat.length !== 0) {
      await this.setState({ business: [] });

      for (const i of filterBusiness_4) {
        let counter = 0;
        for (const k of filterObject_cat) {
          for (const j of i.category) {
            if (k.id == j.id && k.selected == true && counter == 0) {
              var cat_1 = this.state.business.concat(i);
              await this.setState({ business: cat_1 });
              counter++;
            }
          }
        }
      }
      this.setState({ filterBusiness: this.state.business });
      sessionStorage.setItem(
        "filteredbusiness",
        JSON.stringify(this.state.business)
      );
    }
  };

  getAllCategoryCuisine = () => {
    for (const j of this.state.business) {
      for (const k of j.category) {
        let pp = 0;
        if (this.state.allCategory.length > 0) {
          for (const p of this.state.allCategory) {
            if (p.name.toLowerCase() === k.name.toLowerCase()) {
              pp = 1;
              p.ids.push(k.id);
            }
          }
        }
        if (pp !== 1) {
          var joined = this.state.allCategory.concat(k);
          this.setState({ allCategory: joined });
        }
        if (this.state.allCategory.length === 0) {
          var joined_2 = this.state.allCategory.concat(k);
          this.setState({ allCategory: joined_2 });
        }
        this.state.allCategory.sort((a, b) => {
          let nameA;
          nameA = a.name.toLowerCase();
          let nameB;
          nameB = b.name.toLowerCase();
          if (nameA < nameB) {
            // sort string ascending
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0; // default return value (no sorting)
        });
      }
      for (const l of j.cuisinelist) {
        if (this.state.allCuisineIds.indexOf(l.id) === -1) {
          var joined_3 = this.state.allCuisine.concat(l);

          this.setState({ allCuisine: joined_3 });
          this.state.allCuisine.sort((a, b) => {
            let nameA;
            nameA = a.name.toLowerCase();
            let nameB;
            nameB = b.name.toLowerCase();
            if (nameA < nameB) {
              // sort string ascending
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0; // default return value (no sorting)
          });

          var joined_4 = this.state.allCuisineIds.concat(l.id);
          this.setState({ allCuisineIds: joined_4 });
        }
      }
    }
  };

  businesstypeCall = async (businesstypeId, lid) => {
    /*const response = await fetch('https://jsonip.com');
        const ips = await response.json();
        const responseIP = await fetch('https://www.iplocate.io/api/lookup/' + ips.ip);
        const ipsAddress = await responseIP.json();*/

    let ip_lat = sessionStorage.getItem("ip_lat");

    if (ip_lat == null) {
      const response = await fetch(this.props.config.api.url + "api/ipinfo");
      let ip = await response.json();
      await this.setState({ ip_lat: ip[0] });
      await this.setState({ ip_long: ip[1] });

      sessionStorage.setItem("ip_lat", ip[0]);
      sessionStorage.setItem("ip_long", ip[1]);
    } else {
      let ip_lat = sessionStorage.getItem("ip_lat");
      let ip_long = sessionStorage.getItem("ip_lat");

      await this.setState({ ip_lat: ip_lat });
      await this.setState({ ip_long: ip_long });
    }

    if (this.state.ip_lat != "" && this.state.ip_long != "") {
      this.setState({ isLoading: true });
      this.setState({ business_fetched: 0 });
      this.setState({ business_autoload_complete: 0 });

      this.setState({ selected_btype: businesstypeId });

      let res = await fetch(
        this.props.config.api.url + "api/businessTypeSearch",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            businesstype: businesstypeId,
            lastid: lid,
            lat: this.state.ip_lat,
            long: this.state.ip_long,
            userid: sessionStorage.getItem("userId"),
          }),
        }
      );
      let result = await res.json();
      if (result && result.success === true) {
        var joined = this.state.business.concat(result.data);
        await this.setState({ business: joined });

        lid = result.lastid;
        this.setState({ lastid: lid });
        this.setState({ endId: result.endId });
        this.setState({ business_fetched: 1 });

        this.getAllCategoryCuisine();

        sessionStorage.setItem(
          "searchbusiness",
          JSON.stringify(this.state.business)
        );

        //if (result.lastid < result.endId) {
        if (result.endId > result.lastid) {
          setTimeout(() => this.businesstypeCall(businesstypeId, lid), 3000);
        } else {
          console.log(this.state.business);
          this.setState({ business_autoload_complete: 1 });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: false });
      }
    }
  };
  predoSearch = (lctn) => {
    if (!lctn[0]) {
      alert("Enter valid Address");
      return;
    }
    if (!lctn[1]) {
      alert("Enter valid Address");
      return;
    }
    try {
      this.setState({ business: [] });
      this.searchCall(lctn, 0, this.state.selected_btype);
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };
  doSearch = (lctn, bid) => {
    if (!lctn[0]) {
      alert("Enter valid Address");
      return;
    }
    if (!lctn[1]) {
      alert("Enter valid Address");
      return;
    }
    try {
      this.searchCall(lctn, 0, bid);
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  searchCall = async (lctn, lid, businesstypeId) => {
    this.setState({ business_fetched: 0 });
    this.setState({ business_autoload_complete: 0 });
    this.setState({ isLoading: true });
    let res = await fetch(this.props.config.api.url + "api/addressSearch", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        latitude: +lctn[0],
        longitude: +lctn[1],
        lastid: lid,
        userid: sessionStorage.getItem("userId"),
        businesstype: businesstypeId,
      }),
    });
    let result = await res.json();
    if (result && result.success === true) {
      var joined = this.state.business.concat(result.data);
      await this.setState({ business: joined });

      lid = result.lastid;
      this.setState({ lastid: lid });
      this.setState({ endId: result.endId });
      this.setState({ business_fetched: 1 });

      this.getAllCategoryCuisine();

      sessionStorage.setItem(
        "searchbusiness",
        JSON.stringify(this.state.business)
      );

      if (result.endId > result.lastid) {
        setTimeout(() => this.searchCall(lctn, lid, businesstypeId), 3000);
      } else {
        this.setState({ business_autoload_complete: 1 });
      }
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  };

  businessType = async () => {
    //const { btype } = useBusinessTypeFetch(this.props.config.api.url + 'api/allBusinessType');

    let res = await fetch(this.props.config.api.url + "api/allBusinessType", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    let result = await res.json();

    if (result && result.success === true) {
      this.state.btype = result.data;
    }
  };

  render() {
    return (
      <>
        <Banner
          config={this.props.config}
          businessTypeChange_r={this.businessTypeChange}
          btype={this.state.btype}
          selected_btype={this.state.selected_btype}
          isLoading={this.state.isLoading}
          onClick={this.predoSearch}
        />
        <BreadCrumbs
          config={this.props.config}
          allCategory={this.state.allCategory}
          allCuisine={this.state.allCuisine}
          applyFilter_r={this.applyFilter}
        />
        <Listing
          getFavBusiness={this.getFavBusiness}
          config={this.props.config}
          business={this.state.business}
          favbusiness={this.state.favbusiness}
          business_autoload_complete={this.state.business_autoload_complete}
          business_fetched={this.state.business_fetched}
          lastId={this.state.lastid}
          endId={this.state.endId}
        />
      </>
    );
  }
}

export default List;
