import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import { Dropdown, Button } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";

// components

function Cart(props) {
  const history = useHistory();
  const [totalCost, setTotalCost] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [redirectToCheckout, setRedirectToCheckout] = useState(false);
  const [isCheckoutClicked, setIsCheckoutClicked] = useState(false);

  const handleToClose = (event, reason) => {
    if ("clickaway" === reason) return;

    setIsCheckoutClicked(false);
  };

  const increaseQty = (x, i) => {
    let cart = JSON.parse(sessionStorage.getItem("cart"));
    cart = cart.map((cartItem, index) => {
      if (cartItem.product.id === x.product.id && index === i)
        return {
          product: {
            ...cartItem.product,
            qty: cartItem.product.qty + 1,
          },
        };
      else return cartItem;
    });
    sessionStorage.setItem("cart", JSON.stringify(cart));
    props.setIsCartUpdated(true);
  };

  const decreaseQty = (x, i) => {
    let cart = JSON.parse(sessionStorage.getItem("cart"));
    cart = cart
      .map((cartItem, index) => {
        if (cartItem.product.id === x.product.id && index === i) {
          if (cartItem.product.qty <= 1) {
            let strArr = [];
            cartItem.product.sets.forEach((set) => {
              set.options.forEach((option) => {
                option.choice.forEach((ch) => {
                  let s;
                  if (ch.checked)
                    s = `${set.id}-${option.id}-${ch.id}-${cartItem.product.id}`;
                  if (s) strArr.push(s);
                });
              });
            });
            let choicesArr = JSON.parse(
              sessionStorage.getItem("selectedChoices")
            );
            if (cartItem.product.sets.length > 0)
              choicesArr = choicesArr
                .filter((chStr) => {
                  console.log(strArr);
                  if (!strArr.includes(chStr)) {
                    return chStr;
                  } else {
                    strArr.forEach((str, index) => {
                      if (str === chStr) {
                        strArr.splice(index, 1);
                        return;
                      }
                    });
                    return null;
                  }
                })
                .filter((item) => item !== null);
            sessionStorage.setItem(
              "selectedChoices",
              JSON.stringify(choicesArr)
            );
            return null;
          } else
            return {
              product: {
                ...cartItem.product,
                qty: cartItem.product.qty - 1,
              },
            };
        } else return cartItem;
      })
      .filter((x) => x !== undefined && x !== null);
    sessionStorage.setItem("cart", JSON.stringify(cart));
    props.setIsCartUpdated(true);
  };

  useEffect(() => {
    if (!props.cart) return;
    console.log(props.cart.length);
    if (props.cart.length > 0) {
      let tc = props.cart.reduce((acc, curr) => {
        let x = curr.product.sets
          .map((set) => {
            let y = set.options
              .map((op) =>
                op.choice.map((ch) => {
                  if (ch.checked) {
                    return parseFloat(ch.price);
                  } else return 0.0;
                })
              )
              .reduce((acc, curr) => {
                let curr2 = curr.reduce((acc3, curr3) => {
                  return acc3 + curr3;
                }, 0);
                return acc + curr2;
              }, 0);
            return y;
          })
          .reduce((acc, curr) => {
            return acc + curr;
          }, 0);
        return acc + curr.product.qty * (x + parseFloat(curr.product.price));
      }, 0);
      let ti = props.cart.reduce((acc, curr) => {
        return acc + curr.product.qty;
      }, 0);
      setTotalItems(ti);
      setTotalCost(tc);
    } else {
      setTotalCost(0);
      setTotalItems(0);
    }
    props.setIsCartUpdated(false);
  }, [props.isCartUpdated, props.cart]);

  useEffect(() => {
    sessionStorage.setItem("cartTotal", totalCost);
  }, [totalCost]);

  useEffect(() => {
    if (redirectToCheckout && isCheckoutClicked) {
      history.push("/checkout");
      window.location.reload();
    }
  }, [redirectToCheckout, isCheckoutClicked]);

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("user-details")))
      setRedirectToCheckout(true);
    else setRedirectToCheckout(false);
  }, [isCheckoutClicked]);

  useEffect(() => {
    setIsCheckoutClicked(false);
    if (JSON.parse(sessionStorage.getItem("user-details")))
      setRedirectToCheckout(true);
    else setRedirectToCheckout(false);
  }, []);

  return (
    <Router>
      <Dropdown className="cart">
        <Snackbar
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          open={!redirectToCheckout && isCheckoutClicked}
          autoHideDuration={4000}
          message={`Please login before checking out`}
          onClose={handleToClose}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleToClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
        <Dropdown.Toggle id="dropdown-basic">
          <i className="fas fa-shopping-bag"></i> <span>{totalItems}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="cart-board">
          <h1>Your order</h1>

          <h3 className="order-from">
            <span>From</span>{" "}
            <Link to={`#`}>
              {props?.cartBusiness?.name} ({props?.cartBusiness?.address})
            </Link>
          </h3>

          <div className="added-item-cont">
            {props.cart &&
              props.cart.map((item, index) => {
                let initialCost = parseFloat(item.product.price),
                  variableCost = 0;
                item.product?.sets.forEach((set) =>
                  set?.options.forEach((op) =>
                    op?.choice.forEach((ch) => {
                      if (ch.checked) variableCost += parseFloat(ch.price);
                    })
                  )
                );
                let finalCost = (initialCost + variableCost) * item.product.qty;
                return (
                  <div key={index}>
                    <div className="added-item">
                      <div className="added-item-options-div">
                        <div
                          className="added-item-options-div-btn"
                          onClick={() => decreaseQty(item, index)}
                        >
                          -
                        </div>
                        <div className="added-item-options-div-item-count">
                          {item.product.qty}
                        </div>
                        <div
                          className="added-item-options-div-btn"
                          onClick={() => increaseQty(item, index)}
                        >
                          +
                        </div>
                      </div>
                      <div className="added-description">
                        <div className="product-desc">
                          <h5 className="p-name">{item.product.name}</h5>
                          <div>
                            <ul style={{ textTransform: "capitalize" }}>
                              {props?.selectedIngredients?.map(
                                (ingredientItem, i) => {
                                  if (
                                    item.product.id === ingredientItem.productId
                                  )
                                    return ingredientItem.ingredientNames.map(
                                      (iname, i2) => {
                                        return (
                                          <li key={`ingredient-${i2}`}>
                                            Ingredient: {iname}
                                          </li>
                                        );
                                      }
                                    );
                                }
                              )}
                              <ul style={{ listStyleType: "circle" }}>
                                {item.product.sets.map((set) => {
                                  return set.options.map((op) => {
                                    return op.choice.map((ch) => {
                                      if (ch.checked)
                                        return (
                                          <li
                                            key={`${set.id}-${op.id}-${ch.id}-${item.product.id}`}
                                          >{`${op.name} : ${ch.name}`}</li>
                                        );
                                      else return null;
                                    });
                                  });
                                })}
                              </ul>
                            </ul>
                          </div>
                        </div>

                        <div className="product-right">
                          <h5 className="p-price">
                            {props.cartBusiness.currency_symbol}
                            {finalCost}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div className="added-item">
              <div
                className="added-description"
                style={{
                  width: "64%",
                  marginRight: 0,
                  marginLeft: "auto",
                }}
              >
                <div className="product-desc">
                  <h5 className="p-name">Delivery Fee</h5>
                </div>
                <div className="product-right">
                  <h5 className="p-price">
                    {props?.cartBusiness?.currency_symbol}
                    {props.deliveryPrice}
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <Button
            variant="secondary"
            onClick={() => {
              setIsCheckoutClicked(true);
            }}
          >
            <span className="total-items">{totalItems}</span>
            Checkout
            <span className="total-price">
              {props?.cartBusiness?.currency_symbol}{" "}
              {(totalCost + parseFloat(props.deliveryPrice))
                .toString()
                .split(".")
                .reduce((acc, curr, index) => {
                  if (index === 0) return acc + curr;
                  else return acc + "." + curr.substring(0, 2);
                }, "")}
            </span>
          </Button>

          <span className="free-delivery">
            Add items worth $10 more for <span>FREE Delivery</span>
          </span>
        </Dropdown.Menu>
      </Dropdown>
    </Router>
  );
}

export default withRouter(Cart);
