import React, { useState,useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { Modal, Form, Button} from 'react-bootstrap';

//custom alert

import CustomAlert from '../alert/CustomAlert'

import './style.css'

let autoComplete;

function handleScriptLoad(updateQuery, setUlocation_r, setBulocation_r, setAddress_r, SetAddRessFlag_r, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current
    );
    autoComplete.setFields(["address_components", "formatted_address", "geometry"]);
    autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(updateQuery, setUlocation_r, setBulocation_r, setAddress_r, SetAddRessFlag_r)
    );
}

async function handlePlaceSelect(updateQuery, setUlocation_r, setBulocation_r, setAddress_r, SetAddRessFlag_r) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    
    if (addressObject.geometry === undefined ||addressObject.geometry === null) {
        return;
      } else {
        let Bulocation_city = "";
        for (let i = 0; i < addressObject.address_components.length; i++) {
          for (let j = 0; j < addressObject.address_components[i].types.length; j++) {
            if (addressObject.address_components[i].types[j] === 'postal_code') {
              setUlocation_r({zip : addressObject.address_components[i].long_name});
              setBulocation_r({zipcode: addressObject.address_components[i].long_name});
            }
            let placeaddress = '';
            placeaddress = addressObject.address_components[i].types[j];

            if (placeaddress == 'administrative_area_level_1') {
                // this is the object you are looking for
                Bulocation_city = addressObject.address_components[i].long_name ?? '';
                
                break;
            }
          }
        }

        setUlocation_r({ lat: addressObject.geometry.location.lat(), lng : addressObject.geometry.location.lng(), address: addressObject.formatted_address});

        setAddress_r(addressObject.formatted_address);
        setBulocation_r({latitud : addressObject.geometry.location.lat(), longitud : addressObject.geometry.location.lng(), city: Bulocation_city});
        SetAddRessFlag_r(1);
      }

}

function AddBusiness(props) {  

    const autoCompleteRef = useRef(null);
    const [query, setQuery] = useState("");
    
    const [addressFlag, SetAddRessFlag] = useState(0);
    const [ulocation, setUlocation] = useState({
        address: '',
        lat: '',
        lng: '',
        zip: ''
      });
  
    const [bulocation, setBulocation] = useState({
        latitud: '',
        longitud: '',
        zipcode: '',
        city: '',
        zoom: 16
      });
   
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile_number, setMobileNumber] = useState("");
    const [business_name, setBusinessName] = useState("");
    const [address, setAddress] = useState("");
    const [accepting, setAccepting] = useState(false); 
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');  

      
    const [language, setLang] = useState(null);

    const [show, setShow] = useState(false);

    const addBusinessClose = () => setShow(false);
    const addBusinessShow = () => setShow(true);

    const [allcountry, setAllCountry] = useState([]);
    const [cityByCountry, setCityByCountry] = useState([]);
   
    useEffect ( () => {
        setTimeout(function(){
            let config_data = props.config;
            setLang(config_data.LANGUAGE[config_data.LANG_ID]); 

            //props.config.google_map_api_key
                (async () => {
                    let res = await fetch(props.config.api.url + 'api/common/common', {
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            f: 'fetchallcountry',
                            langId : sessionStorage.getItem('langId'),
                        })
                    });
                    let result = await res.json();
                    
                    if (result.fetchallcountry) {
        
                        setAllCountry(result.fetchallcountry);  
                      } 
                  })();

                       
        },2000)

        if(show === true){
            setTimeout(function(){
                handleScriptLoad(setQuery, setUlocation, setBulocation, setAddress, SetAddRessFlag,  autoCompleteRef)
        
            },2000);
        }


    },[props.config,language, show]);

    async function ChangeCountry(cid){

       setCountry(cid);
       setCity('');

       let res = await fetch(props.config.api.url + 'api/common/common', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                f: 'fetchcitybycountry',
                langId : sessionStorage.getItem('langId'),
                cid: cid,
            })
        });
        let result = await res.json();
        
        if (result.fetchcitybycountry) {

            setCityByCountry(result.fetchcitybycountry);  
          } 

    }


    //alert start

    const [alert_value, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }

    //alert end 


    const getFormData = async () =>{

        let flg = true;
        const all_error_msg = [];

        if (first_name.trim() === '') {
           
            all_error_msg.push(language.ENTER_FIRST_NAME);

            if(flg === true){
                flg = false;
            }

          }
          if (last_name.trim() === '') {
            all_error_msg.push(language.ENTER_YOUR_LAST_NAME);

            if(flg === true){
                flg = false;
            }
          }
          if (email.trim() === '') {
                        
            all_error_msg.push(language.ENTER_YOUR_EMAIL);

            if(flg === true){
                flg = false;
            }
          }
          let atpos;
          atpos = email.indexOf('@');
          let dotpos;
          dotpos = email.lastIndexOf('.');
          if(email.trim() !== ''){
                if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= email.length) {
                    all_error_msg.push(language.ENTER_VALID_EMAIL_ADDRESS);

                    if(flg === true){
                        flg = false;
                    }
                }
            }

            if (mobile_number.trim() === '') {
                all_error_msg.push(language.ENTER_YOUR_MOBILE);
    
                if(flg === true){
                    flg = false;
                }
            }else if(mobile_number.trim() !== ''){
                if(mobile_number.length < 10){
                    all_error_msg.push(language.ENTER_YOUR_MOBILE_MIN_DIGIT);
    
                    if(flg === true){
                        flg = false;
                    }
                }
            }


            if (business_name.trim() === '') {
                all_error_msg.push(language.ENTER_BUSINESS_NAME);
    
                if(flg === true){
                    flg = false;
                }
              }  


          if (country === '') {
            all_error_msg.push(language.PLEASE_SELECT_COUNTRY);

            if(flg === true){
                flg = false;
            }
          }

          if (city === '') {
            all_error_msg.push(language.PLEASE_SELECT_CITY);

            if(flg === true){
                flg = false;
            }
          }

          if (address.trim() === '') {
            all_error_msg.push(language.ENTER_YOUR_ADDRESS);

            if(flg === true){
                flg = false;
            }
          }

          if (accepting === false) {
            all_error_msg.push(language.CHECK_PRIVACY_TERMS);

            if(flg === true){
                flg = false;
            }
          }

          if(flg === true){
            if (addressFlag === 0) {
                
                all_error_msg.push(language.ENTER_CORRECT_ADDRESS);

                if(flg === true){
                    flg = false;
                }
              }
          }

          if(flg === true){

               try {
                    let res = await fetch(props.config.api.url + 'api/user/buser', {
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            f: "register",
                            langId : sessionStorage.getItem('langId'),
                            firstname : first_name,
                            lastname: last_name,
                            email: email,
                            address: address,
                            cel: mobile_number,
                            bname: business_name,
                            country: country,
                            city: city,
                            location: ulocation,
                            blocation: bulocation,
                        })
                    });
                    let result = await res.json();
                    console.log(result);
                    if (result.register.status) {

                        all_error_msg.push(language.REGISTER_DONE_BUSINESS_OWNER);

                        onShowAlert('success',all_error_msg); 

                        setQuery("");
    
                        SetAddRessFlag(0);
                        setUlocation({address: '', lat: '', lng: '', zip: ''});

                        setBulocation({latitud: '', longitud: '', zipcode: '', city: '', zoom: 16 });

                        setFirstName("");
                        setLastName("");
                        setEmail("");
                        setMobileNumber("");
                        setBusinessName("");
                        setAddress("");
                        setAccepting(false); 
                        setCountry("");
                        setCity("");  
                        

                        setTimeout(
                            function() {
                                setShow(false);
                            }
                            ,
                            5000
                        );
                        setTimeout(
                            function() {
                                onCloseAlert();  
                            }
                            ,
                            4000
                        );
                        
                    } else {
                        all_error_msg.push(result.register.message);

                        onShowAlert('error',all_error_msg); 
                    }
                }
                catch (e) {
                console.log(e);
                }
            }else{

                onShowAlert('error',all_error_msg); 
            }        

    }
 

    return (
        language != null ?
        <>

        {
            props.ref_from === "header" ?

            <Link to={`#`} className="add-business" onClick={addBusinessShow}>{language != null ? language.ADD_BUSINESS : ''}</Link>

            :

            <Link to={`#`} className="add-business" onClick={addBusinessShow}>{language != null ? language.CREATE_BUSINESS_ACCOUNT : ''}</Link>
        }
        
            
            <Modal show={show} onHide={addBusinessClose}>
                <Modal.Header closeButton>
                    <h1 className="modal-title"><span>{language != null ? language.LETS : ''}</span> {language != null ? language.GET_STARTED : ''}</h1>
                </Modal.Header>
                <Modal.Body>

                <CustomAlert
                    header={language != null ? language.ADD_BUSINESS : ''}
                    btnText={language != null ? language.ACCEPT : ''}
                    text={alert_value.text}
                    type={alert_value.type}
                    show={alert_value.show}
                    onClosePress={onCloseAlert}
                    pressCloseOnOutsideClick={true}
                    alertStyles={{}}
                    headerStyles={{}}
                    textStyles={{}}
                    buttonStyles={{}}
                    />  


                    <Form>
                        <Form.Group>
                            { /*<Form.Label>Enter your first name</Form.Label>*/ }
                            <Form.Control type="text" className="box" placeholder={language != null ? language.NAME : ''} onChange={(e)=>setFirstName(e.target.value)}/>
                        </Form.Group>

                        <Form.Group>
                            { /*<Form.Label>Enter your last name</Form.Label>*/ }
                            <Form.Control type="text" className="box" placeholder={language != null ? language.LAST_NAME : ''} onChange={(e)=>setLastName(e.target.value)}/>
                        </Form.Group>

                        <Form.Group>
                            { /*<Form.Label>{language != null ? language.EMAIL : ''}</Form.Label>*/ }
                            <Form.Control type="text" className="box" placeholder={language != null ? language.EMAIL : ''} onChange={(e)=>setEmail(e.target.value)}/>
                        </Form.Group>

                        <Form.Group>
                            { /*<Form.Label>Enter your mobile number</Form.Label>*/ }
                            <Form.Control type="text" onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} className="box" placeholder={language != null ? language.MOBILE_NUMBER : ''} onChange={(e)=>setMobileNumber(e.target.value)}/>
                        </Form.Group>

                        <Form.Group>
                            { /*<Form.Label>Enter your business name</Form.Label>*/ }
                            <Form.Control type="text" className="box" placeholder={language != null ? language.BUSINESS_NAME : ''} onChange={(e)=>setBusinessName(e.target.value)}/>
                        </Form.Group>

                        <Form.Group>
                            { /*<Form.Label>Select your country</Form.Label>*/ }
                            <div className="select-group">
                                <Form.Control as="select" className="box" onChange={(e)=>ChangeCountry(e.target.value)}>
                                    <option value="">{language != null ? language.CHOOSE_COUNTRY : ''}</option>

                                    {allcountry?.map((country, index) => {
                                        return(
                                            <option key={index} value={country.id} >{country.name}</option>
                                        )                   
                                    })}
                                    
                                </Form.Control>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            { /*<Form.Label>Select your city</Form.Label>*/ }
                            <div className="select-group">
                                <Form.Control as="select" className="box" onChange={(e)=>setCity(e.target.value)}>
                                    <option value="">{language != null ? language.CHOOSE_CITY : ''}</option>
                                    {cityByCountry?.map((city, index) => {
                                        return(
                                            <option key={index} value={city.id} >{city.name}</option>
                                        )                   
                                    })}
                                </Form.Control>
                            </div>
                        </Form.Group>

                        <Form.Group>

                          <Form.Control type="text" className="box"
                            name='address'
                            placeholder="Address"
                            ref={autoCompleteRef}
                            onChange={event => setQuery(event.target.value)}
                            value={query}
                            id="address-input"
                            />

                       </Form.Group>  
                      

                        <Form.Group>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="check1" onChange={(e)=>setAccepting(e.target.value)}/>
                                <label className="form-check-label" htmlFor="check1">
                                {language != null ? language.PLEASE_CHECK_THE : ''} <a className="secondary" href={language != null ? language.TERMS_SERVICE_LINK : ''} target="_blank" rel="noreferrer">{language != null ? language.TERMS_SERVICE : ''}</a> {language != null ? language.AND : ''} <a className="secondary" href={language != null ? language.PRIVACY_POLICY_LINK : ''} target="_blank" rel="noreferrer">{language != null ? language.PRIVACY_POLICY : ''}</a>
                                </label>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <Button type="button" className="btn btn-secondary box" onClick={getFormData}>{language != null ? language.REGISTER : ''} </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>                            
        </>

        : ''
        
        
    );
}

export default AddBusiness;