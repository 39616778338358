import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";

function PreOrder({
  business,
  config,
  menus,
  setMenus,
  setSelectedMenuNumber,
  setDate,
  setTime,
}) {
  const [show, setShow] = useState(false);
  const [selectedMenuNo, setSelectedMenuNo] = useState(null);

  const preOrderClose = () => setShow(false);
  const preOrderShow = () => setShow(true);

  const getBusinessMenu = async () => {
    try {
      const x = await fetch(`${config.api.url}api/business/business`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          f: "getbusinessmenu",
          bid: business.id,
          langId: sessionStorage.getItem("langId"),
          orderType: sessionStorage.getItem("orderType"),
          preOrderDetails: "",
        }),
      });
      setMenus((await x.json())?.menu);
    } catch (err) {
      console.log(err);
    }
  };

  const getSelectedBusinessMenu = async (preOrderDetails) => {
    try {
      const x = await fetch(`${config.api.url}api/business/business`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          f: "getbusinessmenu",
          bid: business.id,
          langId: sessionStorage.getItem("langId"),
          orderType: sessionStorage.getItem("orderType"),
          preOrderDetails: preOrderDetails,
        }),
      });
      setMenus((await x.json())?.menu);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getBusinessMenu();
  }, []);

  return (
    <>
      <Link to={`#`} onClick={preOrderShow}>
        Preorder
      </Link>

      <Modal show={show} onHide={preOrderClose}>
        <Modal.Header closeButton>
          <h1 className="modal-title">
            <span>Pre</span> order
          </h1>
        </Modal.Header>
        <Modal.Body>
          <div className="business-detl">
            <img
              src={JSON.parse(business.is_img).data.url}
              className="busn-logo"
              alt="Menuhuts"
            />
            <h3>
              {business.name} ({JSON.parse(business.street)["1"]})
            </h3>
          </div>
          <Form>
            <Form.Group className="divide-item pre-order-option">
              <h4>Please choose any Menu</h4>
              {menus?.map((item, index) => (
                <Row key={index}>
                  <Col sm={12}>
                    <Form.Check
                      type="radio"
                      name="current_selected_menu"
                      label={item.name}
                      value={index}
                      onChange={(e) => setSelectedMenuNo(e.target.value)}
                    />
                  </Col>
                </Row>
              ))}
            </Form.Group>

            <Form.Group className="divide-item pre-order-option no-border">
              <h4>Select Date and Time</h4>
              <Row>
                <Col md={6} sm={12}>
                  <div className="select-group">
                    <Form.Control
                      as="input"
                      type="date"
                      min={(() => {
                        let d = new Date();
                        let yy = d.getFullYear();
                        let mm = d.getMonth() + 1;
                        let dd = d.getDate();
                        if (mm < 10) mm = `0${mm}`;
                        if (dd < 10) dd = `0${dd}`;
                        return `${yy}-${mm}-${dd}`;
                      })()}
                      max={(() => {
                        let d = new Date();
                        let yy = d.getFullYear();
                        let mm = d.getMonth() + 1;
                        let dd = d.getDate() + 6;
                        if ([1, 3, 5, 7, 8, 10, 12].includes(mm) && dd > 31) {
                          const extraDays = dd - 31;
                          dd = extraDays;
                          mm++;
                          if (mm > 12) {
                            yy++;
                            mm = 1;
                          }
                        } else if ([4, 6, 9, 11].includes(mm) && dd > 30) {
                          const extraDays = dd - 31;
                          dd = extraDays;
                          mm++;
                          if (mm > 12) {
                            yy++;
                            mm = 1;
                          }
                        } else if (
                          (yy % 4 === 0 && yy % 400 !== 0 && mm > 29) ||
                          ((yy % 4 !== 0 || (yy % 4 === 0 && yy % 400 === 0)) &&
                            mm > 28)
                        ) {
                          const extraDays = dd - 31;
                          dd = extraDays;
                          mm++;
                          if (mm > 12) {
                            yy++;
                            mm = 1;
                          }
                        }
                        if (mm < 10) mm = `0${mm}`;
                        if (dd < 10) dd = `0${dd}`;
                        return `${yy}-${mm}-${dd}`;
                      })()}
                      className="box"
                      defaultValue="0"
                      onInput={(e) => {
                        let x = e.target.value;
                        x = x.split("-");
                        x = `${x[2]}-${x[1]}-${x[0]}`;
                        setDate(x);
                      }}
                    ></Form.Control>
                  </div>
                </Col>

                <Col md={6} sm={12}>
                  <div className="select-group">
                    <Form.Control
                      as="input"
                      type="time"
                      className="box"
                      defaultValue="0"
                      onInput={(e) => setTime(e.target.value)}
                    ></Form.Control>
                  </div>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Button
                type="submit"
                variant="secondary"
                className="box"
                onClick={(e) => {
                  e.preventDefault();
                  if (selectedMenuNo !== null)
                    getSelectedBusinessMenu(menus[selectedMenuNo]);
                  else alert("Please select a menu");
                  preOrderClose();
                }}
              >
                Pre order
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default PreOrder;
