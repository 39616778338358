import React from "react";
import { Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

// image
import offer1 from "./../../../assets/img/offer-1.jpg";
import offer2 from "./../../../assets/img/offer-2.jpg";
import offer3 from "./../../../assets/img/offer-3.jpg";
import offer4 from "./../../../assets/img/offer-4.jpg";

class Offers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offer: [],
    };
  }
  componentDidMount() {
    if (this.props?.business?.id > 0) {
      this.getOffersBusiness(this.props?.business?.id);
    }
  }
  getOffersBusiness = async (id) => {
    const response = await fetch(
      this.props.config.api.url + "api/autoDiscount?" + id
    );
    const photo = await response.json();
    let data = photo.data;
    this.setState({ offer: data });
  };
  render() {
    return (
      <>
        <div className="other-container business-offers">
          <Row>
            {this.state.offer?.length > 0 ? (
              <>
                {this.state.offer?.map((val, index) => {
                  return (
                    <Col lg={4} md={4} sm={6} xs={6} key={val.id}>
                      <div
                        className="offer-card"
                        style={{
                          backgroundImage:
                            index + (1 % 4) === 1
                              ? `url(${offer1})`
                              : index + (1 % 4) === 2
                              ? `url(${offer2})`
                              : index + (1 % 4) === 3
                              ? `url(${offer3})`
                              : `url(${offer4})`,
                          color: "#fff",
                        }}
                      >
                        <div className="offer-description">
                          <h2>{val.distext}</h2>
                          <h4 className="offer-code">
                            {parseInt(val.d_type) === 2
                              ? val.percent + "%"
                              : this.props?.business?.symbol +
                                " " +
                                val.price}{" "}
                            Off
                          </h4>
                          <span className="footnote">
                            Minimum Purchase: {this.props?.business?.symbol}{" "}
                            {val.min_purchase}
                          </span>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </>
            ) : (
              <div
                style={{
                  width: "fit-content",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <span>No offers available</span>
              </div>
            )}
          </Row>
        </div>
      </>
    );
  }
}

export default Offers;
