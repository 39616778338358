import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Container, Col, Form, InputGroup } from "react-bootstrap";

//Modal
import PreOrder from "../modal/PreOrder";
import BusinessTab from "./BusinessTab";

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activekey: "",
      menus: [],
      selectedMenuNumber: -1,
      date: "",
      time: "",
    };
  }

  setDate = (x) => {
    this.setState({
      date: x,
    });
  };

  setTime = (x) => {
    this.setState({
      time: x,
    });
  };

  setMenus = (x) => {
    this.setState({
      menus: x,
    });
  };

  setSelectedMenuNumber = (x) => {
    this.setState({
      selectedMenuNumber: parseInt(x),
    });
  };

  componentWillReceiveProps(props) {
    this.setState({ activekey: props.activekey });
  }

  getAlert() {
    alert("getAlert from Child");
  }

  render() {
    return (
      <Router>
        {this.props?.business?.id > 0 ? (
          <OfferContainer
            business={this.props?.business}
            orderType={this.props?.orderType}
          />
        ) : (
          ""
        )}

        <section className="business-description">
          <Container fluid>
            <Col>
              {this.props?.business?.id > 0 ? (
                <AddressContainer
                  business={this.props?.business}
                  config={this.props?.config}
                  orderType={this.props?.orderType}
                  setOrderType={this.props?.setOrderType}
                  menus={this.state.menus}
                  setMenus={this.setMenus}
                  setSelectedMenuNumber={this.setSelectedMenuNumber}
                  setDate={this.setDate}
                  setTime={this.setTime}
                />
              ) : (
                ""
              )}
            </Col>
            <Col className="main-nav-container">
              {this.props?.business?.id > 0 ? (
                <BusinessTab
                  {...this.props}
                  date={this.state.date}
                  time={this.state.time}
                  menus={this.state.menus}
                  selectedMenuNumber={this.state.selectedMenuNumber}
                />
              ) : (
                ""
              )}
            </Col>
          </Container>
        </section>
      </Router>
    );
  }
}

const OfferContainer = React.memo(({ business, orderType }) => {
  return (
    <>
      {(business.freedeliverystatus === 1 && orderType !== 2) ||
      (business.firstorderstatus === 1 && business.ordercountstatus) ? (
        <section className="business-offer">
          <Container fluid>
            <Col>
              <ul>
                {business.freedeliverystatus === 1 && orderType !== 2 ? (
                  <li>
                    Delivery free minimum order {business.symbol}{" "}
                    {business.freedeliveryvalue}
                  </li>
                ) : (
                  ""
                )}
                {business.firstorderstatus === 1 &&
                business.ordercountstatus ? (
                  <li>{business.firstorderoffer}% OFF for first time order</li>
                ) : (
                  ""
                )}
              </ul>
            </Col>
          </Container>
        </section>
      ) : (
        ""
      )}
    </>
  );
});

const AddressContainer = React.memo(
  ({
    business,
    config,
    orderType,
    setOrderType,
    menus,
    setMenus,
    setSelectedMenuNumber,
    setDate,
    setTime,
  }) => {
    let { opens, closes, opens2, closes2 } = business.today_schedule;

    if (opens.minute.length === 1) opens.minute = `0${opens.minute}`;
    if (opens2.minute.length === 1) opens2.minute = `0${opens2.minute}`;
    if (closes.minute.length === 1) closes.minute = `0${closes.minute}`;
    if (closes2.minute.length === 1) closes2.minute = `0${closes2.minute}`;

    let { street } = business;
    if (street) {
      street = JSON.parse(street);
      street = street[Object.keys(street)[0]];
    }

    return (
      <>
        <div className="board">
          <ul>
            <li>
              <i className="fas fa-map-marker-alt"></i> {street}
            </li>
            <li>{business.mobile}</li>
            <li>
              Open Time: {opens.hour}:{opens.minute} - {closes.hour}:
              {closes.minute} & {opens2.hour}:{opens2.minute} - {closes2.hour}:
              {closes2.minute}
            </li>
          </ul>
          <ul>
            <li>
              <Form className="order-type-change">
                <Form.Group>
                  <InputGroup className="select-group">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <i className="fas fa-clock"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      as="select"
                      value={orderType}
                      onChange={(e) => {
                        setOrderType(e.target.value, 0.0, {});
                      }}
                    >
                      <option value="0">Select Order Type</option>
                      <option value="1">Delivery</option>
                      <option value="2">Pickup</option>
                    </Form.Control>
                  </InputGroup>
                </Form.Group>
              </Form>
            </li>
            <li>
              <PreOrder
                business={business}
                config={config}
                menus={menus}
                setMenus={setMenus}
                setSelectedMenuNumber={setSelectedMenuNumber}
                setDate={setDate}
                setTime={setTime}
              />
            </li>

            {business.payment?.length > 0
              ? business.payment?.map((pay) => {
                  return pay.name === "paypal" ? (
                    <li key={pay.id} tooltip={pay.displayName}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.898"
                        height="20.623"
                        viewBox="0 0 16.898 20.623"
                      >
                        <g id="paypal" transform="translate(-0.001)">
                          <path
                            id="Path_12"
                            data-name="Path 12"
                            d="M84.345,127.843v.089a6.554,6.554,0,0,1-2.422,5.378,7.253,7.253,0,0,1-4.446,1.528H74.642c-.044,0-.142,0-.212.008a.96.96,0,0,0-.029.143.335.335,0,0,1-.008.054l-.906,5a1.87,1.87,0,0,1-1.841,1.538h-.173l-.063.409a1.153,1.153,0,0,0,1.138,1.327h2.2a1.151,1.151,0,0,0,1.133-.947l.8-4.39v-.026c.073-.533.29-.558.7-.558h2.492a5.873,5.873,0,0,0,3.6-1.235,5.283,5.283,0,0,0,1.942-4.341V130.5a3.706,3.706,0,0,0-1.066-2.653Zm0,0"
                            transform="translate(-68.521 -122.694)"
                          />
                          <path
                            id="Path_13"
                            data-name="Path 13"
                            d="M3.74,17.123l.906-5A1.276,1.276,0,0,1,6.121,10.9H8.957a5.436,5.436,0,0,0,5.621-5.66V3.74c0-2.3-1.878-3.74-4.165-3.74H6.121C2.834.041,2.584-.022,1.956,4.175L.008,16.916a.626.626,0,0,0,.618.721h2.5a.625.625,0,0,0,.615-.514Zm0,0"
                          />
                        </g>
                      </svg>
                    </li>
                  ) : pay.name === "cash" ? (
                    <li key={pay.id} tooltip={pay.displayName}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30.761"
                        height="16.504"
                        viewBox="0 0 30.761 16.504"
                      >
                        <g id="cash" transform="translate(-5858.889 263.05)">
                          <path
                            id="Path_2492"
                            data-name="Path 2492"
                            d="M88.235,18.625H61.394a1.962,1.962,0,0,0-1.96,1.96V33.169a1.962,1.962,0,0,0,1.96,1.96H88.235a1.962,1.962,0,0,0,1.96-1.96V20.585A1.962,1.962,0,0,0,88.235,18.625ZM84.444,33.6H65.187a4.9,4.9,0,0,0-4.224-4.409V24.354a4.9,4.9,0,0,0,4.2-4.2h19.3a4.9,4.9,0,0,0,4.2,4.2v4.837A4.9,4.9,0,0,0,84.444,33.6Zm4.223-13.015v2.222a3.37,3.37,0,0,1-2.652-2.653h2.221A.432.432,0,0,1,88.667,20.585Zm-27.273-.431h2.222a3.37,3.37,0,0,1-2.654,2.654V20.585A.432.432,0,0,1,61.394,20.153Zm-.431,13.015V30.738A3.368,3.368,0,0,1,63.65,33.6H61.394A.432.432,0,0,1,60.962,33.169Zm27.273.432H85.981a3.368,3.368,0,0,1,2.685-2.862v2.43A.432.432,0,0,1,88.235,33.6Z"
                            transform="translate(5799.455 -281.675)"
                          />
                          <path
                            id="Path_2493"
                            data-name="Path 2493"
                            d="M201.673,56.884a5.467,5.467,0,1,0,5.467,5.467A5.473,5.473,0,0,0,201.673,56.884Zm0,9.406a3.939,3.939,0,1,1,3.939-3.939A3.943,3.943,0,0,1,201.673,66.29Z"
                            transform="translate(5672.637 -317.15)"
                          />
                          <path
                            id="Path_2494"
                            data-name="Path 2494"
                            d="M120.492,99.705a2.351,2.351,0,1,0,2.351,2.351A2.353,2.353,0,0,0,120.492,99.705Zm0,3.173a.822.822,0,1,1,.822-.822A.823.823,0,0,1,120.492,102.878Z"
                            transform="translate(5745.021 -356.854)"
                          />
                          <path
                            id="Path_2495"
                            data-name="Path 2495"
                            d="M362.263,99.705a2.351,2.351,0,1,0,2.351,2.351A2.353,2.353,0,0,0,362.263,99.705Zm0,3.173a.822.822,0,1,1,.822-.822A.823.823,0,0,1,362.263,102.878Z"
                            transform="translate(5520.846 -356.854)"
                          />
                        </g>
                      </svg>
                    </li>
                  ) : pay.name === "stripe" ? (
                    <li key={pay.id} tooltip={pay.displayName}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30.761"
                        height="12.8"
                        viewBox="0 0 30.761 12.8"
                      >
                        <g id="stripe" transform="translate(-54 -36)">
                          <path
                            id="Path_2485"
                            data-name="Path 2485"
                            d="M346.049,71.513c0-2.187-1.06-3.913-3.085-3.913s-3.264,1.726-3.264,3.9c0,2.572,1.453,3.871,3.538,3.871a4.764,4.764,0,0,0,2.367-.555V73.1a4.537,4.537,0,0,1-2.093.47c-.829,0-1.564-.291-1.658-1.3h4.178C346.032,72.163,346.049,71.719,346.049,71.513Zm-4.221-.812c0-.966.59-1.367,1.128-1.367.521,0,1.077.4,1.077,1.367Z"
                            transform="translate(-261.288 -28.9)"
                          />
                          <path
                            id="Path_2486"
                            data-name="Path 2486"
                            d="M261.866,67.6a2.414,2.414,0,0,0-1.675.666l-.111-.53H258.2V77.7l2.136-.453.009-2.418a2.406,2.406,0,0,0,1.512.538c1.529,0,2.922-1.23,2.922-3.939C264.771,68.95,263.361,67.6,261.866,67.6Zm-.513,5.887a1.277,1.277,0,0,1-1.008-.4l-.009-3.17a1.272,1.272,0,0,1,1.017-.419c.778,0,1.316.872,1.316,1.991C262.669,72.633,262.139,73.487,261.353,73.487Z"
                            transform="translate(-186.752 -28.9)"
                          />
                          <path
                            id="Path_2487"
                            data-name="Path 2487"
                            d="M223.8,38.2l2.145-.461V36l-2.145.453Z"
                            transform="translate(-155.291)"
                          />
                          <rect
                            id="Rectangle_202"
                            data-name="Rectangle 202"
                            width="2.145"
                            height="7.477"
                            transform="translate(68.509 38.845)"
                          />
                          <path
                            id="Path_2488"
                            data-name="Path 2488"
                            d="M175.682,68.987l-.137-.632H173.7v7.477h2.136V70.764a1.507,1.507,0,0,1,1.623-.444V68.355A1.45,1.45,0,0,0,175.682,68.987Z"
                            transform="translate(-109.472 -29.509)"
                          />
                          <path
                            id="Path_2489"
                            data-name="Path 2489"
                            d="M124.493,47.6l-2.085.444-.009,6.844a2.147,2.147,0,0,0,2.213,2.2,3.3,3.3,0,0,0,1.5-.282V55.068c-.273.111-1.623.5-1.623-.76V51.274h1.623v-1.82h-1.623Z"
                            transform="translate(-62.555 -10.609)"
                          />
                          <path
                            id="Path_2490"
                            data-name="Path 2490"
                            d="M56.162,69.916c0-.333.273-.461.726-.461A4.766,4.766,0,0,1,59.007,70V67.993a5.635,5.635,0,0,0-2.119-.393c-1.735,0-2.888.906-2.888,2.418,0,2.358,3.247,1.982,3.247,3,0,.393-.342.521-.82.521a5.319,5.319,0,0,1-2.333-.684v2.034a5.923,5.923,0,0,0,2.333.487c1.777,0,3-.88,3-2.41C59.417,70.42,56.162,70.873,56.162,69.916Z"
                            transform="translate(0 -28.9)"
                          />
                        </g>
                      </svg>
                    </li>
                  ) : pay.name === "card" ? (
                    <li key={pay.id} tooltip={pay.displayName}>
                      <svg
                        width="24"
                        height="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M22 3c.53 0 1.039.211 1.414.586s.586.884.586 1.414v14c0 .53-.211 1.039-.586 1.414s-.884.586-1.414.586h-20c-.53 0-1.039-.211-1.414-.586s-.586-.884-.586-1.414v-14c0-.53.211-1.039.586-1.414s.884-.586 1.414-.586h20zm1 8h-22v8c0 .552.448 1 1 1h20c.552 0 1-.448 1-1v-8zm-15 5v1h-5v-1h5zm13-2v1h-3v-1h3zm-10 0v1h-8v-1h8zm-10-6v2h22v-2h-22zm22-1v-2c0-.552-.448-1-1-1h-20c-.552 0-1 .448-1 1v2h22z" />
                      </svg>
                    </li>
                  ) : pay.name === "epay" ? (
                    <li key={pay.id} tooltip={pay.displayName}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="42.304"
                        height="15.697"
                        viewBox="0 0 42.304 15.697"
                      >
                        <g id="epay" transform="translate(-5825.515 241.242)">
                          <path
                            id="Path_2482"
                            data-name="Path 2482"
                            d="M5.517,11.819A5.5,5.5,0,0,0,11.033,6.1,5.52,5.52,0,1,0,0,6.1a5.5,5.5,0,0,0,5.517,5.719m0-1.966A3.555,3.555,0,0,1,2.072,6.1,3.555,3.555,0,0,1,5.517,2.347,3.555,3.555,0,0,1,8.962,6.1,3.555,3.555,0,0,1,5.517,9.853"
                            transform="translate(5825.515 -241.624)"
                            fill="#1f1f1f"
                          />
                          <path
                            id="Path_2483"
                            data-name="Path 2483"
                            d="M34.1,10.844a1.585,1.585,0,0,0-.341-.765A.979.979,0,0,0,33,9.8a1.215,1.215,0,0,0-.556.113,1.059,1.059,0,0,0-.354.282,1.032,1.032,0,0,0-.187.355,1.6,1.6,0,0,0-.064.336h2.273l-.009-.045Zm-1.92,1.922a1.428,1.428,0,0,0,1.633.094c.2-.141.2-.291.244-.45h1.36a2.345,2.345,0,0,1-.905,1.314,2.657,2.657,0,0,1-1.476.4,2.89,2.89,0,0,1-1.082-.193,2.246,2.246,0,0,1-.816-.548,2.466,2.466,0,0,1-.517-.849,3.12,3.12,0,0,1-.182-1.087,2.983,2.983,0,0,1,.187-1.067,2.462,2.462,0,0,1,1.353-1.423,2.624,2.624,0,0,1,1.058-.208A2.409,2.409,0,0,1,34.172,9a2.26,2.26,0,0,1,.792.677,2.8,2.8,0,0,1,.448.968,3.633,3.633,0,0,1,.1,1.136H31.84a1.42,1.42,0,0,0,.344.988M43.829,8.28a2.884,2.884,0,0,0-1.99,1.314,3.448,3.448,0,0,0-.4,2.772,3.118,3.118,0,0,0,1.2,1.732,2.814,2.814,0,0,0,2.244.445,2.933,2.933,0,0,0,1.892-1.433,3.421,3.421,0,0,0,.338-2.415,3.15,3.15,0,0,0-1.27-1.944,2.843,2.843,0,0,0-2.013-.472Zm-2.414-.972a4.57,4.57,0,0,1,2.63-.97,4.659,4.659,0,0,1,3.382,1.226,5.172,5.172,0,0,1,1.6,3.179,5.352,5.352,0,0,1-.851,3.671A4.725,4.725,0,0,1,45.2,16.458a4.132,4.132,0,0,1-2.969-.525,5.427,5.427,0,0,1-.965-.769q0,3.041,0,6.082c-.607,0-1.214-.005-1.821,0q.006-4.625,0-9.251a8.222,8.222,0,0,1,.221-2.056,4.923,4.923,0,0,1,1.743-2.632M54.058,8.319A2.943,2.943,0,0,0,52.11,9.9a3.614,3.614,0,0,0-.137,2.741,2.832,2.832,0,0,0,5.134.6,3.5,3.5,0,0,0,.364-2.8,3.086,3.086,0,0,0-1.64-1.935,2.788,2.788,0,0,0-1.773-.189Zm-2.949-.269a4.556,4.556,0,0,1,7.593.707,21.391,21.391,0,0,1,1.263,3.2c.488,1.419.982,2.836,1.469,4.255H59.451q-.388-1.147-.783-2.291a4.664,4.664,0,0,1-2.335,2.286,4.373,4.373,0,0,1-2.543.24,4.686,4.686,0,0,1-2.753-1.708,5.408,5.408,0,0,1-1.141-3.6,5.2,5.2,0,0,1,1.213-3.087"
                            transform="translate(5798.051 -246.956)"
                            fill="#1f1f1f"
                          />
                          <path
                            id="Path_2484"
                            data-name="Path 2484"
                            d="M336.755,9.421c.6-.048,1.213-.008,1.82-.021,0,1.781,0,3.562,0,5.343a3.149,3.149,0,0,0,.441,1.788,1.918,1.918,0,0,0,1.345.807,2.05,2.05,0,0,0,1.845-.641,3.426,3.426,0,0,0,.641-2.2c0-1.7,0-3.4,0-5.1.607,0,1.215.009,1.822,0-.007,2.658,0,5.317,0,7.976a9.21,9.21,0,0,1-.522,3.635,5.37,5.37,0,0,1-3.267,2.914,4.95,4.95,0,0,1-5.1-1.337,7.668,7.668,0,0,1-.613-.731c.469-.418.956-.813,1.423-1.235a3.605,3.605,0,0,0,2.207,1.568,3.492,3.492,0,0,0,3.838-2.4,5.049,5.049,0,0,0,.243-1.552,3.481,3.481,0,0,1-1.98,1.016,3.608,3.608,0,0,1-3.109-1.11,3.5,3.5,0,0,1-.859-1.667,9.779,9.779,0,0,1-.171-2.218c0-1.611,0-3.222,0-4.833"
                            transform="translate(5523.146 -249.702)"
                            fill="#1f1f1f"
                          />
                        </g>
                      </svg>
                    </li>
                  ) : (
                    ""
                  );
                })
              : ""}
          </ul>
        </div>
      </>
    );
  }
);
export default Details;
