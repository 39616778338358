import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { Form, InputGroup, Button, Row, Col, } from "react-bootstrap";

//custom alert

import CustomAlert from '../alert/CustomAlert'

let autoComplete;

const loadScript = (url, callback) => {
    let id = 'googleMaps'
    if (document.getElementById(id) === null) {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.id = id;

        if (script.readyState) {
            script.onreadystatechange = function () {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {
            script.onload = () => callback();
        }

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    }else{
        callback();
    }
};

function handleScriptLoad(updateQuery, setUlocation_r, setAddress_r, SetAddRessFlag_r, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current
    );
    autoComplete.setFields(["address_components", "formatted_address", "geometry"]);
    autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(updateQuery, setUlocation_r, setAddress_r, SetAddRessFlag_r)
    );
}

async function handlePlaceSelect(updateQuery, setUlocation_r, setAddress_r, SetAddRessFlag_r) {
   
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    
    if (addressObject.geometry === undefined ||addressObject.geometry === null) {
        return;
      } else {
        for (let i = 0; i < addressObject.address_components.length; i++) {
          for (let j = 0; j < addressObject.address_components[i].types.length; j++) {
            if (addressObject.address_components[i].types[j] === 'postal_code') {
              setUlocation_r({zip : addressObject.address_components[i].long_name});
            }
            let placeaddress = '';
            placeaddress = addressObject.address_components[i].types[j];

          }
        }

        setUlocation_r({ lat: addressObject.geometry.location.lat(), lng : addressObject.geometry.location.lng(), address: addressObject.formatted_address});

        setAddress_r(addressObject.formatted_address);
        SetAddRessFlag_r(1);
      }

}


function UpdateProfile(props) {

    const [language, setLang] = useState(null); 
    let userdetails = JSON.parse(sessionStorage.getItem('user-details'));
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [mobile, SetMobile] = useState("");

    const autoCompleteRef = useRef(null);
    const [query, setQuery] = useState("");
    
    const [addressFlag, SetAddRessFlag] = useState(0);
    const [ulocation, setUlocation] = useState({
        address: '',
        lat: '',
        lng: '',
        zip: ''
      });
  
   
    useEffect(() => {

        setTimeout(function(){
            let config_data = props.config.config;
            setLang(config_data.LANGUAGE[config_data.LANG_ID]);
            
            loadScript(
                'https://maps.googleapis.com/maps/api/js?key='+ props.config.config.google_map_api_key +'&libraries=places',
                () => handleScriptLoad(setQuery, setUlocation, setAddress, SetAddRessFlag,  autoCompleteRef)
            );

        },2000)

        setFirstName(userdetails.name);
        setLastName(userdetails.last_name);
        setEmail(userdetails.email);
        setAddress(userdetails.address);
        SetMobile(userdetails.cel);
        setQuery(userdetails.address);

        if(userdetails.address != ""){
            SetAddRessFlag(1);
        }

   }, [props.config.config,language]);

    //alert start

    const [alert_custom, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }

    //alert end 

    

    const getFormData = async () =>{

        let flg = true;
        const all_error_msg = [];

        if (first_name.trim() === '') {
           
            all_error_msg.push(language.ENTER_FIRST_NAME);

            if(flg === true){
                flg = false;
            }

          }
          if (last_name.trim() === '') {
            all_error_msg.push(language.ENTER_YOUR_LAST_NAME);

            if(flg === true){
                flg = false;
            }
          }
          if (email.trim() === '') {
                        
            all_error_msg.push(language.ENTER_YOUR_EMAIL);

            if(flg === true){
                flg = false;
            }
          }
          let atpos;
          atpos = email.indexOf('@');
          let dotpos;
          dotpos = email.lastIndexOf('.');
          if(email.trim() !== ''){
                if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= email.length) {
                    all_error_msg.push(language.ENTER_VALID_EMAIL_ADDRESS);

                    if(flg === true){
                        flg = false;
                    }
                }
            }

            if(mobile != null){

                if (mobile === '') {
                    all_error_msg.push(language.ENTER_YOUR_MOBILE);
        
                    if(flg === true){
                        flg = false;
                    }
                }else if(mobile !== ''){
                    if(mobile.length < 10){
                        all_error_msg.push(language.ENTER_YOUR_MOBILE_MIN_DIGIT);
        
                        if(flg === true){
                            flg = false;
                        }
                    }
                }
    

            }else{
                all_error_msg.push(language.ENTER_YOUR_MOBILE);
        
                if(flg === true){
                    flg = false;
                } 
            }
  

          if (address.trim() === '') {
            all_error_msg.push(language.ENTER_YOUR_ADDRESS);

            if(flg === true){
                flg = false;
            }
          }


          if(flg === true){
            if (addressFlag === 0) {
                
                all_error_msg.push(language.ENTER_CORRECT_ADDRESS);

                if(flg === true){
                    flg = false;
                }
              }
          }

          if(flg === true){

            SetAddRessFlag(1);

               try {
                    let res = await fetch(props.config.config.api.url + 'api/user/user', {
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            f: "update",
                            langId : sessionStorage.getItem('langId'),
                            userId: sessionStorage.getItem('userId'),
                            name : first_name,
                            last_name: last_name,
                            email: email,
                            address: address,
                            cel: mobile,
                            location: JSON.stringify(ulocation),
                        })
                    });
                    let result = await res.json();

                    console.log(result);
                    
                    if (result.status) {

                        sessionStorage.setItem('user-details', JSON.stringify(result));

                        props.setUserDetails(result);

                        all_error_msg.push(language.SUCCESSFULLY_UPDATED);

                        onShowAlert('success',all_error_msg); 
   
                        SetAddRessFlag(0);
                        setUlocation({address: '', lat: '', lng: '', zip: ''});

                        setTimeout(
                            function() {
                                onCloseAlert();  
                            }
                            ,
                            4000
                        );
                        
                    } else {
                        all_error_msg.push(language.EMAIL_ALREADY_REGISTERD);

                        onShowAlert('error',all_error_msg); 
                    }
                }
                catch (e) {
                console.log(e);
                }
            }else{

                onShowAlert('error',all_error_msg); 
            }        

    }


    return (
        <>
            <h1 className="container-heading"><span>My</span> profile</h1>
            <Form>
            <CustomAlert
                    header={language != null ? language.UPDATE_PROFILE : ''}
                    btnText={language != null ? language.ACCEPT : ''}
                    text={alert_custom.text}
                    type={alert_custom.type}
                    show={alert_custom.show}
                    onClosePress={onCloseAlert}
                    pressCloseOnOutsideClick={true}
                    alertStyles={{}}
                    headerStyles={{}}
                    textStyles={{}}
                    buttonStyles={{}}
                    /> 

                   <Form.Group>
                            <Row>
                                <Col md={6} sm={12}>
                                    <InputGroup className="grey">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text><i className="fas fa-user"></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control id="" placeholder={language != null ? language.NAME : ''} defaultValue={first_name} onChange={(e)=>setFirstName(e.target.value)}/>
                                    </InputGroup>
                                </Col>
                                <Col md={6} sm={12}>
                                    <InputGroup className="grey">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text><i className="fas fa-user"></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control id="" placeholder={language != null ? language.LAST_NAME : ''} defaultValue={last_name} onChange={(e)=>setLastName(e.target.value)}/>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group>
                            <Row>
                                <Col md={6} sm={12}>
                                    <InputGroup className="grey">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text><i className="fas fa-envelope"></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control id="" placeholder={language != null ? language.EMAIL : ''} defaultValue={email} onChange={(e)=>setEmail(e.target.value)}/>
                                    </InputGroup>
                                </Col>
                                <Col md={6} sm={12}>
                                    <InputGroup className="grey">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text><i className="fas fa-mobile"></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control id="" onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} placeholder={language != null ? language.MOBILE : ''} defaultValue={mobile} onChange={(e)=>SetMobile(e.target.value)} />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group>
                            <Row>
                                <Col md={12} sm={12}>
                                    <InputGroup className="grey textarea">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text><i className="fas fa-map-marker-alt"></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                   
                                        <Form.Control type="text" className="box"
                                            name='address'
                                            placeholder={language != null ? language.ADDRESS : ''}
                                            ref={autoCompleteRef}
                                            onChange={event => setQuery(event.target.value)}
                                            defaultValue={query}
                                            id="address-input"
                                            />
                                    
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Button variant="secondary" onClick={getFormData}>{language != null ? language.SAVE_DETAILS : ''}</Button>
            </Form>
        </>
    );
}

export default UpdateProfile;