import React from "react";

// Own Pages
import Header from "./Header";
import Footer from "./Footer";

const Layout = (props) => {
  return (
    <div className="main-body">
      <Header {...props} />
      {props.children}
      <Footer {...props} />
    </div>
  );
};

export default Layout;
