import React, {useState, useEffect} from 'react';
import { Container, Col } from "react-bootstrap";


// img
import country from '../../assets/img/arc-de-triomphe.png';
import resturant from '../../assets/img/dinner.png';
import foody from '../../assets/img/eating.png';
import photo from '../../assets/img/image.png';
import review from '../../assets/img/rating.png';

import Skeleton from 'react-loading-skeleton';


function Stats(props) {
    const [language, setLang] = useState(null);
    const [starts, setStarts] = useState([]);
   const getStarts = async () => {
        const url = props.api_url + 'api/getStartsCountDetails';
        const response = await fetch(url);
        const res = await response.json();
        let data = res.data;

        //return data;

        setStarts(data);
   };

   if(starts.length === 0){

        getStarts();
    }

   useEffect(() => {
        setTimeout(function(){
            let config_data = props.config;
           setLang(config_data.LANGUAGE[config_data.LANG_ID]);
                     
        },2000)
        
    }, [props.config,language]);


    return (
        starts.length !== 0 ?
        <section className="stats">
            <Container fluid>
                <Col>
                    <div className="stats-cont">
                        <div className="item">
                            <div className="details">
                                <h3>{language != null ? language.COUNTRIES : ''}</h3>
                                <span>{starts.country}</span>
                            </div>
                            <img src={country} alt="Menuhuts" />
                        </div>

                        <div className="item">
                            <div className="details">
                                <h3>{language != null ? language.RESTAURANTS : ''}</h3>
                                <span>{starts.rest}</span>
                            </div>
                            <img src={resturant} alt="Menuhuts" />
                        </div>

                        <div className="item">
                            <div className="details">
                                <h3>{language != null ? language.FOODIES : ''}</h3>
                                <span>{starts.foodie}</span>
                            </div>
                            <img src={foody} alt="Menuhuts" />
                        </div>

                        <div className="item">
                            <div className="details">
                                <h3>{language != null ? language.PHOTOS : ''}</h3>
                                <span>{starts.photo}</span>
                            </div>
                            <img src={photo} alt="Menuhuts" />
                        </div>

                        <div className="item">
                            <div className="details">
                                <h3>{language != null ? language.REVIEWS : ''}</h3>
                                <span>{starts.review}</span>
                            </div>
                            <img src={review} alt="Menuhuts" />
                        </div>
                    </div>
                </Col>
            </Container>
        </section>
        : 
        <section className="stats">
            <Container fluid>
                <Col>
                    <div className="stats-cont">
                        <div className="item">
                           
                            <Skeleton width={'100%'} height={100} />
                        </div>

                        <div className="item">
                           
                            <Skeleton width={'100%'} height={100} />
                        </div>

                        <div className="item">
                           
                            <Skeleton width={'100%'} height={100} />
                        </div>

                        <div className="item">
                           
                            <Skeleton width={'100%'} height={100} />
                        </div>

                        <div className="item">
                           
                            <Skeleton width={'100%'} height={100} />
                        </div>
                    </div>
                </Col>
            </Container>
        </section>
    );
}

export default Stats;