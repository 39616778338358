import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';


function FilterBusiness(props) {

    const [language, setLang] = useState(null);

    const [show, setShow] = useState(false);

    const [allCategory, setAllCatgory] = useState([]);
    const [allCuisine, setAllCuisine] = useState([]);

    const [filterObject_order, setFilterObject_order] = useState([]);
    const [filterObject_status, setFilterObject_status] = useState([]);
    const [filterObject_cuisine, setFilterObject_cuisine] = useState([]);
    const [filterObject_cat, setFilterObject_cat] = useState([]);

    const filterBusinesssClose = () => setShow(false);
    const filterBusinesssShow = () => {
           
         setShow(true);
      
    };


    const [orderType, setOrderType] = useState([
        {
        'name': 'Delivery',
        'id': 1,
        'selected' : false
        },
        {
          'name': 'Pickup',
          'id': 2,
          'selected': false
        }
      ]);
      const [businessStatus, setBusinessState] = useState([
        {
          'name': 'Open',
          'id': 1,
          'selected': false
        },
        {
          'name': 'Close',
          'id': 0,
          'selected': false
        }
      ]);

      /*const [filterObject, setFilterObject] = useState({
        'order': [],
        'status': [],
        'cuisine': [],
        'cat': []
      });*/

      

    useEffect ( () => {
        setTimeout(function(){
            let config_data = props.config;
            
            setLang(config_data.LANGUAGE[config_data.LANG_ID]);

            setAllCatgory(props.allCategory);

            setAllCuisine(props.allCuisine);

            if(language != null){ 

                    setOrderType([
                        {
                        'name': language.DELIVERY,
                        'id': 1,
                        'selected' : false
                        },
                        {
                        'name': language.PICKUP,
                        'id': 2,
                        'selected': false
                        }
                    ]);
                    setBusinessState([
                        {
                        'name': language.OPEN,
                        'id': 1,
                        'selected': false
                        },
                        {
                        'name': language.CLOSE,
                        'id': 0,
                        'selected': false
                        }
                    ]);
            }       
            
        },2000)
        
    },[props.config,language, props.allCategory, props.allCuisine]);

    async function businessFilter(type, val, checked_status) {
        let flg = false;
        if (type === 'order') {
            
          const type_arr = [...filterObject_order];
           
          if(checked_status == false){
            if (type_arr.indexOf(val) !== -1) {
                type_arr.splice(type_arr.indexOf(val), 1 );
                await setFilterObject_order(type_arr)
            }
          }else {

            if (type_arr.indexOf(val) !== -1) {
                type_arr.splice(type_arr.indexOf(val), 1 );
                await setFilterObject_order(type_arr)
            }else{
                type_arr.push(val);
                await setFilterObject_order(type_arr)
            }
          
          }
        }
        if (type === 'status') {
            const status_arr = [...filterObject_status];
            if(checked_status == false){
                if (status_arr.indexOf(val) !== -1) {
                     status_arr.splice(status_arr.indexOf(val), 1);
                     await setFilterObject_status(status_arr);
                }
            } else {
                if (status_arr.indexOf(val) !== -1) {
                    status_arr.splice(status_arr.indexOf(val), 1);
                    await setFilterObject_status(status_arr);
               }else{
                status_arr.push(val);
                await setFilterObject_status(status_arr)
               }
            }
        }
        if (type === 'cat') {
          
          const cat_arr = [...filterObject_cat];
            if(checked_status == false){
                if (cat_arr.indexOf(val) !== -1) {
                    cat_arr.splice(cat_arr.indexOf(val), 1);
                    await setFilterObject_cat(cat_arr);
                }
            } else {
                if (cat_arr.indexOf(val) !== -1) {
                    cat_arr.splice(cat_arr.indexOf(val), 1);
                    await setFilterObject_cat(cat_arr);
                }else{
                    val.selected = true;
                    cat_arr.push(val);
                    await setFilterObject_cat(cat_arr)
                }
                
            }
        }
        if (type === 'cuisine') {
            const cuisine_arr = [...filterObject_cuisine];
            if(checked_status == false){
                if (cuisine_arr.indexOf(val) !== -1) {
                    cuisine_arr.splice(cuisine_arr.indexOf(val), 1);
                    await setFilterObject_cuisine(cuisine_arr);
                }
            } else {
                if (cuisine_arr.indexOf(val) !== -1) {
                    cuisine_arr.splice(cuisine_arr.indexOf(val), 1);
                    await setFilterObject_cuisine(cuisine_arr);
                }else{
                    val.selected = true;
                    cuisine_arr.push(val);
                    await setFilterObject_cuisine(cuisine_arr)

                }
                
            }
        }

        
    }

    function apply_filter(){
        setShow(false);
        props.applyFilter_r(filterObject_order, filterObject_status, filterObject_cat, filterObject_cuisine);
    }

    function clear_filter(){
        setFilterObject_order([]);
        setFilterObject_status([]);
        setFilterObject_cuisine([]);
        setFilterObject_cat([]);

    }


    return (
        <>
            <Link to={`#`} className="filter" onClick={filterBusinesssShow}><i className="fas fa-filter"></i> {language != null ? language.FILTER : ''}</Link>

            <Modal show={show} onHide={filterBusinesssClose}>
                <Modal.Header closeButton>
                    <h1 className="modal-title"><span>{language != null ? language.FILTER : ''}</span> {language != null ? language.RESTAURANT : ''}</h1>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="divide-item">
                            <h4>{language != null ? language.DELIVERY_TYPE : ''}</h4>
                            <Row>
                            {orderType?.map((type, index) => {
                                let delivery_type_index = 'delivery-' + index;

                                var type_selected = false;
                                if (filterObject_order.indexOf(type.id) !== -1) {
                                    type_selected = true;
                                }
                                
                                return(
                                    
                                    <Col sm={6} key={delivery_type_index}>
                                        { type_selected === true ?
                                        <Form.Check type="checkbox" label={type.name} defaultChecked={type_selected} onChange={(e) => businessFilter('order',type.id,e.target.checked)}/>
                                        :
                                        <Form.Check type="checkbox" label={type.name} onChange={(e) => businessFilter('order',type.id,e.target.checked)}/>
                                       }
                                    </Col>
                                )                   
                            })}
                               
                            </Row>
                        </Form.Group>

                        <Form.Group className="divide-item">
                            <h4>{language != null ? language.BUSINESS_STATUS : ''}</h4>
                            <Row>
                                {businessStatus?.map((status, index) => {
                                    let status_index = 'status-' + index;

                                    let status_selected = false;
                                    if (filterObject_status.indexOf(status.id) !== -1) {
    
                                        status_selected = true;
                                    }
                                    
                                    return(
                                        
                                        <Col sm={6} key={status_index}>
                                            {
                                                status_selected === true ?
                                                <Form.Check type="checkbox" label={status.name} defaultChecked={status_selected} onChange={() => businessFilter('status',status.id)}/>
                                                :
                                                <Form.Check type="checkbox" label={status.name} onChange={() => businessFilter('status',status.id)}/>
                                            }
                                            
                                        </Col>
                                    )                   
                                })}
                                
                            </Row>
                        </Form.Group>

                        

                        <Form.Group className="divide-item">
                            <h4>{language != null ? language.CUISINE : ''}</h4>
                            <Row>
                            {allCuisine?.map((cuisine, index) => {
                                let cuisine_index = 'cuisine-' + index;

                                let cuisine_selected = false;
                                    if (filterObject_cuisine.map(cui_item => cui_item.id).indexOf(cuisine.id) !== -1) {
    
                                        cuisine_selected = true;
                                    }
                                
                                    return(
                                      
                                        <Col sm={6} key={cuisine_index}>
                                            {
                                                cuisine_selected === true ?
                                                <Form.Check type="checkbox" label={cuisine.name} defaultChecked={cuisine_selected} onChange={() => businessFilter('cuisine',cuisine)}/>
                                                :
                                                <Form.Check type="checkbox" label={cuisine.name} onChange={() => businessFilter('cuisine',cuisine)}/>
                                            }
                                            
                                        </Col>
                                      
                                    )                   
                                })}
                            </Row>
                        </Form.Group>

                        

                        <Form.Group className="divide-item no-border">
                            <h4>{language != null ? language.CATEGORY : ''}</h4>
                            <Row>
                               {allCategory?.map((cat, index) => {
                                let cat_index = 'cat-' + index;

                                let cat_selected = false;
                                    if (filterObject_cat.map(cat_item => cat_item.id).indexOf(cat.id) !== -1) {
                                        cat_selected = true;
                                    }
                                
                                    return(
                                        
                                        <Col sm={6} key={cat_index}>
                                            {
                                                cat_selected === true ? 
                                                <Form.Check type="checkbox" label={cat.name} defaultChecked={cat_selected} onChange={() => businessFilter('cat',cat)}/>
                                                :
                                                <Form.Check type="checkbox" label={cat.name} onChange={() => businessFilter('cat',cat)}/>
                                            }
                                            
                                        </Col>
                                    )                   
                                })}
                                
                            </Row>
                        </Form.Group>
                            

                        <Form.Group>
                       
                           
                            <Button type="button" variant="secondary" className="box" onClick={() => apply_filter()}>{language != null ? language.APPLY : ''}</Button>
                            
                        </Form.Group>
                        
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default FilterBusiness;