import React, { useState } from "react";
import { CardElement } from "@stripe/react-stripe-js";

const StripeForm = ({
  secretKey,
  price,
  apiUrl,
  paymentMethod,
  currencyCode,
  canSubmit,
  btnOpacity,
}) => {
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const paymentHandler = async (e) => {
    e.preventDefault();
    try {
      let response = await fetch(
        `${apiUrl}api/stripe/create-checkout-session`,
        {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            success: `${window.location.origin}/confirmation`,
            error: `${window.location.origin}/checkout`,
            stripeSecretKey: secretKey,
            totalAmount: price * 100,
            currencyCode: currencyCode,
            bname: JSON.parse(sessionStorage.getItem("cartBusiness")).name,
          }),
        }
      );
      let x = await response.json();
      window.open(x.url, "_blank");
      setPaymentLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="stripe-container">
      {isPaymentLoading ? (
        <>
          <h5>Enter card details:</h5>
          <CardElement />
        </>
      ) : paymentMethod === "Stripe" ? (
        <button
          onClick={paymentHandler}
          className="checkout-payment-btn"
          disabled={!canSubmit}
          style={{ opacity: btnOpacity }}
        >
          Pay using Stripe
        </button>
      ) : (
        <button
          onClick={paymentHandler}
          className="checkout-payment-btn"
          disabled={!canSubmit}
          style={{ opacity: btnOpacity }}
        >
          Pay using Card
        </button>
      )}
    </div>
  );
};

export default StripeForm;
