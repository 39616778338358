import React from "react";
import {
  Form,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  Row,
  Col,
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

//Pages
import ProductListing from "./ProductListing";

class OnlineOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      availableCats: [],
      menuNav: "menuNav",
      category: [],
      menu: [],
      dishids: [],
      activeKey: "",
      dishidslength: 0,
      categoryResults: true,
      searchText: "",
      baseUrl: "",
    };
  }
  componentDidMount() {
    this.setState({
      baseUrl: window.location.href.split("#")[0],
    });
    this.props.onRef(this);
    if (this.props?.business?.id > 0) {
      this.getMenu(
        this.props?.business?.id,
        this.props?.business?.code,
        this.props.orderType
      );
    }
    window.addEventListener("scroll", this.handleScroll);
  }
  componentDidUpdate() {
    if (
      this.props.menus.length > 0 &&
      this.props.selectedMenuNumber >= 0 &&
      this.state.menu[0] !== this.props.menus[this.props.selectedMenuNumber]
    )
      this.getMenu(
        this.props.business.id,
        this.props.business.code,
        this.props.orderType
      );
    if (
      this.state.searchText.length === 0 &&
      this.state.category.length > 0 &&
      this.state.availableCats.length === 0
    )
      this.setState({
        availableCats: this.state.category,
      });
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  changeSearchText = async (x) => {
    this.setState({
      searchText: x,
    });
    let cats;
    if (x.length > 0)
      cats = this.state?.category
        .map((cat) => {
          if (cat.product.filter((prod) => prod.name.includes(x)).length > 0)
            return cat;
        })
        .filter((obj) => obj !== undefined);
    else {
      cats = this.state?.category;
    }
    this.setAvailableCats([...cats]);
  };
  setAvailableCats = (cats) => {
    this.setState({
      availableCats: cats,
    });
  };
  handleScroll = () => {
    if (window.scrollY < 600) {
      this.setState({ menuNav: "menuNav" });
    } else if (window.scrollY > 600) {
      this.setState({ menuNav: "menu-nav" });
    }
  };
  changeProductByorderType = (id, code, type) => {
    this.setState({
      category: [],
    });
    this.setState({
      menu: [],
    });
    this.setState({
      dishids: [],
    });
    this.setState({
      dishidslength: 0,
    });

    console.log("call from parent", type);

    this.getMenu(id, code, type);
  };
  changeTimezone = (date, ianatz) => {
    // suppose the date is 12:00 UTC
    var invdate = new Date(
      date.toLocaleString("en-US", {
        timeZone: ianatz,
      })
    );

    // then invdate will be 07:00 in Toronto
    // and the diff is 5 hours
    var diff = date.getTime() - invdate.getTime();

    // so 12:00 in Toronto is 17:00 UTC
    return new Date(date.getTime() - diff); // needs to substract
  };
  getMenu = async (id, code, type) => {
    if (this.props.menus.length > 0 && this.props.selectedMenuNumber >= 0) {
      if (
        this.state.menu[0] !== this.props.menus[this.props.selectedMenuNumber]
      ) {
        this.setState({
          menu: [this.props.menus[this.props.selectedMenuNumber]],
        });
        this.setState({
          dishids: this.props.menus[this.props.selectedMenuNumber].dishes,
        });
        if (this.state?.dishids?.length > 0) {
          this.setdishIds(this.state?.dishids, 0, type);
        }
        console.log(this.props.menus[this.props.selectedMenuNumber]);
      }
      return;
    }
    let preorder;
    preorder = {
      id: 0,
      date: "2022-09-13",
      time: "18:00",
      day: 0,
    };
    let today;
    // E.g.
    var hereTime = new Date();
    var thereTime = this.changeTimezone(hereTime, code);
    let date;
    date = thereTime;
    if (parseInt(preorder.id) > 0) {
      let fullDate = preorder.date.split("-");
      let fullDateMonth = parseInt(fullDate[1]) - 1;
      date.setFullYear(fullDate[0], fullDateMonth, fullDate[2]);
      today = date.getDay() === 0 ? 7 : date.getDay();
      preorder.day = today;
    }

    let res = await fetch(this.props.config?.api?.url + "api/menu", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        code: code,
        ordertype: parseInt(type),
        preorder: preorder,
      }),
    });
    let result = await res.json();
    console.log(result);
    if (result && result.success === true) {
      this.setState({
        category: result.category,
      });
      this.setState({
        menu: result.menu,
      });
      this.setState({
        dishids: result.dish,
      });
      if (this.state?.dishids?.length > 0) {
        this.setdishIds(this.state?.dishids, 0, type);
      }
      this.setState({
        categoryResults: true,
      });
    } else {
      this.setState({
        categoryResults: false,
      });
    }
  };
  setdishIds(dishids, lid, type) {
    if (lid < dishids.length) {
      let arrlen;
      if (lid + 100 < dishids.length) {
        arrlen = lid + 100;
      } else {
        arrlen = dishids.length;
      }
      let dishes = [];
      for (let index = lid; index < arrlen; index++) {
        const element = dishids[index];
        if (element !== undefined) {
          dishes.push(element);
        }
      }
      this.getProducts(dishes, lid, type);
    }
  }
  getProducts = async (dishid, lid, type) => {
    let res = await fetch(this.props.config?.api?.url + "api/dish", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dishid,
        ordertype: parseInt(type),
      }),
    });
    let result = await res.json();
    if (result && result.success === true) {
      for (let index = 0; index < result.data.length; index++) {
        const element = result.data[index];
        for (let j = 0; j < this.state?.category?.length; j++) {
          const catElement = this.state?.category[j];
          if (parseInt(catElement.id) === parseInt(element.category)) {
            if (catElement.dishid.includes(element.id)) {
              catElement.product.push(element);
            } else {
              for (let k = 0; k < catElement.subcat?.length; k++) {
                const subcatElement = catElement.subcat[k];
                if (subcatElement.dishid.includes(element.id)) {
                  subcatElement.product.push(element);
                }
              }
            }
          }
        }
      }
      lid = lid + 100;
      this.state.dishidslength = this.state.dishidslength + dishid.length;
      if (lid < this.state?.dishids.length) {
        this.setdishIds(this.state?.dishids, lid, type);
      }
    }
  };
  categorySelectEvent = (selectedKey) => {
    this.setState({
      activeKey: selectedKey,
    });
  };

  render() {
    return (
      <>
        <div className="online-order">
          <Form>
            <Form.Group>
              <InputGroup className="grey">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <i className="fas fa-search"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  id=""
                  placeholder="What are you craving?"
                  onChange={(e) => this.changeSearchText(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </Form>

          <div className="menu-container">
            <Nav
              variant="tabs"
              className={this.state.menuNav}
              activeKey={this.state.activeKey}
              onSelect={this.categorySelectEvent}
            >
              {this.state.availableCats.length > 0 ? (
                <>
                  <NavItems
                    category={this.state.availableCats}
                    eventKey={this.state.activeKey}
                    baseUrl={this.state.baseUrl}
                  />
                </>
              ) : (
                <>
                  <CategoryNavSkeleton />
                </>
              )}
            </Nav>
            {this.state.category?.length > 0 ? (
              <>
                {this.state.dishidslength === this.state.dishids?.length ? (
                  <ProductListing
                    searchText={this.state.searchText}
                    activeKey={this.state.activeKey}
                    category={this.state.category}
                    dishids={this.state.dishids}
                    {...this.props}
                  />
                ) : (
                  <ProductSkeleton />
                )}
              </>
            ) : (
              <>
                <ProductListingSkeleton />
              </>
            )}
            <div className="other-description">
              Items marked with a green & white “V” graphic are American
              Vegetarian Association certified Vegetarian food items, are
              lacto-ovo, allowing consumption of dairy and eggs but not animal
              byproducts. The same frying oil may be used to prepare menu items
              that could contain meat. Vegetarian and meat ingredients are
              handled in common, and cross contact may occur, which may not be
              acceptable to certain types of vegetarian diets. Neither Taco
              Bell®, its employees, nor its franchisees nor the AVA assume any
              responsibility for such cross contact.
            </div>
          </div>
        </div>
      </>
    );
  }
}

const NavItems = React.memo(({ category, eventKey, baseUrl }) => {
  return (
    <>
      {category?.map((cat) =>
        cat.subcat?.length > 0 ? (
          <Dropdown as={NavItem} key={cat.id}>
            <Dropdown.Toggle as={NavLink}>{cat.name}</Dropdown.Toggle>
            <Dropdown.Menu>
              {cat.subcat.map((valSub) => {
                return (
                  <Dropdown.Item
                    eventKey={`subcat-${valSub.id}-${cat.id}`}
                    key={`submenu-${valSub.id}`}
                    href={`${baseUrl}#submenu-${valSub.id}`}
                  >
                    {valSub.name}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <Nav.Item key={cat.id}>
            <Nav.Link
              eventKey={`cat-${cat.id}`}
              href={`${baseUrl}#${eventKey}`}
            >
              {cat.name}
            </Nav.Link>
          </Nav.Item>
        )
      )}
    </>
  );
});

const CategoryNavSkeleton = React.memo(() => {
  return (
    <>
      {[...Array(6)].map((item, i) => (
        <Nav.Item key={i} className="skeleton-padding">
          <Skeleton width={100} height={45} />
        </Nav.Item>
      ))}
    </>
  );
});

const ProductListingSkeleton = React.memo(() => {
  return (
    <>
      {[...Array(5)].map((item, i) => (
        <div className="items-sec" key={i}>
          <h4 className="category">
            <Skeleton width={200} height={26} />
          </h4>
          <p className="cate-des">
            <Skeleton className="width-70" height={50} />
          </p>
        </div>
      ))}
    </>
  );
});

const ProductSkeleton = React.memo(() => {
  return (
    <>
      <div className="items-sec">
        <h4 className="category">
          <Skeleton width={200} height={26} />
        </h4>
        <p className="cate-des">
          <Skeleton className="width-70" height={50} />
        </p>
        <Row className="list-container">
          {[...Array(3)].map((item, i) => (
            <Col lg={4} md={6} sm={12} key={i}>
              <div className="items">
                <div className="product-description">
                  <div className="top">
                    <h5>
                      <Skeleton width={150} height={19} />
                    </h5>
                    <p>
                      <Skeleton width={290} height={50} />
                    </p>
                  </div>
                  <div className="btm">
                    <h5 className="price">
                      <Skeleton width={50} height={19} />
                    </h5>
                  </div>
                </div>
                <Skeleton width={158} height={158} />
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
});

export default OnlineOrder;
