import React from 'react';
import { Link } from "react-router-dom";
import { Container, Col} from "react-bootstrap";

// Pages
import FilterBusiness from '../modal/FilterBusiness';


function BreadCrumbs(props) {   

    return (
        <section className="breadcumbs">
            <Container fluid>
                <Col>
                    <div className="body-top">
                        { /*<ul>
                            <li><Link to={`#`}>Australia</Link></li>
                            <li><Link to={`#`}>Adelaide</Link></li>
                          </ul>*/
                        }


                        <FilterBusiness config={props.config} allCategory = {props.allCategory} allCuisine = {props.allCuisine} applyFilter_r = {props.applyFilter_r}/>
                        
                    </div>
                </Col>
            </Container>
        </section>
    );
}

export default BreadCrumbs;