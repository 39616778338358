import { BehaviorSubject } from 'rxjs';

const loginsubscriber = new BehaviorSubject(false);

const loginService = {
    send: function(msg) {
        loginsubscriber.next(true);
    }
}

export {
    loginService,
    loginsubscriber
}