import React, {useState, useEffect} from 'react';
import { useHistory} from "react-router-dom";


export default function LangType(props){ 

    const history = useHistory();
    let config_data = props.config;
        

    const [lang, setLang] = useState([]);

    function handleChange(e){
        
        sessionStorage.setItem('langId',e);
        window.location.reload();
    }


    useEffect ( () => {
        setTimeout(function(){
            setLang(config_data.LANG_LIST);
        },3000)
        
    },[props.config,lang]);

    return (                              
        lang.length > 0 ?
            <div className="select-group">
                <select className="header_lang_select" id="lang" value={props.config.LANG_ID} onChange={(e) => { handleChange(e.target.value)}}>
                                
                   {lang?.map((type, index) => {
                    
                    return(

                        <option key={index} value={type.id} >{type.name}</option>
                    )                   
                
                   })}

               </select>
                    
                
            </div>
        

        : ''                       

    );

    
}
