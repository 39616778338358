import React from "react";
import { withRouter } from "react-router";

//Component
import { loginsubscriber } from "../../components/loginService";
import ProductDetails from "../modal/ProductDetails/ProductDetails";

//Pages
import Banner from "./Banner";
import Details from "./Details";
import SelectAreaModal from "./selectAreaModal";

class Business extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      business: {},
      availableAreas: [],
      user: {},
      orderType: 0,
      deliveryPrice: this.props.deliveryPrice,
      deliveryDetails: {},
      activekey: "onlineOrder",
      show: false,
      productItem: {},
      modifiedProduct: {},
      showModal: false,
      isSelectedAreaModified: false,
      isOptionsModalOpen: false,
      isProductModified: false,
    };
  }
  componentDidMount() {
    sessionStorage.setItem("selectedIngredients", JSON.stringify([]));
    const query = window.location.pathname;
    const pathArr = query.split("/");
    loginsubscriber.subscribe((v) => {
      if (v) {
        let user = {
          id: sessionStorage.getItem("userId"),
          name: sessionStorage.getItem("userName"),
          last_name: sessionStorage.getItem("userLastName"),
        };
        this.setState({
          user: user,
        });
        if (pathArr[1] === "store" && pathArr[2].length > 0) {
          this.getBusiness(pathArr[2]);
        }
      } else {
        let user = {
          id: sessionStorage.getItem("userId"),
          name: sessionStorage.getItem("userName"),
          last_name: sessionStorage.getItem("userLastName"),
        };
        this.setState({
          user: user,
        });
      }
    });
    if (pathArr[1] === "store" && pathArr[2] !== "") {
      this.getBusiness(pathArr[2]);
    }
    if (
      sessionStorage.getItem("orderType") !== undefined &&
      sessionStorage.getItem("orderType") !== null
    ) {
      this.setState({
        orderType: sessionStorage.getItem("orderType"),
      });
    } else {
      this.setState({
        orderType: 0,
      });
      sessionStorage.setItem("orderType", 0);
    }
  }

  componentDidUpdate() {
    if (this.state.isSelectedAreaModified) {
      const selectedArea = sessionStorage.getItem("selectedArea");
      if (selectedArea && Object.keys(this.state.business).length) {
        let a = this.state.business.arealist.filter(
          (area) => area.name === selectedArea
        )[0];
        if (a) {
          sessionStorage.setItem("orderType", 1);
          this.props.setDeliveryPrice(parseFloat(a.deliveryprice));
        } else {
          this.props.setDeliveryPrice(0);
        }
      } else {
        this.props.setDeliveryPrice(0);
      }
      this.setIsSelectedAreaModified(false);
    }
  }

  setIsSelectedAreaModified = (x) => {
    this.setState({
      isSelectedAreaModified: x,
    });
  };

  setIsProductModified = (x) => {
    this.setState({
      isProductModified: x,
    });
  };

  setModifiedProduct = (x) => {
    this.setState({
      modifiedProduct: x,
    });
  };

  changeSelectedChoiceInProductOption = (
    product,
    setId,
    optionId,
    choiceId
  ) => {
    let sets = product.sets.filter((set) => set.id === setId)[0];
    let options = sets.options.filter((option) => option.id === optionId)[0];
    let choices = options.choice;
    if (options.type === 0) {
      //radio
      choices = choices.map((choice) => {
        if (choice.id === choiceId && !choice.checked)
          return {
            ...choice,
            checked: true,
          };
        else if (choice.id === choiceId && choice.checked) {
          return {
            ...choice,
            checked: false,
          };
        } else return choice;
      });
    } else {
      // other
      choices = choices.map((choice) => {
        console.log(choice.id, choiceId, choice.checked);
        if (choice.id === choiceId && !choice.checked)
          return {
            ...choice,
            checked: true,
          };
        else if (choice.id === choiceId && choice.checked) {
          return {
            ...choice,
            checked: false,
          };
        } else return choice;
      });
    }
    let product2, sets2, options2, choice2;
    sets2 = product.sets.map((set) => {
      if (set.id !== setId) return set;
      else {
        options2 = set.options.map((option) => {
          if (option.id !== optionId) return option;
          else {
            choice2 = choices;
            return {
              ...option,
              choice: choice2,
            };
          }
        });
        return {
          ...set,
          options: options2,
        };
      }
    });
    product2 = { ...product, sets: sets2 };
    this.setState({
      modifiedProduct: product2,
    });
    this.setIsProductModified(true);
  };
  setIsOptionsModalOpen = (x) => {
    this.setState({
      isOptionsModalOpen: x,
    });
  };
  handleShow = () => {
    this.setState({
      showModal: true,
    });
  };
  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };
  getBusiness = async (slug) => {
    try {
      let res = await fetch(
        this.props.config.api.url + "api/business/business",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            f: "getbusinessid",
            customurl: slug,
            langId: this.props.config.LANG_ID,
            uid:
              sessionStorage.getItem("userId") === undefined ||
              sessionStorage.getItem("userId") === null
                ? -1
                : sessionStorage.getItem("userId"),
          }),
        }
      );
      let result = await res.json();
      if (result) {
        this.setState({
          business: result,
        });
      } else {
        alert("No Business Found");
        this.props.history.push("/");
      }
    } catch (err) {
      console.log(err);
    }
  };
  TabToggle = () => {
    this.setState({
      activekey: "review",
    });
  };
  addToFav = async () => {
    if (
      sessionStorage.getItem("userId") === undefined ||
      sessionStorage.getItem("userId") === null
    ) {
      alert("Please Login First");
      return;
    }
    let res = await fetch(this.props.config.api.url + "api/business/business", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        f: "addtofav",
        bId: this.state.business.id,
        userId: sessionStorage.getItem("userId"),
      }),
    });
    let result = await res.json();

    if (result && result.status) {
      this.setState((prevState) => ({
        business: {
          ...prevState.business,
          addtofav: true,
        },
      }));
    }
    if (result && !result.status) {
      this.setState((prevState) => ({
        business: {
          ...prevState.business,
          addtofav: false,
        },
      }));
    }
  };
  setOrderType = (type, price, details) => {
    if (type === "1") {
      let area = this.state.business.arealist
        .map((area) => area.name)
        .filter((item, index) => index > 0);
      this.setState({
        availableAreas: area,
      });
      this.handleShow();
    } else if (type === "0" || type === "2") {
      sessionStorage.removeItem("selectedArea");
      sessionStorage.removeItem("deliveryPrice");
      this.setIsSelectedAreaModified(true);
    }
    if (type)
      this.setState({
        orderType: parseInt(type),
      });
    this.setState({
      deliveryPrice: price,
    });
    this.setState({
      deliveryPrice: details,
    });
    this.onlineOrder.changeProductByorderType(
      this.state?.business?.id,
      this.state?.business?.code,
      parseInt(type)
    );
  };

  ProductDetailsItem = (val) => {
    this.setState({
      productItem: val,
    });
    this.productDetailsModal("show", true);
  };
  productDetailsModal = (property, val) => {
    this.setState({
      [property]: val,
    });
  };

  render() {
    return (
      <>
        <SelectAreaModal
          showModal={this.state.showModal}
          availableAreas={this.state.availableAreas}
          setIsSelectedAreaModified={this.setIsSelectedAreaModified}
          handleClose={this.handleClose}
          currentBusiness={this.state.business}
          setIsCartUpdated={this.props.setIsCartUpdated}
        />
        <Banner
          business={this.state.business}
          deliveryPrice={this.props.deliveryPrice}
          orderType={this.state.orderType}
          activekey={this.state.activekey}
          addToFav={this.addToFav}
          onClick={this.TabToggle}
        />
        {this.state?.business?.id > 0 ? (
          <Details
            setIsCartUpdated={this.props.setIsCartUpdated}
            setIsSelectedAreaModified={this.setIsSelectedAreaModified}
            business={this.state.business}
            user={this.state.user}
            orderType={this.state.orderType}
            activekey={this.state.activekey}
            config={this.props.config}
            setOrderType={this.setOrderType}
            onRef={(ref) => (this.onlineOrder = ref)}
            modifiedProduct={this.state.modifiedProduct}
            productDetailsItem={this.ProductDetailsItem}
            setIsDropdownDeliveryClicked={this.setIsDropdownDeliveryClicked}
            setIsOptionsModalOpen={this.setIsOptionsModalOpen}
            isProductModified={this.state.isProductModified}
            setIsProductModified={this.setIsProductModified}
          />
        ) : (
          ""
        )}
        {this.state?.show ? (
          <ProductDetails
            show={this.state?.show}
            modifiedProduct={this.state.modifiedProduct}
            isOptionsModalOpen={this.state.isOptionsModalOpen}
            setIsOptionsModalOpen={this.setIsOptionsModalOpen}
            setIsCartUpdated={this.setIsCartUpdated}
            business={this.state?.business}
            product={this.state?.productItem}
            productDetailsModal={this.productDetailsModal}
            orderType={this.state.orderType}
            deliveryPrice={this.state.deliveryPrice}
            isSelectedAreaModified={this.state.isSelectedAreaModified}
            changeSelectedChoiceInProductOption={
              this.changeSelectedChoiceInProductOption
            }
            setIsProductModified={this.setIsProductModified}
            isProductModified={this.state.isProductModified}
            {...this.props}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

export default withRouter(Business);
