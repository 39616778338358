import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, InputGroup, Button, Row, Col } from "react-bootstrap";

// img
import logo1 from "../../assets/img/rest-logo1.png";

import Skeleton from "react-loading-skeleton";

//custom alert

import CustomAlert from "../alert/CustomAlert";

function FavouriteRestaurant(props) {
  const [language, setLang] = useState(null);
  const [favBusiness, setFavBusiness] = useState([]);
  const [foundBus, setFoundBus] = useState([]);
  const [loding, setLoding] = useState(true);

  // the value of the search field
  const [search, setSearch] = useState("");

  //alert start
  const [show, setShow] = useState(true);
  const [alert_coustom, setAlert] = useState({
    type: null,
    text: [],
    show: false,
  });

  function onCloseAlert() {
    setAlert({
      show: false,
      type: null,
      text: [],
    });
  }

  function onShowAlert(type, msg) {
    setAlert({
      type: type,
      text: msg,
      show: true,
    });
  }

  //alert end

  useEffect(() => {
    setTimeout(function () {
      let config_data = props.config.config;
      setLang(config_data.LANGUAGE[config_data.LANG_ID]);
      getFavBusiness();
    }, 2000);
  }, [props.config.config, language]);

  async function getFavBusiness() {
    let userId = sessionStorage.getItem("userId");

    let res = await fetch(
      props.config.config.api.url + "api/business/business",
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          f: "allfav",
          userId: userId,
          langId: sessionStorage.getItem("langId"),
        }),
      }
    );
    let result = await res.json();

    if (result) {
      setLoding(false);
      setFavBusiness(result);
      setFoundBus(result);
    }
  }

  async function deleteFav(val) {
    const all_error_msg = [];
    let res = await fetch(
      props.config.config.api.url + "api/business/business",
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          f: "deletefav",
          Id: val,
        }),
      }
    );
    let result = await res.json();

    if (result.status === true) {
      all_error_msg.push(result.message);
      onShowAlert("success", all_error_msg);
      getFavBusiness();
      setTimeout(function () {
        onCloseAlert();
      }, 1000);
    } else {
      all_error_msg.push(result.message);
      onShowAlert("error", all_error_msg);
      setTimeout(function () {
        onCloseAlert();
      }, 1000);
    }
  }

  const filter = (e) => {
    const keyword = e;

    if (keyword !== "") {
      const results = favBusiness.filter((bus) => {
        return bus.name.toLowerCase().startsWith(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setFoundBus(results);
    } else {
      setFoundBus(favBusiness);
      // If the text field is empty, show all users
    }

    setSearch(keyword);
  };

  return (
    <>
      <h1 className="container-heading">
        <span>Search</span> business
      </h1>
      <Form>
        <Form.Group>
          <Row>
            <Col md={12} sm={12}>
              <InputGroup className="grey">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <i className="fas fa-search"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  id=""
                  placeholder="Search"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    filter(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>
          </Row>
        </Form.Group>
      </Form>

      <CustomAlert
        header={language != null ? language.FAVOURITE_BUSINESS : ""}
        btnText={language != null ? language.ACCEPT : ""}
        text={alert_coustom.text}
        type={alert_coustom.type}
        show={alert_coustom.show}
        onClosePress={onCloseAlert}
        pressCloseOnOutsideClick={true}
        alertStyles={{}}
        headerStyles={{}}
        textStyles={{}}
        buttonStyles={{}}
      />

      {loding === false && foundBus.length > 0 ? (
        <Business favBusiness={foundBus} deleteFav_f={deleteFav} />
      ) : loding === true ? (
        <div className="business-item-cont">
          {[...Array(3)].map((item, i) => (
            <div key={i} className="business-item">
              <Skeleton className="business-logo" />
              <div className="business-description">
                <div className="busn-details">
                  <h3>
                    <Skeleton width={300} height={25} />
                  </h3>
                  <ul>
                    <li className="address">
                      <Skeleton width={300} height={25} />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : loding === false && foundBus.length == 0 ? (
        <div className="business-item-cont">
          <div className="business-item">
            <div className="business-description">
              <div className="busn-details">
                <h3>
                  {language != null ? language.NO_FAVOURITE_BUSINESS_FOUND : ""}
                </h3>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

const Business = React.memo(({ favBusiness, deleteFav_f }) => {
  function deleteFav_is(id) {
    deleteFav_f(id);
  }

  return (
    <div className="business-item-cont">
      {favBusiness.map((bus) => {
        const {
          id,
          name,
          mobile,
          address,
          ratings,
          is_img,
          is_banner,
          open,
          slug,
        } = bus;
        let bImage;
        if (is_img.is_img === 1) {
          bImage = is_img.data.secure_url;
        } else {
          bImage = logo1;
        }

        return (
          <div key={id} className="business-item">
            <img src={bImage} className="business-logo" alt="Menuhuts" />
            <div className="business-description">
              <div className="busn-details">
                <h3>{name}</h3>
                <ul>
                  <li className="phone">{mobile}</li>
                  <li className="address">{address}</li>
                </ul>
              </div>

              <a onClick={() => deleteFav_is(id)} className="favorite-icon">
                <i className="fas fa-heart"></i>
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default FavouriteRestaurant;
