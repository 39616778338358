import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Container, Col, Form, Button, InputGroup, } from "react-bootstrap";


import BusinessType from './BusinessType';


let autoComplete;

const loadScript = (url, callback) => {
    let id = 'googleMaps'
    if (document.getElementById(id) === null) {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.id = id;

        if (script.readyState) {
            script.onreadystatechange = function () {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {
            script.onload = () => callback();
        }

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    }else{
        callback();
    }
};

function handleScriptLoad(updateQuery, updateLocation, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current
    );
    autoComplete.setFields(["address_components", "formatted_address", "geometry"]);
    autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(updateQuery, updateLocation)
    );
}

async function handlePlaceSelect(updateQuery, updateLocation) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    console.log(addressObject);
    let zip;
    for (let i = 0; i < addressObject.address_components.length; i++) {
        for (let j = 0; j < addressObject.address_components[i].types.length; j++) {
            if (addressObject.address_components[i].types[j] === 'postal_code') {
                zip = addressObject.address_components[i].long_name;
            }
        }
    }
    let lctn;
    lctn = {
        address: addressObject.formatted_address,
        latitude: addressObject.geometry.location.lat(),
        longitude: addressObject.geometry.location.lng(),
        zipcode: zip,
        zoom: 12
    }
    updateLocation(lctn);
}

const Banner = (props) => {

    const [query, setQuery] = useState("");
    const [location, setLocation] = useState({
        address: '',
        latitude: '',
        longitude: '',
        zipcode: '',
        zoom: 12
    });
    const autoCompleteRef = useRef(null);

    const history = useHistory();

    const doSearch = () => {
        if (location.latitude === '' && location.longitude === '') {
            alert('Choose Address from google autocomplete')
            return
        }
        let lctn = [];
        lctn[0] = location.latitude;
        lctn[1] = location.longitude;
        history.push('/search/' + location.latitude + ',' + location.longitude);
        props.onClick(lctn);
    }

    useEffect(() => {
        setTimeout(function(){
            loadScript(
                'https://maps.googleapis.com/maps/api/js?key='+ props.config.google_map_api_key +'&libraries=places',
                () => handleScriptLoad(setQuery, setLocation, autoCompleteRef)
            );
    
        },2000);
        
    }, []);


    return (
        <section className="banner inner">
            
            <Container fluid>
                <Col>
                    <h2>Your favorite food, delivered with MenuHuts</h2>

                    <Form>
                        <Form.Group>
                        <BusinessType btype={props.btype} businessTypeChange_r={props.businessTypeChange_r} selected_btype={props.selected_btype} />
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text><i className="fas fa-store"></i></InputGroup.Text>
                                </InputGroup.Prepend>
                                <input ref={autoCompleteRef}
                                    onChange={event => setQuery(event.target.value)}
                                    className="form-control" placeholder="Enter business near me by zipcode"
                                    value={query} disabled={props.isLoading} />
                            </InputGroup>

                            <Button variant="primary" disabled={props.isLoading} onClick={!props.isLoading ? doSearch : null} >{props.isLoading ? 'Loading…' : 'Search'}</Button>

                        </Form.Group>
                    </Form>
                </Col>
            </Container>
        </section>
    );
}

export default Banner;