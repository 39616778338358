import React from 'react';
import { Link } from "react-router-dom";
import { Container, Col } from "react-bootstrap";
import OwlCarousel from 'react-owl-carousel';

import Skeleton from 'react-loading-skeleton';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { useBusinessTypeFetch } from '../../components/fetch'

// img
import americanFood from '../../assets/img/american.png';



const options = {
    loop: false,
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    smartSpeed: 1000,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    responsive: {
        0: {
            items: 2,

        },
        400: {
            items: 2,
        },
        500: {
            items: 3,
        },
        576: {
            items: 3,
        },
        768: {
            items: 4,
            // nav: true,
        },
        992: {
            items: 5,
            // nav: true,
        },
        1200: {
            items: 7,
            // nav: true,
        },
    },
};


const Btype = props => {
    const { btype } = useBusinessTypeFetch(props.config.api.url + 'api/allBusinessType');

    return (
        <div className="cuisine">
            <Container fluid>
                <Col className="cuisine-card">
                {
                    btype?.length > 0 ?
                        <OwlSlider btype={btype} />
                    :
                        <Skeleton width={1290} height={119} />
                }                    
                </Col>
            </Container>
        </div>
    )
}

const OwlSlider = React.memo(({ btype }) => {
    { /*<Link to={`businesstype`} state={{ btype_id:id }} key={id} className="item">*/ }
    {/*<Link to={{ pathname: '/businesstype', state: {fromNotifications: true}}}  key={id} className="item">*/}
    {/*<Link to={`businesstype`} params={{ testvalue: "hello" }} key={id} className="item"> */}
    return (
        <OwlCarousel className="owl-theme" {...options}>
            {btype?.map((type) => {
                const { id, name, image} = type;
                return (
                    <Link to={`businesstype/${id}`} key={id} className="item">
                       
                        { image !== '' 
                        
                        ? 
                        <img src={image} className="cuisine-icon" alt="Menuhuts" />
                        :
                        <img src={americanFood} className="cuisine-icon" alt="Menuhuts" />
                        }
                        <h5>{name}</h5>
                    </Link>
                );
            })}
        </OwlCarousel>
    )
})

export default Btype;