import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from "react-router-dom";
import { Container, Col, Form, InputGroup, } from "react-bootstrap";

const Cities = ({ countries, country_code, config }) => {

    const [defaultCountry, setdefaultCountry] = useState(1);
    const [cities, setCities] = useState([]);
    const [language, setLang] = useState(null);

    /*const getCities = (id) => {
        let newCities = countries.filter((country) => country.id === parseInt(id));
        if (newCities[0])
            setCities(newCities[0].city);
    };*/

    async function getCities(id){

        let res = await fetch(config.api.url + 'api/countrycity/citybycountry', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                langId : sessionStorage.getItem('langId'),
                country_id: id,
            })
        });
        let result = await res.json();
        
        if (result.success === true) {

            setCities(result.data);  
        } 
    }

    useEffect(() => {
        setTimeout(function(){
            let config_data = config;
           setLang(config_data.LANGUAGE[config_data.LANG_ID]);
            
        },2000)
        // eslint-disable-next-line
        countries.filter((country) => {
            if (country.gcode.toLowerCase() === country_code.toLowerCase()) {
                getCities(country.id);
                setdefaultCountry(country.id)
            }
        });
        // eslint-disable-next-line    
    }, [countries,config,language]);


    const countryChange = (event) => {
        setdefaultCountry(event.target.value)
        getCities(event.target.value);
    };

    return (
        <section className="choose-city">
            <Container fluid>
                <Col>
                    <div className="city-heading">
                        <h1 className="container-heading">
                            <span>{language != null ? language.CHOOSE : ''}</span> {language != null ? language.YOUR_CITY : ''}
                        </h1>
                        <Form className="select-country">
                            <Form.Group>
                                <InputGroup className="select-group">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text><i className="fas fa-globe-americas"></i></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control as="select" value={defaultCountry} onChange={countryChange}>
                                        {countries?.map((e, key) => {
                                            return <option key={key} value={e.id}>{e.name}</option>;
                                        })}
                                    </Form.Control>
                                </InputGroup>
                            </Form.Group>
                        </Form>
                    </div>

                    {cities?.length > 0 ?
                        <ul className="city-list row">
                            {cities?.map((city) => {
                                const { id, name } = city;
                                return (
                                    <li key={id} className="col-md-3 col-sm-6"><Link to={`city/${id}`}>{name}</Link></li>
                                );
                            })}
                        </ul>
                        :
                        <ul className="city-list row">
                            {[...Array(12)].map((item, i) => (
                                <li key={i} className="col-md-3 col-sm-6"><Skeleton height={40} width={344} /></li>
                            ))}
                        </ul>

                    }
                </Col>
            </Container>
        </section>
    );
}

export default Cities;