import React from "react";
import { Form, InputGroup, Button, Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

// img
import client1 from "./../../../assets/img/client-1.jpg";

function generateData(data) {
  if (data !== undefined) {
    var rows = [];
    for (var i = 1; i <= 5; i++) {
      if (i <= data) {
        rows.push(<i className="fas fa-star active" key={i}></i>);
      } else {
        rows.push(<i className="fas fa-star" key={i}></i>);
      }
    }
    return rows;
  }
}

class Review extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      review: [],
      addReview: {
        review1: 0,
        review2: 0,
        review3: 0,
        review4: 0,
        name: "",
        email: "",
        msg: "",
        buttonDisabled: false,
      },
    };
  }
  componentDidMount() {
    if (this.props?.business?.id > 0) {
      this.getReviewBusiness(this.props?.business?.id);
    }
  }
  getReviewBusiness = async (id) => {
    const response = await fetch(
      this.props.config.api.url + "api/review?" + id
    );
    const review = await response.json();
    let data = review.data;
    this.setState({ review: data });
  };
  setReview(property, val) {
    console.log(property, val);

    this.setState((prevState) => ({
      ...prevState.review,
      addReview: {
        ...prevState.addReview,
        [property]: val,
      },
    }));
  }
  setInputValue(property, val) {
    console.log(property, val);
    this.setState((prevState) => ({
      ...prevState.review,
      addReview: {
        ...prevState.addReview,
        [property]: val,
      },
    }));
  }
  resetForm() {
    this.setState((prevState) => ({
      ...prevState.review,
      addReview: {
        review1: 0,
        review2: 0,
        review3: 0,
        review4: 0,
        name: "",
        email: "",
        msg: "",
        buttonDisabled: false,
      },
    }));
  }
  async doReview() {
    if (!this.state.addReview.review1) {
      alert("Please select review of Product quality");
      return;
    }
    if (!this.state.addReview.review2) {
      alert("Please select review of Punctuality");
      return;
    }
    if (!this.state.addReview.review3) {
      alert("Please select review of Service");
      return;
    }
    if (!this.state.addReview.review4) {
      alert("Please select review of Food packing");
      return;
    }
    if (!this.state.addReview.name) {
      alert("Please Enter your name");
      return;
    }
    if (!this.state.addReview.email) {
      alert("Please Enter your email");
      return;
    }
    if (!this.state.addReview.msg) {
      alert("Please Enter your message");
      return;
    }
    this.setState({
      buttonDisabled: true,
    });
    try {
      let res = await fetch(this.props.config.api.url + "api/addReview", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          review1: this.state.addReview.review1,
          review2: this.state.addReview.review2,
          review3: this.state.addReview.review3,
          review4: this.state.addReview.review4,
          name: this.state.addReview.name,
          email: this.state.addReview.email,
          msg: this.state.addReview.msg,
          business: this.props.business?.id,
        }),
      });
      let result = await res.json();
      if (result && result.success === true) {
        this.getReviewBusiness(this.props?.business?.id);
        alert(result.msg);
      } else {
        alert(result.msg);
      }
      this.resetForm();
    } catch (e) {
      console.log(e);
      this.setState({
        buttonDisabled: false,
      });
    }
  }
  render() {
    return (
      <>
        <div className="other-container business-review">
          <Row>
            <Col md={6} sm={12}>
              {this.state.review?.length > 0 ? (
                <>
                  {this.state.review?.map((val, index) => {
                    const { id, name, date, comment, review, img } = val;
                    return (
                      <div className="reviewed-item" key={id}>
                        <div className="user-container">
                          <img
                            src={img !== "" ? img : client1}
                            alt="Menuhuts"
                          />
                          <div className="information">
                            <div className="left">
                              <h5 className="sub-heading">{name}</h5>
                              <span className="date">{date}</span>
                            </div>
                            <span className="stars">
                              {generateData(review)}
                            </span>
                          </div>
                        </div>
                        <p className="reviewed-des">{comment}</p>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>{null}</>
              )}
            </Col>
            <Col md={6} sm={12}>
              <h5 className="sub-heading">Add your review</h5>
              <Form>
                <Form.Group className="feature-container">
                  <Row>
                    <Col>
                      <div className="feature-points">
                        <span>Product quality</span>
                        <span className="stars">
                          <i
                            className={
                              this.state.addReview.review1 >= 1
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review1", 1)}
                          ></i>
                          <i
                            className={
                              this.state.addReview.review1 >= 2
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review1", 2)}
                          ></i>
                          <i
                            className={
                              this.state.addReview.review1 >= 3
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review1", 3)}
                          ></i>
                          <i
                            className={
                              this.state.addReview.review1 >= 4
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review1", 4)}
                          ></i>
                          <i
                            className={
                              this.state.addReview.review1 >= 5
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review1", 5)}
                          ></i>
                        </span>
                      </div>

                      <div className="feature-points">
                        <span>Punctuality</span>
                        <span className="stars">
                          <i
                            className={
                              this.state.addReview.review2 >= 1
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review2", 1)}
                          ></i>
                          <i
                            className={
                              this.state.addReview.review2 >= 2
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review2", 2)}
                          ></i>
                          <i
                            className={
                              this.state.addReview.review2 >= 3
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review2", 3)}
                          ></i>
                          <i
                            className={
                              this.state.addReview.review2 >= 4
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review2", 4)}
                          ></i>
                          <i
                            className={
                              this.state.addReview.review2 >= 5
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review2", 5)}
                          ></i>
                        </span>
                      </div>

                      <div className="feature-points">
                        <span>Service</span>
                        <span className="stars">
                          <i
                            className={
                              this.state.addReview.review3 >= 1
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review3", 1)}
                          ></i>
                          <i
                            className={
                              this.state.addReview.review3 >= 2
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review3", 2)}
                          ></i>
                          <i
                            className={
                              this.state.addReview.review3 >= 3
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review3", 3)}
                          ></i>
                          <i
                            className={
                              this.state.addReview.review3 >= 4
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review3", 4)}
                          ></i>
                          <i
                            className={
                              this.state.addReview.review3 >= 5
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review3", 5)}
                          ></i>
                        </span>
                      </div>

                      <div className="feature-points">
                        <span>Food packing</span>
                        <span className="stars">
                          <i
                            className={
                              this.state.addReview.review4 >= 1
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review4", 1)}
                          ></i>
                          <i
                            className={
                              this.state.addReview.review4 >= 2
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review4", 2)}
                          ></i>
                          <i
                            className={
                              this.state.addReview.review4 >= 3
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review4", 3)}
                          ></i>
                          <i
                            className={
                              this.state.addReview.review4 >= 4
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review4", 4)}
                          ></i>
                          <i
                            className={
                              this.state.addReview.review4 >= 5
                                ? "fas fa-star active"
                                : "fas fa-star"
                            }
                            onClick={() => this.setReview("review4", 5)}
                          ></i>
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={6} sm={12}>
                      <InputGroup className="grey">
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            <i className="fas fa-user"></i>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          placeholder="Name"
                          value={this.state.addReview.name}
                          onChange={(e) =>
                            this.setInputValue("name", e.target.value)
                          }
                        />
                      </InputGroup>
                    </Col>
                    <Col md={6} sm={12}>
                      <InputGroup className="grey">
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            <i className="fas fa-envelope"></i>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          placeholder="Email"
                          value={this.state.addReview.email}
                          onChange={(e) =>
                            this.setInputValue("email", e.target.value)
                          }
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={12} sm={12}>
                      <InputGroup className="grey textarea">
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            <i className="fas fa-comment"></i>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          as="textarea"
                          placeholder="Message"
                          value={this.state.addReview.msg}
                          onChange={(e) =>
                            this.setInputValue("msg", e.target.value)
                          }
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Button
                    variant="primary"
                    disabled={this.state.addReview.buttonDisabled}
                    onClick={() => this.doReview()}
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Review;
