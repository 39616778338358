import React, { useState, useEffect } from "react";
import { Accordion, Card, Form } from "react-bootstrap";

function ProductOptions(props) {
  const changeSelectedChoices = (setId, optionId, choiceId) => {
    const productId = props.product.id;
    const set = props.product.sets.filter((set) => set.id === setId)[0];
    const option = set.options.filter((op) => op.id === optionId)[0];
    const choice = option.choice.filter((ch) => ch.id === choiceId)[0];
    const optionType = option.type;
    let previousSelectedChoicesArr = JSON.parse(
      sessionStorage.getItem("selectedChoices")
    );
    const newItem = `${setId}-${optionId}-${choiceId}-${productId}`;
    if (optionType === 0) {
      previousSelectedChoicesArr = previousSelectedChoicesArr
        .map((choiceStr) => {
          const choiceStrSplit = choiceStr.split("-");
          if (parseInt(choiceStrSplit[1]) !== optionId) return choiceStr;
        })
        .filter((obj) => obj !== null && obj !== undefined);
      previousSelectedChoicesArr.push(newItem);
    } else {
      //checkbox
      let flag = false;
      previousSelectedChoicesArr = previousSelectedChoicesArr
        .map((choiceStr) => {
          if (choiceStr !== newItem) return choiceStr;
          else flag = true;
        })
        .filter((obj) => obj !== null && obj !== undefined);
      if (!flag) previousSelectedChoicesArr.push(newItem);
    }
    sessionStorage.setItem(
      "selectedChoices",
      JSON.stringify(previousSelectedChoicesArr)
    );
  };

  const changeIngredients = (isChecked, ingredientName) => {
    // [{ ingredientNames: [], productId: Number }, ...]
    let previousSelectedIngredients = JSON.parse(
      sessionStorage.getItem("selectedIngredients")
    );
    if (previousSelectedIngredients.length === 0 && isChecked) {
      previousSelectedIngredients.push({
        ingredientNames: [ingredientName],
        productId: props.product.id,
      });
    } else if (previousSelectedIngredients.length && isChecked) {
      if (
        previousSelectedIngredients.filter(
          (arrItem) => arrItem.productId === props.product.id
        ).length > 0
      ) {
        let x = previousSelectedIngredients.filter(
          (arrItem) => arrItem.productId === props.product.id
        )[0];
        x = {
          ingredientNames: [...x.ingredientNames, ingredientName],
          productId: props.product.id,
        };
        previousSelectedIngredients = previousSelectedIngredients.map(
          (arrItem) => {
            if (arrItem.productId === props.product.id) return x;
            else return arrItem;
          }
        );
      } else {
        previousSelectedIngredients.push({
          ingredientNames: [ingredientName],
          productId: props.product.id,
        });
      }
    } else if (previousSelectedIngredients.length && !isChecked) {
      if (
        previousSelectedIngredients.filter(
          (arrItem) => arrItem.productId === props.product.id
        ).length > 0
      ) {
        let x = previousSelectedIngredients.filter(
          (arrItem) => arrItem.productId === props.product.id
        )[0];
        x = {
          ingredientNames: x.ingredientNames.filter(
            (iname) => iname !== ingredientName
          ),
          productId: props.product.id,
        };
        previousSelectedIngredients = previousSelectedIngredients.map(
          (arrItem) => {
            if (arrItem.productId === props.product.id) return x;
            else return arrItem;
          }
        );
      }
    }
    sessionStorage.setItem(
      "selectedIngredients",
      JSON.stringify(previousSelectedIngredients)
    );
  };

  useEffect(() => {
    let currIngredients = props.product.ingredients;
    let x = JSON.parse(sessionStorage.getItem("selectedIngredients"));
    console.log(x);
    if (!x) x = [];
    if (x.length === 0) {
      x.push({
        ingredientNames: currIngredients,
        productId: props.product.id,
      });
    } else {
      if (
        x.filter((obj) => obj.productId.includes(props.product.id)).length === 0
      ) {
        x.push({
          ingredientNames: currIngredients,
          productId: props.product.id,
        });
      }
    }
    sessionStorage.setItem("selectedIngredients", JSON.stringify(x));
  }, []);

  return (
    <>
      {props.product.ingredients.length > 0 ? (
        <Accordion className="product-options" defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <div className="title">
                <h5>Ingredients</h5>
                <span>Please uncheck if you do not want</span>
              </div>
              <span className="angle">
                <i className="fas fa-angle-down"></i>
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form>
                  <Form.Group>
                    {
                      <div>
                        {props.product.ingredients.map((item, index) => (
                          <div key={index}>
                            <input
                              type="checkbox"
                              value={item}
                              id={`${index}`}
                              defaultChecked
                              onInput={(e) =>
                                changeIngredients(
                                  e.target.checked,
                                  e.target.value
                                )
                              }
                            />
                            <label htmlFor={`${index}`}>{item}</label>
                          </div>
                        ))}
                      </div>
                    }
                  </Form.Group>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      ) : null}
      {props?.product?.sets?.map((set, index) => {
        return set.options.map((option, num) => (
          <Accordion
            className="product-options"
            defaultActiveKey="0"
            key={`${index}-${num}`}
          >
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <div className="title">
                  <h5>Would you like to add this?</h5>
                  <span>
                    Choose {option.name}
                    {option.required ? (
                      <span style={{ color: "red" }}>*</span>
                    ) : null}
                  </span>
                </div>
                <span className="angle">
                  <i className="fas fa-angle-down"></i>
                </span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <Form>
                    <Form.Group>
                      {option.choice.map((choice) => {
                        if (option.type === 0)
                          return (
                            <div className="form-check" key={choice.id}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name={option.name}
                                value={choice.id}
                                id={choice.id}
                                onInput={(e) => {
                                  props.changeSelectedChoiceInProductOption(
                                    props.product,
                                    set.id,
                                    option.id,
                                    choice.id
                                  );
                                  changeSelectedChoices(
                                    set.id,
                                    option.id,
                                    choice.id
                                  );
                                  props.setIsProductModified(true);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={choice.id}
                              >
                                <span>{choice.name}</span>
                                <span>
                                  + {props.currencySymbol}
                                  {choice.price}
                                </span>
                              </label>
                            </div>
                          );
                        else
                          return (
                            <div className="form-check" key={choice.id}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={option.name}
                                value={choice.id}
                                id={choice.id}
                                onInput={(e) => {
                                  props.changeSelectedChoiceInProductOption(
                                    props.product,
                                    set.id,
                                    option.id,
                                    choice.id
                                  );
                                  changeSelectedChoices(
                                    set.id,
                                    option.id,
                                    choice.id
                                  );
                                  props.setIsProductModified(true);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={choice.id}
                              >
                                <span>{choice.name}</span>
                                <span>
                                  + {props.currencySymbol}
                                  {choice.price}
                                </span>
                              </label>
                            </div>
                          );
                      })}
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ));
      })}
    </>
  );
}

export default ProductOptions;
