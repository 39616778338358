import React from "react";
import { Link } from "react-router-dom";
import { Modal, Form, Button } from "react-bootstrap";

// components
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";

// img
import user from "../../assets/img/user.png";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";

import UserStore from "../../components/UserStores";
import auth from "../../components/auth";
import { loginsubscriber } from "../../components/loginService";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";

//custom alert

import CustomAlert from "../alert/CustomAlert";
import "../../styles/SignIn.css";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isLoading: false,
      modalShow: false,
      passType: "password",
      open_err: false,
      err_msg: "",
      language: null,
      type: "error",
      text: [],
      show: false,
    };
    //type:'error',
    //   text:'This is a alert message',
    //   show:false
  }

  componentDidMount() {
    // Changing the state after 2 sec
    // from the time when the component
    // is rendered
    setTimeout(() => {
      this.setState({
        language: this.props.config.LANGUAGE[this.props.config.LANG_ID],
      });
    }, 1000);
  }

  handleToClose = (event, reason) => {
    if ("clickaway" === reason) return;

    this.setState({
      open_err: false,
    });
  };

  setInputValue(property, val) {
    val = val.trim();
    this.setState({
      [property]: val,
    });
  }
  resetForm() {
    this.setState({
      email: "",
      password: "",
      isLoading: false,
      passType: "password",
    });
  }
  async doLogin() {
    let flg = true;
    const all_error_msg = [];

    if (!this.state.email) {
      /*this.setState({open_err:true});
            this.setState({err_msg:"Please Enter your email and try again"});*/

      all_error_msg.push(this.state.language.ENTER_YOUR_EMAIL);

      if (flg === true) {
        flg = false;
      }

      /* this.onShowAlert('error',this.state.language.ENTER_YOUR_EMAIL); 

            return;*/
    }
    if (!this.state.password) {
      /*this.setState({open_err:true});
            this.setState({err_msg:"Please Enter your password and try again"});*/

      all_error_msg.push(this.state.language.ENTER_PASSWORD);

      if (flg === true) {
        flg = false;
      }
    }

    if (flg === true) {
      this.setState({
        isLoading: true,
      });
      try {
        let res = await fetch(this.props.config.api.url + "api/login", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: this.state.email,
            password: this.state.password,
          }),
        });
        let result = await res.json();
        if (result && result.success === true) {
          let results = result.data;
          UserStore.name = results.name;
          UserStore.last_name = results.last_name;
          UserStore.id = results.id;
          auth.login();
          //this.resetForm();
          // this.signInClose();
          sessionStorage.setItem("userId", results.id);
          sessionStorage.setItem("userName", results.name);
          sessionStorage.setItem("userLastName", results.last_name);
          sessionStorage.setItem("user-details", JSON.stringify(results));
          loginsubscriber.next(true);
        } else {
          /*this.setState({
                                open_err: true,
                                err_msg: result.msg
                            });*/
          this.onShowAlert("error", result.msg);
          this.setState({
            open_err: true,
          });
          this.setState({
            isLoading: false,
          });
        }
      } catch (e) {
        console.log("Error caught");
        this.setState({
          open_err: true,
        });
        this.resetForm();
      }
    } else {
      this.onShowAlert("error", all_error_msg);
      this.setState({
        open_err: true,
      });
    }
  }
  responseFacebook(response) {
    console.log(response);
  }
  responseGoogle(response) {
    console.log(response);
  }

  signInShow = () => {
    this.setState({
      modalShow: true,
    });
  };

  signInClose = () => {
    this.setState({
      modalShow: false,
    });
  };

  async signInShow() {
    this.setState({
      modalShow: true,
    });
  }

  async signInClose() {
    this.setState({
      modalShow: false,
    });
  }
  async passVisible() {
    this.setState({
      passType: "text",
    });
  }
  async passHidden() {
    this.setState({
      passType: "password",
    });
  }

  /*const [alert, setAlert] = React.useState({
        type: 'error',
        text: 'This is a alert message',
        show: false
      })*/

  onCloseAlert = () => {
    this.setState({
      show: false,
      type: null,
      text: [],
    });
  };

  onShowAlert = (type, msg) => {
    this.setState({
      type: type,
      text: [msg],
      show: true,
    });
  };

  render() {
    return this.state.language !== null ? (
      <div className="main-container-signin">
        <Link to={`#`} onClick={() => this.signInShow()}>
          <img
            src={user}
            className="nav-icon"
            alt="Menuhuts"
            width="35px"
            height="35px"
          />
          <span>
            {this.state.language != null ? this.state.language.SIGN_IN : ""}
          </span>
        </Link>

        <Modal show={this.state.modalShow} onHide={() => this.signInClose()}>
          <Modal.Header closeButton>
            <h1 className="modal-title">
              <span>
                {this.state.language != null ? this.state.language.SIGN_IN : ""}
              </span>{" "}
              {this.state.language != null ? this.state.language.NOW : ""}
            </h1>
          </Modal.Header>
          <Modal.Body>
            <CustomAlert
              header={
                this.state.language != null
                  ? this.state.language.SIGN_IN
                  : "Sign In"
              }
              btnText={
                this.state.language != null
                  ? this.state.language.ACCEPT
                  : "Accept"
              }
              text={this.state.text}
              type={this.state.type}
              show={this.state.show}
              onClosePress={this.onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
            />
            <Snackbar
              anchorOrigin={{
                horizontal: "center",
                vertical: "top",
              }}
              open={this.state.open_err}
              autoHideDuration={5000}
              message={this.state.err_msg}
              onClose={this.handleToClose}
              action={
                <React.Fragment>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleToClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />

            <Form>
              <Form.Group>
                {/*<Form.Label>{this.state.language != '' ? this.state.language.EMAIL : ''}</Form.Label>*/}
                <Form.Control
                  type="email"
                  className="box"
                  placeholder={
                    this.state.language != null
                      ? this.state.language.EMAIL
                      : "Enter email"
                  }
                  value={this.state.email ? this.state.email : ""}
                  onChange={(event) =>
                    this.setInputValue("email", event.target.value)
                  }
                />
              </Form.Group>

              <Form.Group>
                {/*<Form.Label>{this.state.language.PASSWORD}</Form.Label> */}
                <Form.Control
                  type={this.state.passType.toString()}
                  className="box"
                  placeholder={
                    this.state.language != null
                      ? this.state.language.PASSWORD
                      : "*******"
                  }
                  value={this.state.password ? this.state.password : ""}
                  onChange={(event) =>
                    this.setInputValue("password", event.target.value)
                  }
                />
                {this.state.passType === "password" ? (
                  <i
                    className="far fa-eye-slash show-password"
                    onClick={() => this.passVisible()}
                  ></i>
                ) : (
                  <i
                    className="far fa-eye show-password"
                    onClick={() => this.passHidden()}
                  ></i>
                )}
              </Form.Group>

              <Form.Group>
                <Button
                  variant="secondary"
                  className="box"
                  disabled={this.state.isLoading}
                  onClick={() => this.doLogin()}
                >
                  {this.state.isLoading ? "Loading…" : "Sign in"}
                </Button>
              </Form.Group>

              <Form.Group className="d-flex justify-content-between">
                <span>
                  {this.state.language != null
                    ? this.state.language.DONT_HAVE_ACCOUNT
                    : ""}{" "}
                  <SignUp
                    ref_from="model"
                    config={this.props.config}
                    signInShow={this.signInShow_f}
                    signInClose={this.signInClose_f}
                    api_url={this.props.config.api.url}
                  />
                </span>
                <ForgotPassword
                  config={this.props.config}
                  signInShow={this.signInShow_f}
                  signInClose={this.signInClose_f}
                  api_url={this.props.config.api.url}
                />
              </Form.Group>

              <Form.Group className="otherwise">
                <span>
                  {this.state.language != null ? this.state.language.OR : ""}
                </span>
              </Form.Group>

              <Form.Group>
                <FacebookLogin
                  appId="1984330021864531"
                  autoLoad={false}
                  fields="name,email,picture"
                  scope="public_profile,user_friends,user_actions.books"
                  callback={this.responseFacebook}
                  render={(renderProps) => (
                    <button
                      className="btn btn-facebook"
                      disabled={renderProps.disabled}
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          this.state.email.length > 0 &&
                          this.state.password.length > 0
                        ) {
                          this.doLogin();
                          return;
                        }
                        renderProps.onClick();
                      }}
                    >
                      <i className="fab fa-facebook-f"></i>
                      {this.state.language != null
                        ? this.state.language.CONTINUE_WITH_FACEBOOK
                        : ""}
                    </button>
                  )}
                />
                <GoogleLogin
                  clientId="89089670257-2qqve59e124kaeune5bdeq594uj722v0.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <button
                      className="btn btn-google"
                      onClick={(e) => {
                        e.preventDefault();
                        renderProps.onClick();
                      }}
                      disabled={renderProps.disabled}
                    >
                      <i className="fab fa-google"></i>
                      {this.state.language != null
                        ? this.state.language.CONTINUE_WITH_GOOGLE
                        : ""}
                    </button>
                  )}
                  autoLoad={false}
                  buttonText="Login"
                  onSuccess={() => {
                    this.responseGoogle();
                  }}
                  onFailure={() => {
                    this.responseGoogle();
                  }}
                  cookiePolicy={"single_host_origin"}
                />

                {/* <Button type="submit" className="btn-facebook"><i className="fab fa-facebook-f"></i> Continue with Facebook</Button>
                                <Button type="submit" className="btn-google"><i className="fab fa-google"></i> Continue with Google</Button> */}
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    ) : (
      ""
    );
  }
}

export default SignIn;
