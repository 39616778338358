import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Tab, Nav, Form } from "react-bootstrap";

// components
import UpdateProfile from "./UpdateProfile";
import ChangePassword from "./ChangePassword";
import FavouriteRestaurant from "./FavouriteRestaurant";
import OrderHistory from "./OrderHistory";
import BookingHistory from "./BookingHistory";

//custom alert

import CustomAlert from "../alert/CustomAlert";

// img
import avtar from "./../../assets/img/profile.png";

function Profile(props) {
  const history = useHistory();
  const formData = new FormData();
  const [language, setLang] = useState(null);
  const [userdetails, setUserDetails] = useState(
    JSON.parse(sessionStorage.getItem("user-details"))
  );
  const [profile_image, setProfileImage] = useState(avtar);

  useEffect(() => {
    setTimeout(function () {
      let config_data = props.config;

      setLang(config_data.LANGUAGE[config_data.LANG_ID]);

      console.log(userdetails);
    }, 2000);

    if (sessionStorage.getItem("userId") > 0) {
      //console.log(JSON.parse(sessionStorage.getItem('user-details')));
      setUserDetails(JSON.parse(sessionStorage.getItem("user-details")));
      if (userdetails.img == null) {
        setProfileImage(avtar);
      } else {
        setProfileImage(userdetails.img);
      }
    } else {
      history.push("/");
    }
  }, [props.config]);

  //alert start

  const [alert_custom, setAlert] = useState({
    type: null,
    text: [],
    show: false,
  });

  function onCloseAlert() {
    setAlert({
      show: false,
      type: null,
      text: [],
    });
  }

  function onShowAlert(type, msg) {
    setAlert({
      type: type,
      text: msg,
      show: true,
    });
  }

  //alert end

  async function goToLogout() {
    await sessionStorage.removeItem("userId");
    await sessionStorage.removeItem("userName");
    await sessionStorage.removeItem("userLastName");
    await sessionStorage.removeItem("user-details");

    /* this.comservice.deleteService('Search');
        this.comservice.deleteService('businessID');
        this.comservice.deleteService('businessDetails');
        this.comservice.deleteService('cartDetails');
        this.comservice.deleteService('categoryDetails');
        this.comservice.deleteService('myClonedcategoryDetails');
        this.comservice.deleteService('MenuDetails');
        this.comservice.deleteService('orderType');
        this.comservice.deleteService('deliveryDetails');
        this.comservice.removeFromLocal('businessID');*/

    // this.authService.signOut();

    // window.location.href = props.config.SITE_URL;

    await history.push("/");
    window.location.reload();
  }

  function onSelectFile(event) {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      const reader = new window.FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        upload_profile(reader.result);
      };
    }
  }

  async function upload_profile(file_data) {
    console.log(file_data);

    const all_error_msg = [];

    try {
      let res = await fetch(props.config.api.url + "api/user/upload", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          langId: sessionStorage.getItem("langId"),
          myId: sessionStorage.getItem("userId"),
          myFile: file_data,
        }),
      });
      let result = await res.json();

      userdetails.img = result.data.secure_url;
      setProfileImage(userdetails.img);

      sessionStorage.setItem("user-details", JSON.stringify(userdetails));

      setUserDetails(JSON.parse(sessionStorage.getItem("user-details")));

      console.log(sessionStorage.getItem("user-details"));

      all_error_msg.push(language.SUCCESSFULLY_UPDATED);

      onShowAlert("success", all_error_msg);

      setTimeout(function () {
        onCloseAlert();
      }, 4000);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <section className="no-banner profile">
      <CustomAlert
        header={language != null ? language.UPDATE_PROFILE : ""}
        btnText={language != null ? language.ACCEPT : ""}
        text={alert_custom.text}
        type={alert_custom.type}
        show={alert_custom.show}
        onClosePress={onCloseAlert}
        pressCloseOnOutsideClick={true}
        alertStyles={{}}
        headerStyles={{}}
        textStyles={{}}
        buttonStyles={{}}
      />

      <Container fluid>
        <Col>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="UpdateProfile"
          >
            <Row className="profile-row">
              <Col md={4} sm={12} className="left-panel">
                <div className="my-profile-info">
                  <Form className="avtar-form" encType="multipart/form-data">
                    <Form.Group>
                      <Form.File
                        className="avtar-img"
                        accept="image/png, image/gif, image/jpeg"
                        id=""
                        onChange={(e) => onSelectFile(e)}
                        style={{ backgroundImage: `url(${profile_image})` }}
                      />
                      <i className="fas fa-camera"></i>
                    </Form.Group>
                  </Form>

                  <div className="avtar-details">
                    <h3>
                      {userdetails.name} {userdetails.last_name}
                    </h3>
                    <span>{userdetails.address}</span>
                  </div>
                </div>

                <Nav variant="pills" className="flex-column profile-tab">
                  <Nav.Item>
                    <Nav.Link eventKey="UpdateProfile">
                      <i className="fas fa-users"></i>Update profile
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="ChangePassword">
                      <i className="fas fa-key"></i>Change password
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="FavouriteRestaurant">
                      <i className="fas fa-heart"></i>Favourite restaurant
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="OrderHistory">
                      <i className="fas fa-bookmark"></i>Order history
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="BookingHistory">
                      <i className="fas fa-history"></i>Booking history
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="RewardHistory">
                      <i className="fas fa-gift"></i>Reward history
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link onClick={goToLogout}>
                      <i className="fas fa-power-off"></i>Log out
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>

              <Col md={8} sm={12}>
                <Tab.Content className="right-panel">
                  <Tab.Pane eventKey="UpdateProfile">
                    <UpdateProfile
                      config={props}
                      userdetails={userdetails}
                      setUserDetails={setUserDetails}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="ChangePassword">
                    <ChangePassword config={props} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="FavouriteRestaurant">
                    <FavouriteRestaurant config={props} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="OrderHistory">
                    <OrderHistory config={props} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="BookingHistory">
                    <BookingHistory config={props} />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
      </Container>
    </section>
  );
}

export default Profile;
