import React, { useState, useEffect } from 'react';
import { Form, InputGroup, Button, Row, Col, } from "react-bootstrap";

//custom alert

import CustomAlert from '../alert/CustomAlert'

function ChangePassword(props) {
    const [language, setLang] = useState(null);

    useEffect(() => {

        setTimeout(function(){
            let config_data = props.config.config;
            setLang(config_data.LANGUAGE[config_data.LANG_ID]);
        },2000)
   
   }, [props.config.config,language]);

    //alert start

    const [alert_custom, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }

    //alert end 

    const [current_password, setCurrentPassword] = useState("");
    const [new_password, setNewPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");

    const [current_passType, setCurrentPassType] = useState('password');
    const [new_passType, setNewPassType] = useState('password');
    const [confirm_passType, setConfirmPassType] = useState('password');

    function passHidden(type) {
        if(type === "new"){
            setNewPassType('password');
        }else if('confirm'){
            setConfirmPassType('password');
        }else if("current"){
            setCurrentPassType('password');
        }
        
    }

    function passVisible(type) {
        if(type === "new"){
            setNewPassType('text');
        }else if('confirm'){
            setConfirmPassType('text');
        }else if("current"){
            setCurrentPassType('text');
        }
       
    }

    const getFormData = async () =>{

        let flg = true;
        const all_error_msg = [];

        if (current_password.trim() === '') {
            
            all_error_msg.push(language.ENTER_CURRENT_PASSWORD);

            if(flg === true){
                flg = false;
            }
          }
          
        
        if (new_password.trim() === '') {
            
            all_error_msg.push(language.ENTER_NEW_PASSWORD);

            if(flg === true){
                flg = false;
            }
          }
          else if (new_password.length < 6) {
            
            all_error_msg.push(language.ENTER_YOUR_PASSWORD_LENGTH);

            if(flg === true){
                flg = false;
            }
          }
          if (confirm_password.trim() === '') {
            
            all_error_msg.push(language.ENTER_CONFIRM_PASSWORD);

            if(flg === true){
                flg = false;
            }
          }else if (confirm_password !== new_password) {
            
            all_error_msg.push(language.CONFIRM_PASSWORD_NOT_MATCHED_WITH_NEW);

            if(flg === true){
                flg = false;
            }
          }

          if(flg === true){
                 
                    try {
                        let res = await fetch(props.config.config.api.url + 'api/user/user', {
                            method: 'post',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                f: 'changepassword',
                                oldpass: current_password,
                                newpass: new_password,
                                userId : sessionStorage.getItem('userId'),
                            })
                        });
                        let result = await res.json();
                        if (result.status) {
                        
                            setCurrentPassword("");                       
                            setNewPassword("");
                            setConfirmPassword(""); 
                            
                            all_error_msg.push(language.PASSWORD_SUCCESSFULLY_CHANGED);
                            onShowAlert('success',all_error_msg); 

                            setTimeout(
                                function() {
                                    onCloseAlert();   
                                }
                                ,
                                4000
                            );
                            
                        } else {
                          
                            all_error_msg.push(language.CURRENT_PASSWORD_NOT_MATCHED_WITH_USER);
                            onShowAlert('error',all_error_msg); 
                        }
                    }
                    catch (e) {
                    console.log(e);
                    }
          }else{
            onShowAlert('error',all_error_msg); 
          }    
    }

    return (
        <>
            <h1 className="container-heading"><span>{language != null ? language.CHANGE : ''}</span> {language != null ? language.PASSWORD : ''}</h1>
            <Form>
            <CustomAlert
                    header={language != null ? language.CHANGE_PASSWORD : ''}
                    btnText={language != null ? language.ACCEPT : ''}
                    text={alert_custom.text}
                    type={alert_custom.type}
                    show={alert_custom.show}
                    onClosePress={onCloseAlert}
                    pressCloseOnOutsideClick={true}
                    alertStyles={{}}
                    headerStyles={{}}
                    textStyles={{}}
                    buttonStyles={{}}
                    />        
            <Form.Group>
                            <Row>
                                <Col md={6} sm={12}>
                                    <InputGroup className="grey">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>
                                            <i className="fas fa-key"></i>
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control type={current_passType} value={current_password} placeholder={language != null ? language.CURRENT_PASSWORD : ''} onChange={(e)=>setCurrentPassword(e.target.value)}/>
                                    </InputGroup>
                                </Col>
                                <Col md={6} sm={12}>
                                    <InputGroup className="grey">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>
                                            <i className="fas fa-key"></i>
                                            
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control type={new_passType} value={new_password} placeholder={language != null ? language.NEW_PASSWORD : ''} onChange={ (e)=>setNewPassword(e.target.value)}/>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group>
                            <Row>
                                <Col md={6} sm={12}>
                                    <InputGroup className="grey">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text><i className="fas fa-key"></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control type={confirm_passType} value={confirm_password} placeholder={language != null ? language.CONFIRM_PASSWORD : ''} onChange={(e)=>setConfirmPassword(e.target.value)}/>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Button variant="secondary" onClick={getFormData}>{language != null ? language.SUBMIT : ''}</Button>
            </Form>
        </>
    );
}

export default ChangePassword;