import React from 'react';
import { useHistory} from "react-router-dom";

export default function BusinessType(props){ 

    const history = useHistory();

    function handleChange(e){
        console.log(e);

        /*history.push('/businesstype/' + e);
        window.location.reload();*/

        props.businessTypeChange_r(e);
    }

    return (                              
        props.btype?.length > 0 ?
        
            <div className="select-group ">
                <select className="btype_lang_select" id="lang" value={props.selected_btype} onChange={(e) => { handleChange(e.target.value)}}>
                  <option key={'all'} value="0" >All</option>            
                   {props.btype?.map((type, index) => {
                    
                    return(

                        <option key={index} value={type.id} >{type.name}</option>
                    )                   
                
                   })}

               </select>
                
            </div>

        : ''                       

    );

    
}
