import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";

// Pages
import SignIn from "../modal/SignIn";
import AddBusiness from "../modal/AddBusiness";
import Cart from "./Cart";
import LangType from "../list/LangType";

// img
import logo from "../../assets/img/menuhuts_logo.png";

import { loginsubscriber } from "../../components/loginService";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: sessionStorage.getItem("userName"),
      last_name: sessionStorage.getItem("userLastName"),
      id: sessionStorage.getItem("userId"),
      cart: [],
      selectedIngredients: JSON.parse(
        sessionStorage.getItem("selectedIngredients")
      ),
      cartBusiness: {},
    };
    try {
      if (!sessionStorage.getItem("cart"))
        sessionStorage.setItem("cart", JSON.stringify([]));
      else {
        this.state.cart = JSON.parse(sessionStorage.getItem("cart"));
      }
      if (!sessionStorage.getItem("cartBusiness"))
        sessionStorage.setItem("cartBusiness", JSON.stringify({}));
      else
        this.state.cartBusiness = JSON.parse(
          sessionStorage.getItem("cartBusiness")
        );
    } catch (err) {
      console.log(err);
    }
  }

  componentDidMount() {
    loginsubscriber.subscribe((v) => {
      if (v) {
        let user = {
          name: sessionStorage.getItem("userName"),
          last_name: sessionStorage.getItem("userLastName"),
          id: sessionStorage.getItem("userId"),
        };
        this.setState(user);
      }
    });
  }

  componentDidUpdate() {
    if (this.props.isCartUpdated) {
      this.setState({
        cart: JSON.parse(sessionStorage.getItem("cart")),
        cartBusiness: JSON.parse(sessionStorage.getItem("cartBusiness")),
      });
      this.props.setIsCartUpdated(false);
    }
  }

  Redirect_to_home = () => {
    window.location.href = this.props.config.SITE_URL;
  };

  //to={`#`} className="navbar-brand" onClick={this.Redirect_to_home}

  render() {
    return (
      <Router>
        <Navbar fixed="top" expand="lg">
          <Container fluid>
            {/* <Link to={`#`} className="navbar-brand"  onClick={this.Redirect_to_home}></Link>*/}
            <a href="/" className="add-business" alt="">
              <img src={logo} className="nav-logo" alt="Menuhuts" />
            </a>

            <div className="nav-inner-container">
              <ul className="navbar-right">
                <li>
                  <Cart
                    cart={this.state.cart}
                    selectedIngredients={this.state.selectedIngredients}
                    cartBusiness={this.state.cartBusiness}
                    deliveryPrice={this.props.deliveryPrice}
                    setDeliveryPrice={this.props.setDeliveryPrice}
                    isCartUpdated={this.props.isCartUpdated}
                    setIsCartUpdated={this.props.setIsCartUpdated}
                  />
                </li>

                <li>
                  <AddBusiness ref_from="header" config={this.props.config} />
                </li>
                <li>
                  <LangType config={this.props.config} />
                </li>
                <li>
                  {this.state.id > 0 ? (
                    <a href="/profile" className="add-business" alt="">
                      {this.state.name}
                    </a>
                  ) : (
                    <SignIn config={this.props.config} />
                  )}
                </li>
              </ul>
            </div>
          </Container>
        </Navbar>
      </Router>
    );
  }
}

export default Header;
