import React from "react";
import { Container, Col, Button } from "react-bootstrap";

import background from "./../../assets/img/rest1.jpg";
import logo from "../../assets/img/rest-logo1.png";
import Skeleton from "react-loading-skeleton";

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activekey: props.activekey,
    };
  }

  componentDidUpdate() {
    console.log(this.props);
  }

  render() {
    let {
      name,
      street,
      estimatedeliveryTime,
      estimatepickupTime,
      ratings,
      reviews,
      addtofav,
      bannerUrl,
      logo_url,
    } = this.props.business;

    estimatedeliveryTime = this.props.business.estimatedelivery;
    estimatepickupTime = this.props.business.estimatepickup;

    if (estimatedeliveryTime && estimatedeliveryTime.split("").includes(":"))
      estimatedeliveryTime = estimatedeliveryTime.split(":")[1];

    if (estimatepickupTime && estimatepickupTime.split("").includes(":"))
      estimatepickupTime = estimatepickupTime.split(":")[1];

    if (street) {
      street = JSON.parse(street);
      street = street[Object.keys(street)[0]];
    }

    if (this.props.business.is_banner)
      bannerUrl = JSON.parse(this.props.business.is_banner).data.url;

    if (this.props.business.is_img)
      logo_url = JSON.parse(this.props.business.is_img).data.url;

    return (
      <section
        className="banner inner business"
        style={
          bannerUrl
            ? { backgroundImage: `url(${bannerUrl})` }
            : { backgroundImage: `url(${background})` }
        }
      >
        <Container fluid>
          <Col>
            <div className="business-container">
              <div className="details">
                {logo_url ? (
                  <img src={logo_url ? logo_url : logo} alt="Menuhuts" />
                ) : (
                  <Skeleton height={100} width={100} />
                )}
                <div className="business-descp">
                  <h2>
                    {name} ({street})
                  </h2>
                  <ul>
                    {this.props.business.is_delivery === "1" &&
                    this.props.orderType === "1" &&
                    this.props.deliveryPrice ? (
                      <li>
                        {this.props.business.currency_symbol}
                        {this.props.deliveryPrice
                          ? this.props.deliveryPrice
                          : null}{" "}
                        Delivery Fee
                      </li>
                    ) : (
                      ""
                    )}

                    {estimatedeliveryTime && estimatepickupTime ? (
                      <>
                        <li>
                          {estimatedeliveryTime} - {estimatepickupTime} Min
                        </li>
                      </>
                    ) : null}

                    {this.props.business?.ratings ? (
                      <li>
                        {ratings}({reviews}+ reviews)
                      </li>
                    ) : (
                      <Skeleton height={25} width={100} />
                    )}
                  </ul>
                </div>
              </div>
              <div className="activity">
                <Button onClick={this.props.onClick}>
                  <i className="fas fa-plus"></i>Add Reviews
                </Button>
                <span className="favorite" onClick={this.props.addToFav}>
                  <i
                    className={
                      "fas fa-heart " +
                      (this.props.business.addtofav ? "fillRed" : "")
                    }
                  ></i>
                </span>
              </div>
            </div>
          </Col>
        </Container>
      </section>
    );
  }
}

export default Banner;
