import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PayPalButtons } from "@paypal/react-paypal-js";

function Paypal({ canSubmit, btnOpacity, setIsCartUpdated, price }) {
  const history = useHistory();

  return (
    <div>
      <div disabled={!canSubmit} style={{ opacity: btnOpacity }}>
        <PayPalButtons
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: price.toString(),
                  },
                },
              ],
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then((details) => {
              const name = details.payer.name.given_name;
              sessionStorage.setItem("cart", JSON.stringify([]));
              sessionStorage.setItem("cartBusiness", JSON.stringify({}));
              sessionStorage.setItem("cartTotal", 0);
              sessionStorage.removeItem("totalCost");
              sessionStorage.removeItem("selectedChoices");
              setIsCartUpdated(true);
              history.push("/confirmation");
              alert(`Transaction completed by ${name}`);
            });
          }}
          onError={(e) => console.log(e)}
          style={{ layout: "horizontal" }}
        />
      </div>
    </div>
  );
}

export default Paypal;
