import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Form, InputGroup, Button, Row, Col, } from "react-bootstrap";


// img
import logo1 from '../../assets/img/rest-logo1.png';
import logo2 from '../../assets/img/rest-logo2.png';
import logo3 from '../../assets/img/rest-logo3.png';

import Skeleton from 'react-loading-skeleton';

//custom alert

import CustomAlert from '../alert/CustomAlert' 

import OrderDetails from '../modal/OrderDetails'


function OrderHistory(props) {
    const [language, setLang] = useState(null);
    const [orderhistory, setOrderHistory] = useState([]);
    const [foundOrder, setFoundOrder] = useState([]);
    const [loding, setLoding] = useState(true);

    useEffect(() => {

        setTimeout(function(){
            let config_data = props.config.config;
            setLang(config_data.LANGUAGE[config_data.LANG_ID]);
            getOrderHistory();
        },2000)
   
   }, [props.config.config,language]);

     // the value of the search field 
     const [search, setSearch] = useState('');

     //alert start
    const [alert_coustom, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }

    //alert end 

    async function getOrderHistory(){

        let userId = sessionStorage.getItem('userId') ;
        
            let res = await fetch(props.config.config.api.url + 'api/order/order', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    f: 'fetchorderbyuser',
                    langId : sessionStorage.getItem('langId'),
                    userId: userId,
                    bId: (props.config.config.APP_TYPE) ? 0 : props.config.config.BUSINESS_ID
                })
            });
            let result = await res.json();
            
            if (result) {
                setLoding(false);
                setOrderHistory(result);
                setFoundOrder(result);  
            } 
       }

       const filter = (e) => {
        const keyword = e;
    
        if (keyword !== '') {
          const results = orderhistory.filter((bus) => {
            return bus.bname.toLowerCase().startsWith(keyword.toLowerCase());
            // Use the toLowerCase() method to make it case-insensitive
          });
          setFoundOrder(results);
        } else {
            setFoundOrder(orderhistory);
          // If the text field is empty, show all users
        }
    
        setSearch(keyword);
      };


    return (
        <>
            <h1 className="container-heading"><span>Order</span> history</h1>
            <Form>
                <Form.Group>
                    <Row>
                        <Col md={12} sm={12}>
                            <InputGroup className="grey">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><i className="fas fa-search"></i></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control id="" placeholder="Search" onChange={(e)=> { setSearch(e.target.value); filter(e.target.value)}} />
                            </InputGroup>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>

            <CustomAlert
                    header={language != null ? language.ORDER_HISTORY : ''}
                    btnText={language != null ? language.ACCEPT : ''}
                    text={alert_coustom.text}
                    type={alert_coustom.type}
                    show={alert_coustom.show}
                    onClosePress={onCloseAlert}
                    pressCloseOnOutsideClick={true}
                    alertStyles={{}}
                    headerStyles={{}}
                    textStyles={{}}
                    buttonStyles={{}}
             />  

                { loding === false && foundOrder.length > 0 ?

                <Business OrderHistory={foundOrder} language={language} config={props.config.config} getOrderHistory={getOrderHistory}/>   


                : loding === true ?
                <div className="business-item-cont">
                        {[...Array(3)].map((item, i) => (
                        
                            <div key={i} className="business-item">
                            
                                <Skeleton className="business-logo"  />
                                <div className="business-description">
                                    <div className="busn-details">
                                        <h3><Skeleton width={300} height={25} /></h3>
                                        <ul>
                                            <li className="address"><Skeleton width={300} height={25} /></li>
                                        </ul>
                                    </div>

                                </div>
                            </div> 
                        ))}       
                </div>   

                : loding === false && foundOrder.length == 0 ? 
                    <div className="business-item-cont">
                            
                        <div className="business-item">
                            
                            <div className="business-description">
                                <div className="busn-details">
                                    <h3>{language != null ? language.NO_ORDER : ""}</h3>
                                    
                                </div>

                            </div>
                        </div> 
                                    
                    </div>   
                : ""
                }  

            { /*<div className="business-item-cont">
                <div className="business-item">
                    <img src={logo1} className="business-logo" alt="Menuhuts" />
                    <div className="business-description">
                        <div className="busn-details">
                            <h3>Jack in the Box (400 Geary St)</h3>
                            <ul className="other">
                                <li className="ordered">2 items for $31.98</li>
                                <li className="delivery-status">21-01-2020  11:00</li>
                                <li className="receipt"><Link to={``}>View receipt</Link></li>
                            </ul>
                        </div>

                        <div className="busn-right">
                            <span className="order-status">Pending</span>
                            <Button>Reorder</Button>
                        </div>
                    </div>
                </div>

                <div className="business-item">
                    <img src={logo3} className="business-logo" alt="Menuhuts" />
                    <div className="business-description">
                        <div className="busn-details">
                            <h3>Jack in the Box (400 Geary St)</h3>
                            <ul className="other">
                                <li className="ordered">2 items for $31.98</li>
                                <li className="delivery-status">21-01-2020  11:00</li>
                                <li className="receipt"><Link to={``}>View receipt</Link></li>
                            </ul>
                        </div>

                        <div className="busn-right">
                            <span className="order-status">Pending</span>
                            <Button>Reorder</Button>
                        </div>
                    </div>
                </div>

            </div> */}
        </>
    );
}

const Business = React.memo(({ OrderHistory, language, config, getOrderHistory}) => {

    return (
 
             <div className="business-item-cont">
       
            {OrderHistory.map((order) => {
               // const { id, name, mobile, address, ratings, is_img, is_banner, open, slug } = bus;
                let bImage;
                if (order.is_img.is_img === 1) {
                    bImage = order.is_img.data.secure_url;
                } else {
                    bImage = logo1;
                }

               
                return (
                    
                        <div key={order.id} className="business-item">
                        <img src={bImage} className="business-logo" alt="Menuhuts" />
                        <div className="business-description">
                            <div className="busn-details">
                                <h3>{order.bname}</h3>
                                <ul className="other">
                                    <li className="ordered">{order.currency_symbol}{order.total}</li>
                                    <li className="delivery-status">{order.date}</li>
                                    <li className="receipt"><OrderDetails getOrderHistory_r={getOrderHistory} config={config} language={language} order={order}></OrderDetails></li>
                                </ul>
                            </div>

                            <div className="busn-right">
                               
                                {
                                    order.status == '0' ? 
                                    <span className="order-status pending-color" >&nbsp;{order.statustext}</span>
                                    : order.status == '6' || order.status == '7' || order.status == '8' ?
                                   <span className="order-status cencel-color" ><i className=" fa fa-times" aria-hidden="true"></i> &nbsp;{order.statustext}</span>

                                   : order.status == '1' || order.status == '2' || order.status == '3' || order.status == '4' || order.status == '5' ? 
                                   <span className="order-status success-color" ><i className="fa fa-check" aria-hidden="true"></i> &nbsp;{order.statustext}</span>

                                   : ""
                                }

                                {
                                    order.review_status === true ? 
                                    <Button>{language != null ? language.REVIEW : ""}</Button>
                                    : ""
                                }

                                {
                                    order.is_reorder == 1 ? 
                                    <Button className="re-order-color">{language != null ? language.RE_ORDER : ""}</Button>
                                    : ""
                                }
                                
                            </div>
                        </div>
                        </div>
                    
                );
            })}

          </div>  
       
    )

})

export default OrderHistory;