import { extendObservable } from 'mobx';

class UserStore {
    constructor() {
        extendObservable(this, {
            isLoggedIn: false,
            name: '',
            last_name: '',
            userid: ''
        })
    }
}

export default new UserStore();