import React, { useState, useEffect } from "react";
import { Form, InputGroup, Button, Row, Col } from "react-bootstrap";
import { deviceDetect } from "react-device-detect";

function Booking({ business, config, user }) {
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [number, setNumber] = useState(null);
  const [people, setPeople] = useState("1");
  const [message, setMessage] = useState(null);
  const deviceObj = deviceDetect();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (date && time && name && email && number && people) {
      if (message === null) {
        setMessage("");
      }
      //fetch api call to config.api.url + "/api/place/place"

      try {
        let x = await fetch(`${config.api.url}api/place/place`, {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            f: "placebooking",
            langId: 1,
            userId: user.id,
            bid: business.id,
            data: JSON.stringify({
              bookingDateString: date,
              bookingTime: time,
              userid: user.id,
              name: name,
              email: email,
              cel: number,
              person: people,
              notes: message,
            }),
            sourcetype: "web",
            sourcetypename: JSON.stringify(deviceObj),
          }),
        });
        let r = await x.json();
        if (r && r.placebooking) alert("Booking successful!!");
        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <div className="other-container business-booking">
        <Row>
          <Col>
            <Form>
              <h5 className="sub-heading">Booking details</h5>
              <Form.Group>
                <Row>
                  <Col md={6} sm={12}>
                    <InputGroup className="grey">
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <i className="fas fa-calendar-day"></i>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="date"
                        placeholder="Date"
                        onChange={(e) => {
                          let x = e.target.value;
                          if (x) {
                            let d = x.split("-");
                            [d[0], d[2]] = [d[2], d[0]];
                            d = d.join("-");
                            setDate(d);
                          }
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={6} sm={12}>
                    <InputGroup className="grey">
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <i className="fas fa-clock"></i>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="time"
                        placeholder="Time"
                        onChange={(e) => setTime(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Form.Group>
              <h5 className="sub-heading differ-part">User details</h5>
              <Form.Group>
                <Row>
                  <Col md={6} sm={12}>
                    <InputGroup className="grey">
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <i className="fas fa-user"></i>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={6} sm={12}>
                    <InputGroup className="grey">
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <i className="fas fa-envelope"></i>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col md={6} sm={12}>
                    <InputGroup className="grey">
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <i className="fas fa-mobile"></i>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="number"
                        placeholder="Mobile"
                        onChange={(e) => setNumber(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={6} sm={12}>
                    <InputGroup className="grey">
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <i className="fas fa-users"></i>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        as="select"
                        value={people}
                        placeholder="People"
                        onChange={(e) => setPeople(e.target.value)}
                      >
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                        <option value="4">Four</option>
                        <option value="5">Five</option>
                        <option value="6">Six</option>
                        <option value="7">Seven</option>
                        <option value="8">Eight</option>
                        <option value="9">Nine</option>
                        <option value="10">Ten</option>
                      </Form.Control>
                    </InputGroup>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row>
                  <Col md={12} sm={12}>
                    <InputGroup className="grey textarea">
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <i className="fas fa-comment"></i>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        as="textarea"
                        placeholder="Message"
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                >
                  Book a table
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Booking;
