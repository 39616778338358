import React, { useState, useEffect } from 'react';
import {  useHistory } from "react-router-dom";
import { Modal, Form, Button, } from 'react-bootstrap';
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";

//custom alert

import CustomAlert from '../alert/CustomAlert'


function RecoveryPassword(props) {

    const history = useHistory();

    const [language, setLang] = useState(null);

    const [show, setShow] = useState(true);

    //alert start

    const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }

    //alert end 

    const recoveryPasswordClose = () => setShow(false);
    const recoveryPasswordShow = () => setShow(true);

    const [open_err, setOpenErr] = useState(false);
    const [err_msg, setErrMsg] = useState("");

    const [new_password, setNewPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");
    const [user_id, setUserId] = useState("");

    const [new_passType, setNewPassType] = useState('password');
    const [confirm_passType, setConfirmPassType] = useState('password');

    useEffect(() => {

        setTimeout(function(){
            let config_data = props.config;
            setLang(config_data.LANGUAGE[config_data.LANG_ID]);
           
        },2000)
       
    }, [props.config,language]);


    function passHidden(type) {
        if(type === "new"){
            setNewPassType('password');
        }else if('confirm'){
            setConfirmPassType('password');
        }
        
    }

    function passVisible(type) {
        if(type === "new"){
            setNewPassType('text');
        }else if('confirm'){
            setConfirmPassType('text');
        }
       
    }

    /*function signInShow_props() {
        setShow(false);
        props.signInShow();
    }*/

    const handleToClose = (event, reason) => {
        if ("clickaway" === reason) return;
        setOpenErr(false);
      };

   /* function signInClose_props() {
        props.signInClose();
    }*/

    useEffect ( () => {

        (async () => {
            let res = await fetch(props.api_url + 'api/fetchusrbymd5', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.id,
                })
            });
            let result = await res.json();
            if (result.status) {

                setUserId(result.id);
                                 
              } else {

                const all_error_msg = [];
                
                all_error_msg.push(result.message);

                onShowAlert('error',all_error_msg);

                setTimeout(
                    function() {
                        onCloseAlert();  
                    }
                    ,
                    4000
                );
                setTimeout(
                    function() {
                        setShow(false);
                    }
                   ,
                    5000
                );
              }
          })();
       
    },[props.api_url,props.id]);

    const getFormData = async () =>{

        let flg = true;
        const all_error_msg = [];
        
        if (new_password.trim() === '') {
            
            all_error_msg.push(language.ENTER_NEW_PASSWORD);

            if(flg === true){
                flg = false;
            }
          }
          else if (new_password.length < 6) {
            
            all_error_msg.push(language.ENTER_YOUR_PASSWORD_LENGTH);

            if(flg === true){
                flg = false;
            }
          }
          if (confirm_password.trim() === '') {
            
            all_error_msg.push(language.ENTER_CONFIRM_PASSWORD);

            if(flg === true){
                flg = false;
            }
          }else if (confirm_password !== new_password) {
            
            all_error_msg.push(language.CONFIRM_PASSWORD_NOT_MATCHED_WITH_NEW);

            if(flg === true){
                flg = false;
            }
          }

          if(flg === true){
       
                 
                    try {
                        let res = await fetch(props.api_url + 'api/updatepass', {
                            method: 'post',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                pwd: new_password,
                                id : user_id,
                            })
                        });
                        let result = await res.json();
                        if (result.status) {
                        
                            setOpenErr(true);
                            setErrMsg("Password Successfully Changed");
                        
                            setNewPassword("");
                            setConfirmPassword("");            

                            setTimeout(
                                function() {
                                    setShow(false);
                                    history.push("/");
                                }
                                ,
                                5000
                            );
                            setTimeout(
                                function() {
                                    onCloseAlert();   
                                }
                                ,
                                4000
                            );
                            
                        } else {
                          
                            all_error_msg.push(language.SOMETHING_IS_WRONG);
                            onShowAlert('error',all_error_msg); 
                        }
                    }
                    catch (e) {
                    console.log(e);
                    }
          }else{
            onShowAlert('error',all_error_msg); 
          }    
    }

    
    return (
        <>
            
            <Modal show={show} onHide={recoveryPasswordClose}>
                <Modal.Header closeButton>
                    <h1 className="modal-title"><span>{language != null ? language.RECOVERY : ''}</span> {language != null ? language.PASSWORD : ''}</h1></Modal.Header>
                <Modal.Body>
                <CustomAlert
                    header={language != null ? language.RECOVERY : ''}
                    btnText={language != null ? language.ACCEPT : ''}
                    text={alert.text}
                    type={alert.type}
                    show={alert.show}
                    onClosePress={onCloseAlert}
                    pressCloseOnOutsideClick={true}
                    alertStyles={{}}
                    headerStyles={{}}
                    textStyles={{}}
                    buttonStyles={{}}
                    />    
                <Snackbar
                        anchorOrigin={{
                        horizontal: "center",
                        vertical: "top",
                        }}
                        open={open_err}
                        autoHideDuration={5000}
                        message={err_msg}
                        onClose={handleToClose}
                        action={
                        <React.Fragment>
                            <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleToClose}
                            >
                            <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                        }
                    />
                    <Form>
                        
                        <Form.Group>
                                { /*<Form.Label>{language != null ? language.NEW_PASSWORD : ''}</Form.Label>*/ }
                                <Form.Control type={new_passType} className="box" placeholder={language != null ? language.NEW_PASSWORD : ''} onChange={ (e)=>setNewPassword(e.target.value)} />
                                    {
                                        new_passType === 'password'? 
                                        <i className="far fa-eye-slash show-password"  onClick={() => passVisible('new')}></i>
                                        : 
                                        <i className="far fa-eye show-password" onClick={() => passHidden('new')}></i>
                                    }
                                
                                
                        </Form.Group>

                        <Form.Group>
                           { /* <Form.Label>{language != null ? language.CONFIRM_PASSWORD : ''}</Form.Label>*/ }
                            <Form.Control type={confirm_passType} className="box" placeholder={language != null ? language.CONFIRM_PASSWORD : ''} onChange={(e)=>setConfirmPassword(e.target.value)} />
                                {
                                    confirm_passType === 'password'? 
                                    <i className="far fa-eye-slash show-password"  onClick={() => passVisible('confirm')}></i>
                                    : 
                                    <i className="far fa-eye show-password" onClick={() => passHidden('confirm')}></i>
                                }
                            
                            
                        </Form.Group>

                        <Form.Group>
                            <Button variant="secondary" type="button" className="box" onClick={getFormData}>{language != null ? language.SUBMIT : ''}</Button>
                        </Form.Group>

                        
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default RecoveryPassword;