import React from "react";
import { Form, InputGroup, Button, Row, Col } from "react-bootstrap";

const generateElement = (key, value) => {
  return (
    <li key={key}>
      <span>{key}</span>
      <ul>
        <li>
          {value.opens.hour}:{value.opens.minute} - {value.closes.hour}:
          {value.closes.minute}
        </li>
        <li>
          {value.opens2.hour}:{value.opens2.minute} - {value.closes2.hour}:
          {value.closes2.minute}
        </li>
      </ul>
    </li>
  );
};

function generateData(data) {
  if (data !== undefined) {
    const newData = Object.keys(data.sdays).reduce((result, currentKey) => {
      let day;
      // eslint-disable-next-line
      switch (parseInt(currentKey)) {
        case 1:
          day = "Monday";
          break;
        case 2:
          day = "Tuesday";
          break;
        case 3:
          day = "Wednesday";
          break;
        case 4:
          day = "Thursday";
          break;
        case 5:
          day = "Friday";
          break;
        case 6:
          day = "Saturday";
          break;
        case 7:
          day = "Sunday";
          break;
      }
      const elementToPush = generateElement(day, data.sdays[currentKey]);
      result.push(elementToPush);
      return result;
    }, []);
    return newData;
  }
}

class BusinessInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      msg: "",
      buttonDisabled: false,
    };
  }
  resetForm() {
    this.setState({
      name: "",
      email: "",
      msg: "",
      buttonDisabled: false,
    });
  }

  setInputValue(property, val) {
    console.log(property, val);
    this.setState({
      [property]: val,
    });
  }
  async doContact() {
    if (!this.state.name) {
      alert("Please Enter your name");
      return;
    }
    if (!this.state.email) {
      alert("Please Enter your email");
      return;
    }
    if (!this.state.msg) {
      alert("Please Enter your message");
      return;
    }
    this.setState({
      buttonDisabled: true,
    });
    try {
      let res = await fetch(this.props.config.api.url + "api/contact", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: this.state.name,
          email: this.state.email,
          msg: this.state.msg,
          business: this.props.business?.id,
        }),
      });
      let result = await res.json();
      if (result && result.success === true) {
        alert(result.msg);
      } else {
        alert(result.msg);
      }
      this.resetForm();
    } catch (e) {
      console.log(e);
      this.setState({
        buttonDisabled: false,
      });
    }
  }

  render() {
    let { street } = this.props.business;
    if (street) {
      street = JSON.parse(street);
      street = street[Object.keys(street)[0]];
    }

    return (
      <>
        <div className="other-container business-info">
          <Row>
            <Col md={6} sm={12}>
              <h5 className="sub-heading">
                <i className="fas fa-map-marker-alt"></i>
                {street}
              </h5>
              <hr />
              <h5 className="sub-heading">
                <i className="fas fa-clock"></i>Opening hours
              </h5>
              <ul className="hours">
                {generateData(this.props.business?.schedule)}
              </ul>
            </Col>
            <Col md={6} sm={12}>
              <h5 className="sub-heading">Contact with business</h5>
              <Form>
                <Form.Group>
                  <Row>
                    <Col md={6} sm={12}>
                      <InputGroup className="grey">
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            <i className="fas fa-user"></i>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          placeholder="Name"
                          value={this.state.name}
                          onChange={(e) =>
                            this.setInputValue("name", e.target.value)
                          }
                        />
                      </InputGroup>
                    </Col>
                    <Col md={6} sm={12}>
                      <InputGroup className="grey">
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            <i className="fas fa-envelope"></i>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          placeholder="Email"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setInputValue("email", e.target.value)
                          }
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col md={12} sm={12}>
                      <InputGroup className="grey textarea">
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            <i className="fas fa-comment"></i>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          as="textarea"
                          placeholder="Message"
                          value={this.state.msg}
                          onChange={(e) =>
                            this.setInputValue("msg", e.target.value)
                          }
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Button
                    variant="primary"
                    disabled={this.state.buttonDisabled}
                    onClick={() => this.doContact()}
                  >
                    Submit
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default BusinessInfo;
