import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

function Confirmation() {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      history.push("/");
    }, 3000);
  }, []);
  return (
    <div className="confirmation-main-container">
      <i className="fal fa-box-check" style={{ color: "#eb640a" }}></i> Order
      confirmed!
      <div>Redirecting to main page...</div>
    </div>
  );
}

export default Confirmation;
