import { useState, useEffect, useCallback } from 'react';

export const useCuisinesFetch = (url) => {
    const [cuisines, setCuisines] = useState([]);
  
    const getCuisines = useCallback(async () => {
      const response = await fetch(url);
      const cuisines = await response.json();
      if (cuisines.success) {
        let data = cuisines.data;
        setCuisines(data);
      }      
    }, [url]);
  
    useEffect(() => {
      getCuisines();
    }, [url, getCuisines]);
    return { cuisines };
};

export const useCountriesFetch = (url) => {
  const [countries, setCountries] = useState([]);

  const getCountries = useCallback(async () => {
    const response = await fetch(url);
    const country = await response.json();
    if (country.success) {
      let data = country.data;
      setCountries(data);
    }      
  }, [url]);

  useEffect(() => {
    getCountries();
  }, [url, getCountries]);
  return { countries };
};

export const IPAddressFetch = (url) => {
  const [ip, setIP] = useState([]);

  const getIPAddress = useCallback(async () => {    
    const response = await fetch(url);
    const ips = await response.json();
    const responseIP = await fetch('https://www.iplocate.io/api/lookup/' + ips.ip);
    const ipsAddress = await responseIP.json();
    setIP(ipsAddress);
  }, [url]);

  useEffect(() => {
    getIPAddress();
  }, [url, getIPAddress]);
  return { ip };
};

export const useBusinessTypeFetch = (url) => {
  const [btype, setBtype] = useState([]);

  const getBtype = useCallback(async () => {
    const response = await fetch(url);
    const btype = await response.json();
    let data = btype.data;
    setBtype(data);
  }, [url]);

  useEffect(() => {
    getBtype();
  }, [url, getBtype]);
  return { btype };
};

export const SiteImagesFetch = (url) => {
  const [siteImages, setSiteImage] = useState([]);

  const getSiteImages = useCallback(async () => {
    const response = await fetch(url);
    const siteImg = await response.json();
    let data = siteImg.data;
    setSiteImage(data);
  }, [url]);

  useEffect(() => {
    getSiteImages();
  }, [url, getSiteImages]);
  return { siteImages };
};