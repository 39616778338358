import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';


// img
import logo1 from '../../assets/img/rest-logo1.png';


function BookingDetails(props) {

    const [show, setShow] = useState(false);

    const BookingDetailClose = () => setShow(false);
    const BookingDetailShow = () => setShow(true);

    const [language, setLang] = useState(null);
    const [loding, setLoding] = useState(true);
    const [bookingDetails, setBookingDetails] = useState(props.booking);
    

    useEffect(() => {

        setTimeout(function(){
            let config_data = props.config;
            setLang(config_data.LANGUAGE[config_data.LANG_ID]);
            setBookingDetails(props.booking);
            
        },2000)
   
   }, [props.config,language,props.booking]);

    return (
        <>

            <Link to={`#`} onClick={BookingDetailShow}>View Details</Link>

            <Modal show={show} onHide={BookingDetailClose}>
                <Modal.Header closeButton>
                    <h1 className="modal-title"><span>Booking</span> Details</h1>
                </Modal.Header>
                <Modal.Body>

                    <div className="new-pop">
                        <h1>{language != null ? language.BOOKING_ID : ''} # {bookingDetails.id} <small
                            className="order_status">({bookingDetails.statustext})</small></h1>
                        
                        <div className="booking-bdy">
                            <div className="row ">
                            <div className="col-md-6">
                                <div className="mdl-dtls bookingdiv">
                                <h4>{language != null ? language.BUSINESS_DETAILS : ''}</h4>
                                <p>
                                    <span>
                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                    </span> {bookingDetails.bname}
                                </p>
                                <p>
                                    <span>
                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                    </span> {bookingDetails.baddress}
                                </p>
                                <p>
                                    <span>
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                    </span> {bookingDetails.bmobile}
                                </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mdl-dtls bookingdiv">
                                <h4>{language != null ? language.USER_DETAILS : ''}</h4>
                                <p>
                                    <span>
                                    <i className="fa fa-user" aria-hidden="true"></i>
                                    </span> {bookingDetails.name}
                                </p>
                                <p>
                                    <span>
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                    </span> {bookingDetails.cel}
                                </p>
                                <p>
                                    <span>
                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                    </span> {bookingDetails.email}
                                </p>
                                <p>
                                    <span>
                                    <i className="fa fa-clock-o" aria-hidden="true"></i>
                                    </span> {bookingDetails.bookingdate} - {bookingDetails.bookingtime}
                                </p>
                                <p>
                                    <span>
                                    <i className="fa fa-users" aria-hidden="true"></i>
                                    </span> {bookingDetails.person}
                                </p>
                                </div>
                            </div>
                            </div>
                            {
                                bookingDetails.notes != '' || bookingDetails.statuscomment != '' ? 
                                <div className="row" >
                                    {
                                        bookingDetails.notes != '' ? 
                                        <div className="col-md-6" >
                                            <p>
                                            <span>
                                                <i className="fa fa-volume-down" aria-hidden="true"></i>
                                            </span> {bookingDetails.notes}
                                            </p>
                                        </div>
                                        : ""
                                    }
                                    {
                                        bookingDetails.statuscomment != '' ? 
                                        <div className="col-md-6" >
                                            <p>
                                            <span>
                                                <i className="fa fa-volume-down" aria-hidden="true"></i>
                                            </span> {bookingDetails.statuscomment}
                                            </p>
                                        </div>
                                        : ""
                                    }
                                    
                                    
                                </div>
                                : ""
                            }
                            

                        </div>
                    </div>

                    
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default BookingDetails;