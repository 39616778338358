import React from "react";
import { Row, Col, Modal, Button, Carousel } from "react-bootstrap";

class Photos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photo: [],
      show: false,
      activeIndex: 0,
    };
    this.setShow = this.setShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }
  setShow(x) {
    this.setState({
      show: x,
    });
  }
  handleClose() {
    this.setShow(false);
  }
  handleShow() {
    this.setShow(true);
  }
  handleSelect(selectedIndex) {
    this.setState({
      activeIndex: selectedIndex,
    });
  }
  componentDidMount() {
    if (this.props?.business?.id > 0) {
      this.getPhotoBusiness(this.props?.business?.id);
    }
  }
  getPhotoBusiness = async (id) => {
    const response = await fetch(this.props.config.api.url + "api/photo?" + id);
    const photo = await response.json();
    let data = photo.data;
    this.setState({ photo: data });
  };
  render() {
    return (
      <>
        <div className="other-container business-photos">
          <Row>
            {this.state.photo?.length > 0 ? (
              <>
                {this.state.photo?.map((val, index) => {
                  return (
                    <Col lg={2} md={3} sm={4} xs={6} key={index}>
                      <img
                        src={val}
                        className="gallery-img"
                        alt="Menuhuts"
                        onClick={() => {
                          this.setState({
                            activeIndex: index,
                          });
                          this.handleShow();
                        }}
                      />
                    </Col>
                  );
                })}
              </>
            ) : (
              <>
                <span>No photos to display</span>
              </>
            )}
          </Row>
        </div>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Body>
            <Carousel
              activeIndex={this.state.activeIndex}
              onSelect={this.handleSelect}
            >
              {this.state.photo?.map((val, index) => {
                return (
                  <Carousel.Item key={index}>
                    <img src={val} className="gallery-img" alt="Menuhuts" />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default Photos;
