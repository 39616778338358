import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import Booking from "./Tab/Booking";
import BusinessInfo from "./Tab/BusinessInfo";
import Offers from "./Tab/Offers";

//Pages
import OnlineOrder from "./Tab/OnlineOrder";
import Photos from "./Tab/Photos";
import Review from "./Tab/Review";

class BusinessTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: "onlineOrder",
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ activeKey: props.activekey });
  }
  handleSelect(activeKey) {
    this.setState({ activeKey });
  }
  render() {
    return (
      <Tabs
        className="business-tab"
        activeKey={this.state.activeKey}
        id=""
        onSelect={(e) => this.handleSelect(e)}
      >
        <Tab eventKey="onlineOrder" title="Online Order">
          <OnlineOrder {...this.props} />
        </Tab>
        <Tab eventKey="businessInfo" title="Business Info">
          <BusinessInfo {...this.props} />
        </Tab>
        <Tab eventKey="photos" title="Photos">
          <Photos {...this.props} />
        </Tab>
        <Tab eventKey="review" title="Review">
          <Review {...this.props} />
        </Tab>
        <Tab eventKey="offers" title="Offers">
          <Offers {...this.props} />
        </Tab>
        <Tab eventKey="booking" title="Booking">
          <Booking {...this.props} />
        </Tab>
      </Tabs>
    );
  }
}

export default BusinessTab;
