import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { BrowserRouter as Router } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import SelectAreaModal from "../selectAreaModal";

class ProductListing extends React.Component {
  constructor(props) {
    super(props);

    let area = props.business.arealist
      .map((area) => area.name)
      .filter((item, index) => index > 0);

    this.state = {
      showModal: false,
      availableAreas: [...area],
      currentBusiness: {},
    };
  }

  setIsOptionsModalOpen = (x) => {
    this.setState({
      isOptionsModalOpen: x,
    });
  };
  handleShow = () => {
    this.setState({
      showModal: true,
    });
  };
  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };

  render() {
    return (
      <Router>
        <SelectAreaModal
          showModal={this.state.showModal}
          availableAreas={this.state.availableAreas}
          setIsSelectedAreaModified={this.props.setIsSelectedAreaModified}
          handleClose={this.handleClose}
          currentBusiness={this.props.business}
          setIsCartUpdated={this.props.setIsCartUpdated}
        />
        {this.props?.category?.map((cat) => (
          <CategoryListComponent
            key={`category-${cat?.id.toString()}`}
            cat={cat}
            symbol={this.props?.business?.currency_symbol}
            business={this.props.business}
            orderType={this.props.orderType}
            handleShow={this.handleShow}
            {...this.props}
          />
        ))}
      </Router>
    );
  }
}

const ImageSlider = ({
  imageUrl1,
  imageUrl2,
  imageUrl3,
  altText,
  styleClassName,
}) => {
  return (
    <Carousel>
      {imageUrl1 ? (
        <Carousel.Item>
          <img className={styleClassName} src={imageUrl1} alt={altText} />
        </Carousel.Item>
      ) : null}{" "}
      {imageUrl2 ? (
        <Carousel.Item>
          <img className={styleClassName} src={imageUrl2} alt={altText} />
        </Carousel.Item>
      ) : null}{" "}
      {imageUrl3 ? (
        <Carousel.Item>
          <img className={styleClassName} src={imageUrl3} alt={altText} />
        </Carousel.Item>
      ) : null}
    </Carousel>
  );
};

const CategoryListComponent = React.memo(
  ({
    modifiedProduct,
    isProductModified,
    setIsProductModified,
    setIsOptionsModalOpen,
    searchText,
    cat,
    activeKey,
    symbol,
    productDetailsItem,
    business,
    orderType,
    isCartUpdated,
    handleShow,
    setIsCartUpdated,
  }) => {
    let cat2 = { ...cat };
    if (searchText.length > 0 && cat2.product)
      cat2.product = cat2.product.filter((prod) =>
        prod.name.includes(searchText)
      );
    if (cat2.product.length === 0) return null;
    return (
      <>
        <div className="items-sec" id={`cat-${cat2.id}`}>
          <h4 className="category">{cat2?.name}</h4>
          <p className="cate-des">{cat2?.description}</p>
          {cat2?.dishid?.length > 0 ? (
            <Row className="list-container">
              {cat2?.product?.length > 0
                ? cat2?.product?.map((prod) => (
                    <ProductItems
                      key={`category-${
                        cat2?.id
                      }-products-${prod?.id.toString()}`}
                      modifiedProduct={modifiedProduct}
                      isProductModified={isProductModified}
                      setIsProductModified={setIsProductModified}
                      setIsOptionsModalOpen={setIsOptionsModalOpen}
                      searchText={searchText}
                      product={prod}
                      symbol={symbol}
                      productDetailsItem={productDetailsItem}
                      business={business}
                      orderType={orderType}
                      isCartUpdated={isCartUpdated}
                      handleShow={handleShow}
                      setIsCartUpdated={setIsCartUpdated}
                    />
                  ))
                : ""}
            </Row>
          ) : (
            ""
          )}
          {cat2?.subcat?.length > 0 ? (
            <SubCategoryItems
              catname={cat2?.name}
              subcat={cat2?.subcat}
              symbol={symbol}
              productDetailsItem={productDetailsItem}
              business={business}
              orderType={orderType}
            />
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
);

const ProductItems = React.memo(
  ({
    modifiedProduct,
    isProductModified,
    setIsProductModified,
    setIsOptionsModalOpen,
    searchText,
    product,
    symbol,
    productDetailsItem,
    business,
    orderType,
    isCartUpdated,
    setIsCartUpdated,
    handleShow,
  }) => {
    useEffect(() => {
      setIsProductModified(false);
    }, [modifiedProduct]);

    return (
      <Col lg={4} md={6} sm={12}>
        <div className="items">
          <div className="product-description">
            <div className="top">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <h5 style={{ alignSelf: "center" }}>{product?.name}</h5>
                {product?.freeitem.length > 0 &&
                business?.buyone_getone_status ? (
                  <span className="buy-get">Buy 1 Get 1 Free</span>
                ) : (
                  ""
                )}
              </div>
              <p>{product?.description}</p>
            </div>
            <div className="btm">
              {orderType !== 2 && product.offerprice > 0 ? (
                <h5 className="price">
                  {symbol} {product.delprice}
                </h5>
              ) : orderType === 2 && product.pofferprice > 0 ? (
                <h5 className="price">
                  {symbol}
                  {product.pickprice}
                </h5>
              ) : product.price > 0 ? (
                <h5 className="price">
                  {symbol}
                  {product.price}
                </h5>
              ) : (
                ""
              )}

              {product?.sets.length > 0 ? (
                <div className="cstmz-div">
                  <span className="cstmz">Options</span>
                </div>
              ) : null}

              {product?.veg === "1" ? (
                <span className="veg"></span>
              ) : product?.nonveg === "1" ? (
                <span className="non-veg"></span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="item_img_featured">
            {product?.feature === "1" ? (
              <span className="ftrd-prdct">Featured</span>
            ) : (
              ""
            )}

            <ImageSlider
              imageUrl1={product?.is_img?.data?.secure_url}
              imageUrl2={product?.is_img1?.data?.secure_url}
              imageUrl3={product?.is_img2?.data?.secure_url}
              altText="Menuhuts"
              styleClassName="product-img"
            />
          </div>
        </div>
        <div className="items-cart-btns-container">
          <button
            onClick={() => {
              if (
                parseInt(sessionStorage.getItem("orderType")) === 0 &&
                business.is_delivery === "1"
              ) {
                handleShow();
                return;
              }
              if (product.sets.length > 0) {
                setIsOptionsModalOpen(true);
                productDetailsItem(product);
              } else {
                if (
                  JSON.parse(sessionStorage.getItem("cartBusiness")).id !==
                  business.id
                ) {
                  sessionStorage.removeItem("cartBusiness");
                  sessionStorage.removeItem("cart");
                }
                sessionStorage.setItem(
                  "cartBusiness",
                  JSON.stringify(business)
                );
                let currentCart = JSON.parse(sessionStorage.getItem("cart"));
                if (currentCart === null)
                  sessionStorage.setItem(
                    "cart",
                    JSON.stringify([{ product: { ...product, qty: 1 } }])
                  );
                else {
                  let currentQty;
                  if (
                    currentCart.filter(
                      (cartItem) => cartItem.product.id === product.id
                    ).length > 0
                  ) {
                    currentQty = currentCart.reduce((acc, curr) => {
                      if (curr.product.id === product.id)
                        return acc + curr.product.qty;
                      else return acc;
                    }, 0);
                    currentCart = currentCart.filter(
                      (cartItem) => cartItem.product.id !== product.id
                    );
                    currentCart = [
                      ...currentCart,
                      {
                        product: {
                          ...product,
                          qty: currentQty + 1,
                        },
                      },
                    ];
                    sessionStorage.setItem("cart", JSON.stringify(currentCart));
                  } else {
                    sessionStorage.removeItem("cart");
                    sessionStorage.setItem(
                      "cart",
                      JSON.stringify([
                        ...currentCart,
                        { product: { ...product, qty: 1 } },
                      ])
                    );
                  }
                }
                setIsCartUpdated(true);
              }
            }}
            className="add-to-cart-btn"
          >
            Add to cart
          </button>
        </div>
      </Col>
    );
  }
);

const SubCategoryItems = React.memo(
  ({ catname, subcat, symbol, productDetailsItem, business, orderType }) => {
    return (
      <>
        {subcat?.map((subcategory) => (
          <SubCategoryItemsComponent
            key={`subcategory-${subcategory?.id}`}
            catname={catname}
            subcategory={subcategory}
            symbol={symbol}
            productDetailsItem={productDetailsItem}
            business={business}
            orderType={orderType}
          />
        ))}
      </>
    );
  }
);

const SubCategoryItemsComponent = React.memo(
  ({
    catname,
    subcategory,
    symbol,
    productDetailsItem,
    business,
    orderType,
  }) => {
    return (
      <>
        <ul className="sub-category">
          <li>{catname}</li>
          <li>{subcategory?.name}</li>
        </ul>
        {subcategory?.dishid?.length > 0 ? (
          <Row className="list-container">
            {subcategory?.product?.length > 0
              ? subcategory?.product?.map((prod) => (
                  <ProductItems
                    key={`subcategory-${
                      subcategory?.id
                    }-products-${prod?.id.toString()}`}
                    product={prod}
                    symbol={symbol}
                    productDetailsItem={productDetailsItem}
                    business={business}
                    orderType={orderType}
                  />
                ))
              : ""}
          </Row>
        ) : (
          ""
        )}
      </>
    );
  }
);
export default ProductListing;
