import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import config from "./config.json";

import { observer } from "mobx-react";

// css
import "./App.css";

// js
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";

// Components
import UserStore from "./components/UserStores";
import auth from "./components/auth";

// Pages
import Layout from "./pages/layout";
import Home from "./pages/home";
import List from "./pages/list";
import Business from "./pages/business";
import Profile from "./pages/profile";
import SignIn from "./pages/modal/SignIn";
import Checkout from "./pages/checkout";
import Confirmation from "./pages/confirmation";

class App extends React.Component {
  constructor(props) {
    super(props);
    if (auth.isAuthenticated()) {
      let userResult = auth.authResult();
      UserStore.name = userResult.name;
      UserStore.last_name = userResult.last_name;
      UserStore.id = userResult.id;
      UserStore.isLoggedIn = true;
    }
    this.state = {
      isCartUpdated: false,
      allBusinesses: [],
      deliveryPrice: 0,
    };
    this.setIsCartUpdated = this.setIsCartUpdated.bind(this);
    this.setAllBusinesses = this.setAllBusinesses.bind(this);
    let globals = config;
    let appType = globals.APP_TYPE;
    let langFlag = false;
    let langlist = "";
    let langId = 1;
    let language = "";
  }

  setDeliveryPrice = (x) => {
    this.setState({
      deliveryPrice: x,
    });
  };

  setIsCartUpdated(x) {
    this.setState({
      isCartUpdated: x,
    });
  }

  setAllBusinesses(x) {
    this.setState({
      allBusinesses: x,
    });
  }

  async componentDidMount() {
    let x = sessionStorage.getItem("deliveryPrice");
    if (x)
      this.setState({
        deliveryPrice: x,
      });
    let globals = config;
    let appType = globals.APP_TYPE;
    let langFlag = false;
    let langlist = "";
    let langId = 1;
    let language = "";
    let res = await fetch(globals.API_ENDPOINT_LANGUAGE, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    let result = await res.json();
    if (result.data) {
      langFlag = true;
      let languageResponse = result;
      globals.LANGUAGE = JSON.parse(JSON.stringify(languageResponse.data));
      globals.LANG_ID = languageResponse.langId;
      globals.LANG_LIST = languageResponse.langlist;
      langlist = globals.LANG_LIST;
      if (
        sessionStorage.getItem("langId") === undefined ||
        sessionStorage.getItem("langId") === null
      ) {
        language = globals.LANGUAGE[globals.LANG_ID];
        langId = globals.LANG_ID;
        //sessionStorage.setItem('language',language);
        sessionStorage.setItem("langId", langId);
      } else {
        let lang;
        lang = sessionStorage.getItem("langId");
        globals.LANG_ID = lang;
        language = globals.LANGUAGE[globals.LANG_ID];
      }
    }
  }

  componentDidUpdate() {
    sessionStorage.setItem("deliveryPrice", this.state.deliveryPrice);
  }

  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route exact path="/">
              <Layout
                config={config}
                deliveryPrice={this.state.deliveryPrice}
                isCartUpdated={this.state.isCartUpdated}
                setDeliveryPrice={this.setDeliveryPrice}
                setIsCartUpdated={this.setIsCartUpdated}
              >
                <Home config={config} />
              </Layout>
            </Route>
            <Route exact path="/recovery/:Id">
              <Layout
                config={config}
                deliveryPrice={this.state.deliveryPrice}
                isCartUpdated={this.state.isCartUpdated}
                setDeliveryPrice={this.setDeliveryPrice}
                setIsCartUpdated={this.setIsCartUpdated}
              >
                <Home config={config} />
              </Layout>
            </Route>
            <Route exact path="/search/:params">
              <Layout
                exact
                config={config}
                deliveryPrice={this.state.deliveryPrice}
                setDeliveryPrice={this.setDeliveryPrice}
                isCartUpdated={this.state.isCartUpdated}
                setIsCartUpdated={this.setIsCartUpdated}
              >
                <List config={config} />
              </Layout>
            </Route>
            <Route exact path="/city/:cityId">
              <Layout
                config={config}
                deliveryPrice={this.state.deliveryPrice}
                setDeliveryPrice={this.setDeliveryPrice}
                isCartUpdated={this.state.isCartUpdated}
                setIsCartUpdated={this.setIsCartUpdated}
              >
                <List config={config} />
              </Layout>
            </Route>
            <Route exact path="/cuisine/:cuisineId">
              <Layout
                config={config}
                deliveryPrice={this.state.deliveryPrice}
                setDeliveryPrice={this.setDeliveryPrice}
                isCartUpdated={this.state.isCartUpdated}
                setIsCartUpdated={this.setIsCartUpdated}
              >
                <List config={config} />
              </Layout>
            </Route>
            <Route exact path="/businesstype/:id">
              <Layout
                config={config}
                deliveryPrice={this.state.deliveryPrice}
                setDeliveryPrice={this.setDeliveryPrice}
                isCartUpdated={this.state.isCartUpdated}
                setIsCartUpdated={this.setIsCartUpdated}
              >
                <List config={config} />
              </Layout>
            </Route>
            <Route exact path="/store/:businessSlug">
              <Layout
                config={config}
                deliveryPrice={this.state.deliveryPrice}
                setDeliveryPrice={this.setDeliveryPrice}
                isCartUpdated={this.state.isCartUpdated}
                setIsCartUpdated={this.setIsCartUpdated}
              >
                <Business
                  config={config}
                  deliveryPrice={this.state.deliveryPrice}
                  setDeliveryPrice={this.setDeliveryPrice}
                  setIsCartUpdated={this.setIsCartUpdated}
                />
              </Layout>
            </Route>
            <Route exact path="/profile">
              <Layout
                config={config}
                deliveryPrice={this.state.deliveryPrice}
                setDeliveryPrice={this.setDeliveryPrice}
                isCartUpdated={this.state.isCartUpdated}
                setIsCartUpdated={this.setIsCartUpdated}
              >
                <Profile config={config} />
              </Layout>
            </Route>
            <Route exact path="/login">
              <Layout
                config={config}
                deliveryPrice={this.state.deliveryPrice}
                setDeliveryPrice={this.setDeliveryPrice}
                isCartUpdated={this.state.isCartUpdated}
                setIsCartUpdated={this.setIsCartUpdated}
              >
                <SignIn config={config} />
              </Layout>
            </Route>
            <Route exact path="/checkout">
              <Layout
                config={config}
                deliveryPrice={this.state.deliveryPrice}
                setDeliveryPrice={this.setDeliveryPrice}
                isCartUpdated={this.state.isCartUpdated}
                setIsCartUpdated={this.setIsCartUpdated}
              >
                <Checkout
                  config={config}
                  deliveryPrice={this.state.deliveryPrice}
                  isCartUpdated={this.state.isCartUpdated}
                  setIsCartUpdated={this.setIsCartUpdated}
                  setPaypalClientId={this.setPaypalClientId}
                />
              </Layout>
            </Route>
            <Route exact path="/confirmation">
              <Layout
                config={config}
                deliveryPrice={this.state.deliveryPrice}
                setDeliveryPrice={this.setDeliveryPrice}
                isCartUpdated={this.state.isCartUpdated}
                setIsCartUpdated={this.setIsCartUpdated}
              >
                <Confirmation config={config} />
              </Layout>
            </Route>
          </Switch>
        </Router>
      </>
    );
  }
}

export default observer(App);
