import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";

// components
import ProductOptions from "./ProductOptions";
import SelectAreaModal from "../../business/selectAreaModal";

class ProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props?.show,
      product: this.props?.product,
      orderType: this.props?.orderType,
      isRequiredItemMissing: false,
      totalQty: 1,
    };
    sessionStorage.setItem("totalCost", parseFloat(this.props?.product?.price));
    sessionStorage.setItem("selectedChoices", JSON.stringify([]));
  }

  componentDidMount() {
    this.setState({
      isRequiredItemMissing: false,
    });
  }

  componentDidUpdate() {
    if (this.props.isProductModified) {
      const product = this.props.modifiedProduct;
      const initialCost = parseFloat(this.props?.product?.price);
      let otherCost = 0;
      if (typeof this.props.deliveryPrice === "number")
        otherCost += this.props.deliveryPrice;
      const selectedChoices = sessionStorage.getItem("selectedChoices");
      product.sets.forEach((set) => {
        set.options.forEach((option) => {
          option.choice.forEach((ch) => {
            const currentChoice = `${set.id}-${option.id}-${ch.id}`;
            if (selectedChoices.includes(currentChoice)) {
              otherCost += parseFloat(ch.price);
            }
          });
        });
      });
      sessionStorage.setItem("totalCost", initialCost + otherCost);
    }
  }

  handleToClose = (event, reason) => {
    if ("clickaway" === reason) return;

    this.setState({
      isRequiredItemMissing: false,
    });
  };

  checkAreRequiredItemsSelected = () => {
    const selectedChoicesArr = JSON.parse(
      sessionStorage.getItem("selectedChoices")
    );
    let x = this.props.modifiedProduct;
    if (Object.keys(x).length === 0 || !x) x = this.props.product;
    x.sets.forEach((set) => {
      set.options.forEach((op) => {
        if (op.required) {
          if (selectedChoicesArr.length === 0) {
            this.setState({
              isRequiredItemMissing: true,
            });
          }
          let arr = [];
          if (selectedChoicesArr.length > 0)
            arr = selectedChoicesArr[0].split("-");

          if (arr.length <= 1 || arr[0] != set.id || arr[1] != op.id) {
            this.setState({
              isRequiredItemMissing: true,
            });
          }
          if (this.state.isRequiredItemMissing) return;
        }
      });
    });
  };

  addItemsToCart = () => {
    this.checkAreRequiredItemsSelected();
    setTimeout(() => {
      if (this.state.isRequiredItemMissing) return;
      const selectedChoicesArr = JSON.parse(
        sessionStorage.getItem("selectedChoices")
      );
      const selectedIngredients = JSON.parse(
        sessionStorage.getItem("selectedIngredients")
      );
      let x = this.props.modifiedProduct;
      if (Object.keys(x).length === 0 || !x) x = this.props.product;
      x.sets = x.sets.map((set) => {
        let op = set.options.map((option) => {
          let ch = option.choice.map((choice) => {
            const identifierStr = `${set.id}-${option.id}-${choice.id}-${x.id}`;
            if (selectedChoicesArr.includes(identifierStr))
              return {
                ...choice,
                checked: true,
              };
            else return choice;
          });
          return {
            ...option,
            choice: ch,
          };
        });
        return {
          ...set,
          options: op,
        };
      });
      if (selectedIngredients[0].productId === x.id)
        x.ingredients = selectedIngredients[0].ingredientNames;
      let cart = JSON.parse(sessionStorage.getItem("cart"));
      const cartBusiness = JSON.parse(sessionStorage.getItem("cartBusiness"));
      if (Object.keys(cartBusiness).length === 0) {
        sessionStorage.setItem(
          "cartBusiness",
          JSON.stringify(this.props.business)
        );
      } else {
        if (cartBusiness.id !== this.props.business.id) {
          sessionStorage.setItem("cart", JSON.stringify([]));
          sessionStorage.setItem("cartBusiness", JSON.stringify({}));
          cart = [];
        }
      }
      cart.push({ product: { ...x, qty: this.state.totalQty } });
      sessionStorage.setItem("cart", JSON.stringify(cart));
      sessionStorage.setItem("selectedIngredients", JSON.stringify([]));
      this.props.setIsCartUpdated(true);
      this.productDetailsClose();
    }, 300);
  };

  productDetailsClose = () => {
    this.setState({
      show: false,
    });
    this.props?.productDetailsModal("show", false);
    this.props.setIsOptionsModalOpen(!this.props.isOptionsModalOpen);
  };

  render() {
    return (
      <>
        <Modal
          show={this.state?.show}
          onHide={this.productDetailsClose}
          className="product-details-popup"
        >
          <Modal.Header closeButton>
            <Snackbar
              anchorOrigin={{
                horizontal: "center",
                vertical: "top",
              }}
              open={this.state.isRequiredItemMissing}
              autoHideDuration={4000}
              message={`Please make required choices`}
              onClose={this.handleToClose}
              action={
                <React.Fragment>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleToClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
          </Modal.Header>
          <Modal.Body>
            {this.state?.product?.is_img?.is_img === 1 ? (
              <img
                src={this.state?.product?.is_img?.data?.secure_url}
                className="product-image"
                alt={this.state?.product?.name}
              />
            ) : this.state?.product?.is_img1?.is_img === 1 ? (
              <img
                src={this.state?.product?.is_img1?.data?.secure_url}
                className="product-image"
                alt={this.state?.product?.name}
              />
            ) : this.state?.product?.is_img2?.is_img === 1 ? (
              <img
                src={this.state?.product?.is_img2?.data?.secure_url}
                className="product-image"
                alt={this.state?.product?.name}
              />
            ) : (
              ""
            )}
            <div className="product-descriptions">
              <h2>{this.state?.product?.name}</h2>
              {this.state?.product?.description !== "" ? (
                <p>{this.state?.product?.description}</p>
              ) : (
                ""
              )}
            </div>
            <ProductOptions
              product={this.state?.product}
              currencySymbol={this.props?.business?.currency_symbol}
              isOptionsModalOpen={this.props.isOptionsModalOpen}
              changeSelectedChoiceInProductOption={
                this.props.changeSelectedChoiceInProductOption
              }
              setIsProductModified={this.props.setIsProductModified}
            />
            <div className="special-comments">
              <Form>
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    className="box"
                    id=""
                    placeholder="Special comments"
                  />
                </Form.Group>
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer className="product-footer">
            <div className="plus-minus-cont">
              <Button
                onClick={() => {
                  this.setState((prev) => {
                    if (prev.totalQty <= 1) return;
                    return {
                      totalQty: prev.totalQty - 1,
                    };
                  });
                }}
              >
                <i className="fas fa-minus"></i>
              </Button>
              <span>{this.state.totalQty}</span>
              <Button
                onClick={() => {
                  this.setState((prev) => {
                    return {
                      totalQty: prev.totalQty + 1,
                    };
                  });
                }}
              >
                <i className="fas fa-plus"></i>
              </Button>
            </div>

            <Button
              type="submit"
              variant="primary"
              onClick={this.addItemsToCart}
            >
              Add to Cart
              <span className="total-price">
                {this.props?.business?.currency_symbol}
                {this.state.totalQty * sessionStorage.getItem("totalCost")}
              </span>
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ProductDetails;
