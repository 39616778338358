import React, { useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import { Modal, Form, Button} from 'react-bootstrap';
import UserStore from '../../components/UserStores';
import auth from '../../components/auth';
import { loginsubscriber } from "../../components/loginService";

//custom alert

import CustomAlert from '../alert/CustomAlert'

function SignUp(props) {

    const [language, setLang] = useState(null);

    const [show, setShow] = useState(false);

    //alert start

    const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }

    //alert end  

    const signUpClose = () => setShow(false);
    const signUpShow = () => setShow(true);

    useEffect ( () => {
        setTimeout(function(){
            let config_data = props.config;
            setLang(config_data.LANGUAGE[config_data.LANG_ID]);
            
        },2000)
        
    },[props.config,language]);

    function signInShow_props() {
        setShow(false);
        props.signInShow();
    }

    /*function signInClose_props() {
                props.signInClose();
    }*/

    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [accepting, setAccepting] = useState(false);

      const getFormData = async () =>{

        let flg = true;
        const all_error_msg = [];

        if (first_name.trim() === '') {
            /*setOpenErr(true);
            setErrMsg("Please Enter your first name and try again");
            
            return false;*/

            all_error_msg.push(language.ENTER_FIRST_NAME);

            if(flg === true){
                flg = false;
            }

          }
          if (last_name.trim() === '') {
            all_error_msg.push(language.ENTER_YOUR_LAST_NAME);

            if(flg === true){
                flg = false;
            }
          }
          if (email.trim() === '') {
                        
            all_error_msg.push(language.ENTER_YOUR_EMAIL);

            if(flg === true){
                flg = false;
            }
          }
          let atpos;
          atpos = email.indexOf('@');
          let dotpos;
          dotpos = email.lastIndexOf('.');
          if(email.trim() !== ''){
                if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= email.length) {
                    all_error_msg.push(language.ENTER_VALID_EMAIL_ADDRESS);

                    if(flg === true){
                        flg = false;
                    }
                }
            }
          if (password.trim() === '') {
            all_error_msg.push(language.ENTER_YOUR_PASSWORD);

            if(flg === true){
                flg = false;
            }
          }
          if (password.trim() !== '') {
            if (password.length < 6) {
                all_error_msg.push(language.ENTER_YOUR_PASSWORD_LENGTH);

                if(flg === true){
                    flg = false;
                }
            }
        }
          if (accepting === false) {
            all_error_msg.push(language.CHECK_PRIVACY_TERMS);

            if(flg === true){
                flg = false;
            }
          }

          if(flg === true){

                try {
                    let res = await fetch(props.api_url + 'api/register', {
                        method: 'post',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            name : first_name,
                            last_name: last_name,
                            email: email,
                            pass: password,
                            terms: accepting,
                        })
                    });
                    let result = await res.json();
                    if (result.status) {
                        sessionStorage.setItem('user-details', result.result);
                        //sessionStorage.setItem('userId', result.result.id);

                        all_error_msg.push(language.SIGN_UP_SUCCESSFULLY);

                        onShowAlert('success',all_error_msg); 

                        setFirstName("");
                        setLastName("");
                        setEmail("");
                        setPassword("");
                        setAccepting(false);
                        

                        setTimeout(
                            function() {
                                setShow(false);
                            }
                            ,
                            5000
                        );
                        setTimeout(
                            function() {
                                onCloseAlert(); 
                            }
                            ,
                            4000
                        );

                        setTimeout(
                            function() {
                                login();
                            }
                            ,
                            2000
                        );
                       
                        
                    } else {
                        all_error_msg.push(result.message);

                        onShowAlert('error',all_error_msg); 
                    }
                }
                catch (e) {
                console.log(e);
                }
            }else{

                onShowAlert('error',all_error_msg); 
            }        

    }

    async function login(){
        try {
            let res = await fetch(props.api_url + 'api/login', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                })
            });
            let result = await res.json();
            if (result && result.success === true) {
                let results = result.data;
                UserStore.name = results.name;
                UserStore.last_name = results.last_name;
                UserStore.id = results.id;
                auth.login();
               
                sessionStorage.setItem('userId', results.id);
                sessionStorage.setItem('userName', results.name);
                sessionStorage.setItem('userLastName', results.last_name);
                loginsubscriber.next(true);
            } else {
            
               
            }
        }
        catch (e) {
            
        }
    }

    return (
        language !== "" ? 
        <>
        { 
            props.ref_from === "model" ? 
            <Link className="secondary" to={`#`} onClick={signUpShow}>{language != null ? language.CREATE_AN_ACCOUNT : ''}</Link>
            : 
            <Link className="secondary" to={`#`} onClick={signUpShow}>{language != null ? language.CREATE_ACCOUNT_TO_ORDER : ''}</Link>
            }
            <Modal show={show} onHide={signUpClose}>
                <Modal.Header closeButton>
                    <h1 className="modal-title"><span>{language != null ? language.SIGN_UP : ''}</span> {language != null ? language.NOW : ''}</h1>
                </Modal.Header>
                <Modal.Body>

                <CustomAlert
                    header={language != null ? language.SIGN_UP : ''}
                    btnText={language != null ? language.ACCEPT : ''}
                    text={alert.text}
                    type={alert.type}
                    show={alert.show}
                    onClosePress={onCloseAlert}
                    pressCloseOnOutsideClick={true}
                    alertStyles={{}}
                    headerStyles={{}}
                    textStyles={{}}
                    buttonStyles={{}}
                    />    

                
                    <Form onSubmit={getFormData}>
                        <Form.Group>
                            { /*<Form.Label>{language != null ? language.NAME : ''}</Form.Label>*/ }
                            <Form.Control type="text" className="box" placeholder={language != null ? language.NAME : ''} onChange={(e)=>setFirstName(e.target.value)} />
                        </Form.Group>

                        <Form.Group>
                            { /*<Form.Label>{language != null ? language.LAST_NAME : ''}</Form.Label>*/ }
                            <Form.Control type="text" className="box" placeholder={language != null ? language.LAST_NAME : ''} onChange={(e)=>setLastName(e.target.value)}/>
                        </Form.Group>

                        <Form.Group>
                            { /*<Form.Label>{language != null ? language.EMAIL : ''}</Form.Label>*/ }
                            <Form.Control type="text" className="box" placeholder={language != null ? language.EMAIL : ''} onChange={(e)=>setEmail(e.target.value)}/>
                        </Form.Group>

                        <Form.Group>
                            { /*<Form.Label>{language != null ? language.PASSWORD : ''}</Form.Label>*/ }
                            <Form.Control type="password" className="box" placeholder={language != null ? language.PASSWORD : ''} onChange={(e)=>setPassword(e.target.value)}/>
                            <i className="far fa-eye-slash show-password"></i>
                            {/* <i className="far fa-eye show-password"></i> */}
                        </Form.Group>

                        <Form.Group>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="check1" onChange={(e)=>setAccepting(e.target.checked)}/>
                                <label className="form-check-label" htmlFor="check1">
                                    {/*Accepting <Link className="secondary" to={`#`}>terms of service</Link> and <Link className="secondary" to={`#`}>privacy policy</Link>*/}
                                    {language != null ? language.PLEASE_CHECK_THE : ''} <a className="secondary" href={language != null ? language.TERMS_SERVICE_LINK : ''} target="_blank" rel="noreferrer">{language != null ? language.TERMS_SERVICE : ''}</a> {language != null ? language.AND : ''} <a className="secondary" href={language != null ? language.PRIVACY_POLICY_LINK : ''} target="_blank" rel="noreferrer">{language != null ? language.PRIVACY_POLICY : ''}</a>
                                </label>
                            </div>
                        </Form.Group>

                        <Form.Group>
                            <Button variant="secondary" type="button" className="box" onClick={getFormData}>{language != null ? language.SIGN_UP : ''}</Button>
                        </Form.Group>

                        <Form.Group className="d-flex justify-content-center">
                            <span>{language != null ? language.ALREADY_HAVE_ACCOUNT : ''} <Link className="secondary" to={`#`}  onClick={signInShow_props}>{language != null ? language.SIGN_IN : ''}</Link></span>
                        </Form.Group>

                        <Form.Group className="otherwise">
                            <span>Or</span>
                        </Form.Group>

                        <Form.Group>
                            <Button type="submit" className="btn-facebook"><i className="fab fa-facebook-f"></i> {language != null ? language.CONTINUE_WITH_GOOGLE : ''}</Button>
                            <Button type="submit" className="btn-google"><i className="fab fa-google"></i> {language != null ? language.CONTINUE_WITH_FACEBOOK : ''}</Button>
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
        : ''
    );
}

export default SignUp;