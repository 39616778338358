import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Paypal from "./paypal";
import StripeForm from "./stripe";

function Checkout(props) {
  const history = useHistory();
  const [paymentModes, setPaymentModes] = useState(null);
  const [isDelivery, setIsDelivery] = useState(false);
  const [isPickup, setIsPickup] = useState(false);
  const [cart, setCart] = useState(JSON.parse(sessionStorage.getItem("cart")));
  const [additionalCartContents, setAdditionalCartContents] = useState([]);
  const [billItems, setBillItems] = useState([]);
  const [cartCosts, setCartCosts] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const cartBusiness = JSON.parse(sessionStorage.getItem("cartBusiness"));

  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [driverTips, setDriverTips] = useState("");
  const [additionalTips, setAdditionalTips] = useState("");
  const [isASAP, setIsAsap] = useState(true);
  const [isPreorder, setIsPreorder] = useState(false);
  const [orderType, setOrderType] = useState("ASAP");
  const [pickupDate, setPickupDate] = useState("");
  const [pickupTime, setPickTime] = useState("");
  const [paymentMode, setPaymentMode] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false);
  const [btnOpacity, setBtnOpacity] = useState(0.4);
  const [stripePromise, setStripePromise] = useState(null);
  const [paypalInitialOptions, setPaypalInitialOptions] = useState({
    "client-id": "",
    currency: cartBusiness.currency_code,
    intent: "capture",
    components: "buttons",
  });

  const submitOrder = async (e) => {
    e.preventDefault();
    try {
      const url = `${props.config.api.url}api/place/place`;
      const userId = sessionStorage.getItem("userId");
      const userName = sessionStorage.getItem("userName");
      const userLastName = sessionStorage.getItem("userLastName");
      // check if userId !== undefined
      const res = await fetch(url, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          f: "placeorder",
          userid: userId,
          langId: props.config.LANG_ID,
          cartDish: JSON.stringify(billItems),
          cartFee: JSON.stringify(
            cartTotal + props.deliveryPrice - totalDiscount
          ),
          BuyerDetails: JSON.stringify({
            id: userId,
            name: userName,
            last_name: userLastName,
            email: email,
            cel: phone,
          }),
          deliveryDetails: JSON.stringify({
            billItems: billItems,
            driverTips: driverTips,
            additionalTips: additionalTips,
            isASAP: isASAP,
            isPreorder: isPreorder,
            pickupDate: pickupDate,
            pickupTime: pickupTime,
          }),
          Search: JSON.stringify({
            order_type: orderType,
          }),
          paymentMethod: paymentMode,
          rewardWallet: "{}",
          sourcetype: "",
          sourcetypename: "",
          discountInfo: JSON.stringify({
            discountid: couponCode,
            amount: totalDiscount,
          }),
          txnId: null,
          extraJson: JSON.stringify({}),
        }),
      });
      await res.json();
      sessionStorage.setItem("cart", JSON.stringify([]));
      sessionStorage.setItem("cartBusiness", JSON.stringify({}));
      sessionStorage.setItem("cartTotal", 0);
      sessionStorage.removeItem("totalCost");
      sessionStorage.removeItem("selectedChoices");
      props.setIsCartUpdated(true);
      history.push("/confirmation");
    } catch (err) {
      console.log(err);
    }
  };

  const checkCouponCode = async (e) => {
    e.preventDefault();
    if (couponCode.length > 0) {
      try {
        const cartTotal = sessionStorage.getItem("cartTotal");
        const business = JSON.parse(sessionStorage.getItem("cartBusiness"));
        const url = `${props.config.api.url}api/business/business`;
        const res = await fetch(url, {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            f: "checkdiscount",
            bid: business.id,
            total: cartTotal,
            code: couponCode,
          }),
        });
        const discountRes = await res.json();
        if (discountRes.status) {
          if (discountRes.price) setTotalDiscount(discountRes.price);
          else setTotalDiscount((discountRes.percent / 100) * cartTotal);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getDecimalRupees = (x) => {
    if (x) {
      x = x.toString();
      if (!x.includes(".")) return parseFloat(x);
      x = x.split(".");
      x[1] = x[1].substring(0, 2);
      return parseFloat(x.join("."));
    } else return x;
  };

  const setPaypalClientId = (id) => {
    setPaypalInitialOptions((prevState) => {
      return {
        "client-id": id,
        currency: prevState.currency,
        intent: prevState.intent,
        components: prevState.components,
      };
    });
  };

  const setStripe = async (x) => {
    try {
      let y = await loadStripe(x);
      setStripePromise(y);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setBillItems(
      cart.map((cartObj, index) => {
        if (typeof additionalCartContents[index] === typeof [])
          return [cartObj.product.name, ...additionalCartContents[index]];
      })
    );
  }, [cart, additionalCartContents]);

  useEffect(() => {
    if (props.isCartUpdated) {
      setAdditionalCartContents([]);
      setCartCosts([]);
      setCart(JSON.parse(sessionStorage.getItem("cart")));
    }
  }, [props.isCartUpdated]);

  useEffect(() => {
    if (isASAP) setOrderType("ASAP");
    else setOrderType("Preorder");
  }, [isASAP, isPreorder]);

  useEffect(() => {
    cart.forEach((cartItem) => {
      let additionalCost = 0;
      const arr = [];
      cartItem.product.ingredients.forEach((ingredient) => {
        arr.push(`Ingredient: ${ingredient}`);
      });
      cartItem.product.sets.forEach((set) => {
        set.options.forEach((op) => {
          op.choice.forEach((ch) => {
            if (ch.checked) {
              additionalCost += parseFloat(ch.price);
              arr.push(`${op.name} : ${ch.name}`);
            }
          });
        });
      });
      setAdditionalCartContents((curr) => [...curr, arr]);
      let itemCost =
        cartItem.product.qty *
        (parseFloat(cartItem.product.price) + additionalCost);
      setCartCosts((curr) => [...curr, itemCost]);
    });
  }, [cart]);

  useEffect(() => {
    setCartTotal(
      cartCosts.reduce((acc, curr) => {
        return acc + curr;
      }, 0)
    );
  }, [cartCosts]);

  useEffect(() => {
    if (canSubmit) setBtnOpacity(1);
    else setBtnOpacity(0.4);
  }, [canSubmit, props]);

  useEffect(() => {
    if (!JSON.parse(sessionStorage.getItem("user-details"))) history.push("/");
    if (Object.keys(cart).length === 0) history.push("/");
    const area = sessionStorage.getItem("selectedArea");
    if (area) {
      setIsDelivery(true);
      setIsPickup(false);
    } else {
      setIsDelivery(false);
      setIsPickup(true);
    }
  }, []);

  useEffect(() => {
    if (location && address && email && phone && paymentMode) {
      if ((isPreorder && pickupDate.length && pickupTime.length) || !isPreorder)
        setCanSubmit(true);
      else setCanSubmit(false);
    } else setCanSubmit(false);
  }, [
    location,
    address,
    email,
    phone,
    isPreorder,
    pickupDate,
    pickupTime,
    paymentMode,
  ]);

  useEffect(() => {
    if (!paymentModes) return;
    if (
      paymentModes.filter((mode) => mode.displayName === "Paypal").length > 0
    ) {
      const paypalClientId = paymentModes.filter(
        (mode) => mode.displayName === "Paypal"
      )[0].credential.sandboxcre;
      setPaypalClientId(paypalClientId);
      let paypalScript = document.createElement("script");
      paypalScript.setAttribute(
        "src",
        `https://www.paypal.com/sdk/js?client-id=${paypalClientId}`
      );
      document.head.appendChild(paypalScript);
    }

    if (
      paymentModes.filter((mode) => mode.displayName === "Stripe").length > 0
    ) {
      const stripeClientId = paymentModes.filter(
        (mode) => mode.displayName === "Stripe"
      )[0].credential.publishkey;
      setStripe(stripeClientId);
    }
  }, [paymentModes]);

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("cartBusiness")))
      setPaymentModes(
        JSON.parse(sessionStorage.getItem("cartBusiness")).payment
      );
  }, []);

  return (
    <div className="checkout-main-container">
      <div className="checkout-billing-details">
        <h5>Checkout</h5>
        <h6>Billing Details</h6>
        <div></div>
        <form>
          <div>
            <input
              type="text"
              placeholder="Location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Delivery Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="checkout-billing-details-dual-input">
            <input
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="number"
              placeholder="Phone number (without code)"
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="checkout-billing-details-dual-input">
            <input
              type="text"
              placeholder="Coupon Code"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
            />
            <button onClick={(e) => checkCouponCode(e)}>Apply</button>
          </div>
          <div className="checkout-billing-details-dual-input">
            <textarea
              placeholder="Custom tips to driver"
              value={driverTips}
              onChange={(e) => setDriverTips(e.target.value)}
            />
          </div>
        </form>
      </div>
      <div className="checkout-cart-details">
        <h5>Your Order</h5>
        <div></div>
        <div>
          {cart.map((cartObj, index) => (
            <div className="cart-checkout-product-row" key={index}>
              <div>{cartObj.product.qty}</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontWeight: 500,
                    textAlign: "center",
                    paddingTop: "15px",
                  }}
                >
                  {cartObj.product.name}
                </div>
                <ul style={{ listStyleType: "none" }}>
                  {additionalCartContents.length
                    ? additionalCartContents[index].map((content, i) => (
                        <li key={i}>{content}</li>
                      ))
                    : null}
                </ul>
              </div>
              <div>
                {cartBusiness.currency_symbol}{" "}
                {getDecimalRupees(cartCosts[index])}
              </div>
            </div>
          ))}
        </div>
        <div>
          <div className="cart-checkout-product-row">
            <div></div>
            <div
              style={{
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              Delivery Fee
            </div>
            <div>
              {cartBusiness.currency_symbol}
              {getDecimalRupees(props.deliveryPrice)}
            </div>
          </div>
        </div>
        <div>
          <div className="checkout-subtotal-div">
            <h6>Sub Total</h6>
            <h6>
              {cartBusiness.currency_symbol}{" "}
              {getDecimalRupees(cartTotal + parseFloat(props.deliveryPrice))}
            </h6>
          </div>
          <div className="checkout-subtotal-div">
            <h6>Discount</h6>
            <h6>
              {cartBusiness.currency_symbol} {getDecimalRupees(totalDiscount)}
            </h6>
          </div>
          <div className="checkout-subtotal-div">
            <h6>Total</h6>
            <h6>
              {cartBusiness.currency_symbol}{" "}
              {getDecimalRupees(
                cartTotal + parseFloat(props.deliveryPrice) - totalDiscount
              )}
            </h6>
          </div>
          <textarea
            placeholder="Enter any additional information about your order."
            value={additionalTips}
            onChange={(e) => setAdditionalTips(e.target.value)}
          />
          <div className="checkout-pickup-time-container">
            <h6>Pickup Time</h6>
            <div className="checkout-pickup-time-div">
              <div
                className="checkout-pickup-time-div-option"
                style={{
                  borderColor: isASAP ? "tomato" : "rgb(172, 172, 172)",
                }}
              >
                <label htmlFor="ASAP">ASAP</label>
                <input
                  type="radio"
                  id="ASAP"
                  name="pickup-time"
                  value="asap"
                  onClick={() => {
                    setIsPreorder(false);
                    setIsAsap(true);
                  }}
                />
              </div>
              <div
                className="checkout-pickup-time-div-option"
                style={{
                  borderColor: isPreorder ? "tomato" : "rgb(172, 172, 172)",
                }}
              >
                <label htmlFor="Preorder">Preorder</label>
                <input
                  type="radio"
                  id="Preorder"
                  name="pickup-time"
                  value="preorder"
                  onClick={() => {
                    setIsPreorder(true);
                    setIsAsap(false);
                  }}
                />
              </div>
            </div>
            {isPreorder && (
              <div
                className="checkout-pickup-time-div"
                style={{ marginTop: "20px" }}
              >
                <input
                  type="date"
                  style={{ width: "45%", height: "50px", paddingLeft: "5px" }}
                  onChange={(e) => setPickupDate(e.target.value)}
                />
                <input
                  type="time"
                  style={{ width: "45%", height: "50px", paddingLeft: "5px" }}
                  onChange={(e) => setPickTime(e.target.value)}
                />
              </div>
            )}
          </div>
          <div className="checkout-payment-method-div">
            <h6>Payment Method</h6>
            {isDelivery && paymentModes
              ? paymentModes
                  .filter((payItem) => payItem.is_delivery === "1")
                  .map((payItem) => {
                    return (
                      <div key={payItem.pid}>
                        <label htmlFor={`payment-method-${payItem.pid}`}>
                          {payItem.name}
                        </label>
                        <input
                          type="radio"
                          name="payment-method"
                          value={payItem.name}
                          className="payment-method"
                          id={`payment-method-${payItem.pid}`}
                          onClick={(e) => setPaymentMode(e.target.value)}
                        />
                      </div>
                    );
                  })
              : null}
            {isPickup && paymentModes
              ? paymentModes
                  .filter((payItem) => payItem.is_pickup === "1")
                  .map((payItem) => {
                    return (
                      <div key={payItem.pid}>
                        <label htmlFor={`payment-method-${payItem.pid}`}>
                          {payItem.name}
                        </label>
                        <input
                          type="radio"
                          name="payment-method"
                          value={payItem.name}
                          className="payment-method"
                          id={`payment-method-${payItem.pid}`}
                          onClick={(e) => setPaymentMode(e.target.value)}
                        />
                      </div>
                    );
                  })
              : null}
          </div>
          {paymentMode === "Paypal" && paymentModes ? (
            <PayPalScriptProvider options={paypalInitialOptions}>
              <Paypal
                canSubmit={canSubmit}
                btnOpacity={btnOpacity}
                setIsCartUpdated={props.setIsCartUpdated}
                price={getDecimalRupees(
                  cartTotal + parseFloat(props.deliveryPrice) - totalDiscount
                )}
              />
            </PayPalScriptProvider>
          ) : null}
          {paymentMode === "cash" ? (
            <button
              className="checkout-payment-btn"
              disabled={!canSubmit}
              style={{ opacity: btnOpacity }}
              onClick={(e) => submitOrder(e)}
            >
              Place an Order
            </button>
          ) : null}
          {(paymentMode === "Stripe" || paymentMode === "card") &&
          stripePromise ? (
            <Elements stripe={stripePromise}>
              <StripeForm
                secretKey={
                  paymentModes.filter((mode) => mode.name === "Stripe")[0]
                    .credential.secretkey
                }
                price={
                  cartTotal + parseFloat(props.deliveryPrice) - totalDiscount
                }
                currencyCode={cartBusiness.currency_code}
                apiUrl={props.config.api.url}
                paymentMethod={paymentMode}
                canSubmit={canSubmit}
                btnOpacity={btnOpacity}
              />
            </Elements>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default withRouter(Checkout);
