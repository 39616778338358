import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import { Container, Col } from "react-bootstrap";

// img
import img1 from '../../assets/img/registartion1.png';
import img2 from '../../assets/img/registartion2.png';

import SignUp from "../modal/SignUp"
import AddBusiness from "../modal/AddBusiness"

import Skeleton from 'react-loading-skeleton';


function OurRegistration(props) {
    const [language, setLang] = useState(null);  
  const [siteimages, setSite] = useState([]);
   const getStarts = async () => {
        const url = props.api_url + 'api/siteimages';
        const response = await fetch(url);
        const res = await response.json();
        let data = res.data;

        //return data;

        setSite(data);
   };

    if(siteimages.length === 0){

        getStarts();
    }

    useEffect(() => {
        setTimeout(function(){
            let config_data = props.config;
           setLang(config_data.LANGUAGE[config_data.LANG_ID]);
            
        },2000)
         
    }, [props.config,language]);

    const [modelShow, setSignUP] = useState(false);

    function signInShow_f(){
        setSignUP(true);
    }

    function signInClose_f(){
        setSignUP(false); 
    }


    return (

        <section className="our-registration">
            <Container fluid>
                <Col>
                   
                    {siteimages?.map((banner) => {
                        return (
                            <div key="1" className="res-cont row">
                                <div  className="items col-md-6 col-sm-6">
                                    {
                                    banner.create_account_banner !== ''
                                    ?
                                    <img src={banner.create_account_banner} alt="Menuhuts" />
                                    :
                                    <img src={img1} alt="Menuhuts" />

                                    }    
                                    
                                    <div className="description">
                                        <h3>{banner.create_account_title}</h3>
                                        <SignUp config={props.config} ref_from="home" signInShow={signInShow_f} signInClose={signInClose_f} api_url = {props.config.api.url}/>
                                    </div>
                                </div>

                                <div  className="items col-md-6 col-sm-6">
                                    {
                                        banner.business_account_banner !== ''
                                        ?
                                        <img src={banner.business_account_banner} alt="Menuhuts" />
                                        :
                                        <img src={img2} alt="Menuhuts" />

                                        }    
                                    <div className="description">
                                        <h3>{banner.business_account_title}</h3>

                                        <AddBusiness ref_from="home" config={props.config}/>

                                    </div>
                                </div>
                           </div>
                        );    
                       

                     })  
                                
                    }  
                   
                   
                </Col>
            </Container>
        </section> 

    );
}

export default OurRegistration;