import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const SelectAreaModal = ({
  showModal,
  availableAreas,
  handleClose,
  setIsSelectedAreaModified,
  currentBusiness,
  setIsCartUpdated,
}) => {
  const [selectedArea, setSelectedArea] = useState(availableAreas[0]);

  const handleSubmit = () => {
    sessionStorage.setItem("selectedArea", selectedArea);
    sessionStorage.setItem("cartBusiness", JSON.stringify(currentBusiness));
    setIsCartUpdated(true);
    setIsSelectedAreaModified(true);
  };

  useEffect(() => {
    setSelectedArea(availableAreas[0]);
  }, [availableAreas]);

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Where are you?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <select
            onChange={(e) => setSelectedArea(e.target.value)}
            className="select-area-modal-select"
          >
            {availableAreas.map((area, index) => (
              <option key={index} value={area}>
                {area}
              </option>
            ))}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setSelectedArea(null);
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleSubmit();
              handleClose();
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectAreaModal;
