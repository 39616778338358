class Auth {
    constructor() {
        this.authenticated = false;
    }
    login() {
        this.authenticated = true;
    }
    logout(cb) {
        this.authenticated = false;
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('userName');
        sessionStorage.removeItem('userLastName');        
        cb();
    }
    isAuthenticated() {
        if(sessionStorage.getItem('userId')) {
            this.authenticated = true;
            return true;
        } else {
            this.authenticated = false;
            return false;
        }
        
    }
    authResult() {
        return {
            id: sessionStorage.getItem('userId'),
            name: sessionStorage.getItem('userName'),
            last_name: sessionStorage.getItem('userLastName')
        }
    }
}

export default new Auth();