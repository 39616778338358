import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

// Own components
import Banner from "./Banner";
import NearResturant from "./NearResturant";
import GetApp from "./GetApp";
import Cities from "./Cities";
import OurRegistration from "./OurRegistration";
import Stats from "./Stats";

import { useCountriesFetch, IPAddressFetch } from "../../components/fetch";
import RecoveryPassword from "../modal/RecoveryPassword";

const Home = (props) => {
  let config_data = props.config;
  let language = config_data.LANGUAGE[config_data.LANG_ID];

  const [recovery, setRecovery] = useState(false);
  const [recovery_id, setId] = useState("");
  const [call_star, setStar] = useState(false);
  const [Our_Registration, setReg] = useState(false);
  const [citiy, setCities] = useState(false);
  const [Near, setNear] = useState(false);

  const [countries, setAllCountry] = useState([]);

  useEffect(() => {
    const query = window.location.pathname;
    const pathArr = query.split("/");

    if (pathArr[1] === "recovery" && pathArr[2] !== "") {
      setId(pathArr[2]);
      setRecovery(true);
    } else {
      setId("");
      setRecovery(false);
    }

    setTimeout(function () {
      setStar(true);
      setReg(true);
      setCities(true);
      setNear(true);
    }, 1500);
    setTimeout(getCountryCity, 1500);
  }, [recovery]);

  useEffect(() => {
    // fetch user details
  }, []);

  //const { countries } = useCountriesFetch(props.config.api.url + 'api/allCountry');
  const { ip } = IPAddressFetch("https://jsonip.com");

  async function getCountryCity() {
    let config_data = props.config;

    let res = await fetch(props.config.api.url + "api/countrycity/country", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        langId: sessionStorage.getItem("langId"),
      }),
    });
    let result = await res.json();

    if (result.success === true) {
      setAllCountry(result.data);
    }
  }

  return (
    <>
      {recovery === true ? (
        <RecoveryPassword
          id={recovery_id}
          config={props.config}
          api_url={props.config.api.url}
        />
      ) : (
        ""
      )}

      <Banner config={props.config} />
      {countries.length > 0 && ip.country ? (
        Near === true ? (
          <NearResturant countries={countries} ip={ip} config={props.config} />
        ) : (
          ""
        )
      ) : (
        <section className="near-resturants">
          <Container fluid>
            <Col>
              <h1 className="container-heading">
                <Skeleton width={"50%"} height={30} />
              </h1>

              <Row>
                {[...Array(3)].map((item, i) => (
                  <div className="item col-lg-4 col-md-6 col-sm-12" key={i}>
                    <div className="rest-cover">
                      <Skeleton width={"100%"} height={220} />
                    </div>

                    <div className="rest-description">
                      <div className="destails">
                        <h3>
                          <Skeleton width={"100%"} height={24} />
                        </h3>
                      </div>
                    </div>
                  </div>
                ))}
              </Row>
            </Col>
          </Container>
        </section>
      )}
      <GetApp api_url={props.config.api.url} config={props.config} />
      {countries.length > 0 && ip.country ? (
        citiy === true ? (
          <Cities
            countries={countries}
            country_code={ip.country_code}
            config={props.config}
          />
        ) : (
          ""
        )
      ) : (
        <section className="choose-city">
          <Container fluid>
            <Col>
              <div className="city-heading">
                <h1 className="container-heading">
                  <Skeleton width={"50%"} height={30} />
                </h1>
              </div>

              <ul className="city-list row">
                {[...Array(12)].map((item, i) => (
                  <li key={i} className="col-md-3 col-sm-6">
                    <Skeleton height={40} width={344} />
                  </li>
                ))}
              </ul>
            </Col>
          </Container>
        </section>
      )}
      {Our_Registration === true ? (
        <OurRegistration api_url={props.config.api.url} config={props.config} />
      ) : (
        <section className="our-registration">
          <Container fluid>
            <Col>
              <div key="1" className="res-cont row">
                <div className="items col-md-6 col-sm-6">
                  <Skeleton width={"100%"} height={300} />

                  <div className="description">
                    <h3>
                      <Skeleton width={"100%"} height={24} />
                    </h3>
                  </div>
                </div>

                <div className="items col-md-6 col-sm-6">
                  <Skeleton width={"100%"} height={300} />
                  <div className="description">
                    <h3>
                      <Skeleton width={"100%"} height={24} />
                    </h3>
                  </div>
                </div>
              </div>
            </Col>
          </Container>
        </section>
      )}
      {call_star === true ? (
        <Stats api_url={props.config.api.url} config={props.config} />
      ) : (
        <section className="stats">
          <Container fluid>
            <Col>
              <div className="stats-cont">
                <div className="item">
                  <Skeleton width={"100%"} height={100} />
                </div>

                <div className="item">
                  <Skeleton width={"100%"} height={100} />
                </div>

                <div className="item">
                  <Skeleton width={"100%"} height={100} />
                </div>

                <div className="item">
                  <Skeleton width={"100%"} height={100} />
                </div>

                <div className="item">
                  <Skeleton width={"100%"} height={100} />
                </div>
              </div>
            </Col>
          </Container>
        </section>
      )}
    </>
  );
};

export default Home;
