import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Modal, Form, Button, } from 'react-bootstrap';
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";

//custom alert

import CustomAlert from '../alert/CustomAlert'


function ForgotPassword(props) {

    const [show, setShow] = useState(false);

    //alert start

    const [alert, setAlert] = useState({
        type: null,
        text: [],
        show: false
      })
    
      function onCloseAlert(){
        setAlert({
          show: false,
          type: null,
          text: [],
        })
      }
    
      function onShowAlert(type,msg){
        setAlert({
          type: type,
          text: msg,
          show: true
        })
      }

    //alert end 

    const [language, setLang] = useState(null);

    const forgotPasswordClose = () => setShow(false);
    const forgotPasswordShow = () => setShow(true);

    const [open_err, setOpenErr] = useState(false);
    const [err_msg, setErrMsg] = useState("");

    const [email, setEmail] = useState("");

    useEffect(() => {

        setTimeout(function(){
            let config_data = props.config;
            setLang(config_data.LANGUAGE[config_data.LANG_ID]);
           
        },2000)
       
    }, [props.config,language]);

    function signInShow_props() {
        setShow(false);
        props.signInShow();
    }

    const handleToClose = (event, reason) => {
        if ("clickaway" === reason) return;
        setOpenErr(false);
      };

    /*function signInClose_props() {
        props.signInClose();
    }

    if(show){
        signInClose_props();
    }*/


    const getFormData = async () =>{

        let flg = true;
        const all_error_msg = [];
       
          if (email.trim() === '') {
            all_error_msg.push(language.ENTER_YOUR_EMAIL);

            if(flg === true){
                flg = false;
            }
          }
          let atpos;
          atpos = email.indexOf('@');
          let dotpos;
          dotpos = email.lastIndexOf('.');
          if(email.trim() !== ''){
                if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= email.length) {
                    all_error_msg.push(language.ENTER_VALID_EMAIL_ADDRESS);

                    if(flg === true){
                        flg = false;
                    }
                }
            }

            if(flg === true){
        
                    try {
                        let res = await fetch(props.api_url + 'api/forgot', {
                            method: 'post',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                email: email,
                            })
                        });
                        let result = await res.json();
                        if (result.status) {
                        
                            setOpenErr(true);
                            setErrMsg(result.message);

                            all_error_msg.push(result.message);

                             onShowAlert('success',all_error_msg); 
                        
                            setEmail("");               

                            setTimeout(
                                function() {
                                    setShow(false);
                                }
                               ,
                                5000
                            );
                            setTimeout(
                                function() {
                                    onCloseAlert();  
                                }
                                ,
                                4000
                            );
                            
                        } else {
                           
                            all_error_msg.push(result.message);

                            onShowAlert('error',all_error_msg); 
                        }
                    }
                    catch (e) {
                    console.log(e);
                    }
                }else{
                    onShowAlert('error',all_error_msg);  
                }       

         
    }

    
    return (
        <>
            <Link to={`#`} onClick={forgotPasswordShow}>{language != null ? language.FORGOT : ''}</Link>
            <Modal show={show} onHide={forgotPasswordClose}>
                <Modal.Header closeButton>
                    <h1 className="modal-title"><span>{language != null ? language.FORGOT_H : ''}</span> {language != null ? language.PASSWORD : ''}</h1></Modal.Header>
                <Modal.Body>
                <CustomAlert
                    header={language != null ? language.FORGOT_PASSWORD_BT : ''}
                    btnText={language != null ? language.ACCEPT : ''}
                    text={alert.text}
                    type={alert.type}
                    show={alert.show}
                    onClosePress={onCloseAlert}
                    pressCloseOnOutsideClick={true}
                    alertStyles={{}}
                    headerStyles={{}}
                    textStyles={{}}
                    buttonStyles={{}}
                    />       
                <Snackbar
                        anchorOrigin={{
                        horizontal: "center",
                        vertical: "top",
                        }}
                        open={open_err}
                        autoHideDuration={5000}
                        message={err_msg}
                        onClose={handleToClose}
                        action={
                        <React.Fragment>
                            <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleToClose}
                            >
                            <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                        }
                    />
                    <Form>
                        <Form.Group>
                            { /*<Form.Label>{language != null ? language.EMAIL : ''}</Form.Label> */ }
                            <Form.Control type="text" className="box" id="" placeholder={language != null ? language.EMAIL : ''} onChange={(e)=>setEmail(e.target.value)}/>
                        </Form.Group>

                        <Form.Group>
                            <Button variant="secondary" type="button" className="box" onClick={getFormData}>{language != null ? language.FORGOT_PASSWORD_BT : ''}</Button>
                        </Form.Group>

                        <Form.Group className="d-flex justify-content-center">
                            <span>{language != null ? language.ALREADY_HAVE_ACCOUNT : ''} <Link className="secondary" to={`#`} onClick={signInShow_props}>{language != null ? language.SIGN_IN : ''}</Link></span>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ForgotPassword;