import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Modal, Form, Button, Row, Col, Table } from 'react-bootstrap';


// img
import logo1 from '../../assets/img/rest-logo1.png';

import mirchiImage1 from '../../assets/img//mirchi.png';
import mirchiImage2 from '../../assets/img//mirchi2.png';
import mirchiImage3 from  '../../assets/img//mirchi3.png';


function OrderDetails(props) {

    const [show, setShow] = useState(false);

    const OrderDetailClose = () => setShow(false);
    const OrderDetailShow = () => setShow(true);

    const [language, setLang] = useState(null);
    const [loding, setLoding] = useState(true);
    const [orderDetails, setOrderDetails] = useState(props.order);

    const [cancelBtn, setCancelBtn] = useState(false);
    let dateb = "";
    let now = "";
    let startDate = "";


    useEffect(() => {
        setTimeout(function(){
            let config_data = props.config;
            setLang(config_data.LANGUAGE[config_data.LANG_ID]);
            setOrderDetails(props.order);
            
            dateb = new Date().toLocaleString('en-US', {
            timeZone: orderDetails.timezone_code
            });
            now = new Date(dateb);

            if (orderDetails.laundryservice === '1') {
            startDate = new Date(orderDetails.laundryservicedetails.pickupDate + ' ' + orderDetails.laundryservicedetails.pickupTime)
            }
           // console.log(startDate);
           // console.log(now);
            let diffTime;
            diffTime = ((startDate - now) / 1000) / 3600;
            if (diffTime > orderDetails.cancellationtime && orderDetails.status === '0') {
                setCancelBtn(true);
            } else {
                setCancelBtn(false);
            }

        },2000)
   
   }, [props.config,language,props.order]);

   
    let line_cut = "";
    if(orderDetails.reward_wallet == 1){
        line_cut = "line-cut";
    } 

    async function onCancelClick() {
        alert(orderDetails.id);
            let res = await fetch(props.config.api.url + 'api/order/order', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    f: 'cancelorder',
                    orderId: orderDetails.id,
                    langId : sessionStorage.getItem('langId'),
                })
            });
            let result = await res.json();
            
            if (result) {
                setShow(false);
                props.getOrderHistory_r();
            } 

      }

    return (       
            
        <>

            <Link to={`#`} onClick={OrderDetailShow}>View Receipt</Link>

            <Modal show={show} onHide={OrderDetailClose}>
                <Modal.Header closeButton>
                    <h1 className="modal-title"><span>Order</span> Details {cancelBtn}</h1>
                </Modal.Header>
                <Modal.Body>

                    <div className="new-pop">
                        <h1>{language != null ? language.ORDER_ID : ''} # {orderDetails.id}  <small className="order_status">({orderDetails.statustext})</small></h1>
                        <div className="order-bdy">

                                <div className="simplebar_orderdetails">

                                    {
                                      orderDetails.order_type == '1' ?
                                      <h2>
                                      {language != null ? language.ESTIMATED_DELIVERY_TIME : ''} <span className="est-time">{orderDetails.estimate_time}</span>
                                      </h2>
                                      : ""
                                    }
                                    {
                                      orderDetails.order_type == '2' ? 
                                      <h2>
                                      {language != null ? language.ESTIMATED_PICKUP_TIME : ''} <span className="est-time">{orderDetails.estimate_time}</span>
                                      </h2>
                                      
                                      : ""

                                      
                                    }
                                   
                                   
                                   <div className="sec-box">
                                        <div className="row ">
                                        <div className="col-md-6">
                                            <div className="mdl-dtls">
                                            <h4>{language != null ? language.FROM : ''}</h4>
                                            <h5>{orderDetails.bname}</h5>
                                            <p>{orderDetails.baddress}</p>
                                            <p>{orderDetails.bmobile}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mdl-dtls">
                                            <h4>{language != null ? language.TO : ''}</h4>
                                            <h5>{orderDetails.buyerdata.name} {orderDetails.buyerdata.last_name}</h5>
                                            {
                                                orderDetails.order_type == '1' ?
                                                <p >{orderDetails.buyerdata.delievryaddress}</p>
                                                : ""
                                            }
                                            {
                                              orderDetails.order_type == '1' ?
                                              <p >{orderDetails.buyerdata.specialaddress}</p>
                                              : ""
                                            }
                                                                                        
                                            <p>{orderDetails.buyerdata.email}</p>
                                            <p>{orderDetails.buyerdata.cel}</p>
                                            </div>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="sec-box">
                                        <div className="row">
                                        <div className="col-md-6">
                                            <h4>{language != null ? language.ORDER_DATE_TIME : ''} :</h4>
                                            <p>{orderDetails.datetime}</p>
                                        </div>
                                        {
                                            orderDetails.laundryservice == '0' ? 
                                            <div className="col-md-6" >
                                                <h4>{language != null ? language.PREORDER_DATE_TIME : ''} :</h4>
                                                {
                                                    orderDetails.buyerdata.preorder === true ? 
                                                    <p >{orderDetails.buyerdata.preorderDate} - {orderDetails.buyerdata.preorderTime}</p>
                                                    : ""
                                                }
                                                {
                                                   orderDetails.buyerdata.preorder === false ?
                                                   <p >{language != null ? language.ASAP : ''}</p>
                                                   : ""

                                                }
                                                
                                                
                                            </div>
                                            : ""
                                        }
                                        
                                        </div>
                                    </div>

                                    <div className="sec-box">
                                        {
                                            orderDetails.laundryservice == '1' ? 
                                            <div className="row" >
                                                <div className="col-md-6">
                                                    <h4>{language != null ? language.PICKUP_DATE_TIME : ''} :</h4>
                                                    <p>{orderDetails.laundryservicedetails.pickupDate} {orderDetails.laundryservicedetails.pickupTime}</p>
                                                </div>
                                                <div className="col-md-6">
                                                    <h4>{language != null ? language.DELIVERY_DATE_TIME : ''} :</h4>
                                                    <p>{orderDetails.laundryservicedetails.deliveryDate} {orderDetails.laundryservicedetails.deliveryTime}
                                                    </p>
                                                </div>
                                            </div>
                                            : ""
                                        }
                                        
                                    </div>

                                    {
                                        orderDetails.comments !='' || orderDetails.drivercomments !='' || orderDetails.buyercomments !='' ?

                                        <div className="row">

                                            {
                                                orderDetails.comments !='' ? 
                                                <div className="col-md-6" >
                                                    <h4>{language != null ? language.COMMENTS : ''} :</h4>
                                                    <p>{orderDetails.comments}</p>
                                                </div>
                                                : ""
                                            }
                                            
                                            {
                                                orderDetails.drivercomments !='' ?
                                                <div className="col-md-6" >
                                                    <h4>{language != null ? language.DRIVER_COMMENTS : ''} :</h4>
                                                    <p>{orderDetails.drivercomments}</p>
                                                </div>
                                                : ""
                                            }

                                            {
                                                orderDetails.buyercomments !='' ? 
                                                <div className="col-md-6" >
                                                    <h4>{language != null ? language.USER_COMMENTS : ''} :</h4>
                                                    <p>{orderDetails.buyercomments}</p>
                                                </div>
                                                : ""
                                            }
                                                                                        
                                         </div>
                                        : ""
                                    }

                                    <div className="row">
                                        <div className="col-md-12">
                                            
                                            <DishData dishdata_arr={orderDetails.dishdata} orderDetails={orderDetails} language={language}></DishData>

                                            <div className="price-cht">
                                                <Table width="100%" border="0" cellSpacing="0" cellPadding="0" className="confirmation_tbl">
                                                <tbody>
                                                <tr key={'1'}>
                                                    <td colSpan="2">{language != null ? language.ORDER_TEMPLATE_SUB_TOTAL : ''}</td>
                                                    <td><strong>{orderDetails.currency_symbol}{orderDetails.subtotal}</strong>
                                                    </td>
                                                </tr>
                                                {
                                                    orderDetails.extraminimum > 0 ? 
                                                        <tr key={'2'}>
                                                            <td colSpan="2">{language != null ? language.EXTRA_CHARGE_MINIMUM : ''}</td>
                                                            <td><strong>{orderDetails.currency_symbol}{orderDetails.extraminimum}</strong>
                                                            </td>
                                                        </tr>
                                                    : null
                                                }
                                                {
                                                    orderDetails.order_type == '1' ? 
                                                    <tr key={'3'}>
                                                        <td colSpan="2">{language != null ? language.DELIEVRY_FEE : ''}</td>
                                                        <td>
                                                            {
                                                                orderDetails.deliverycost > 0 ? 
                                                                <strong>{orderDetails.currency_symbol}{orderDetails.deliverycost}</strong>
                                                                : ""
                                                            }
                                                            {
                                                                orderDetails.deliverycost == 0 ? 
                                                                <strong className="delivery-free-text">{language != null ? language.FREE : ''}</strong>
                                                                : ""
                                                            }
                                                        </td>
                                                    </tr>
                                                    : null
                                                }
                                                <tr key={'4'}>
                                                    <td colSpan="2">{language != null ? language.ORDER_TEMPLATE_TAX : ''} ({orderDetails.tax}%)
                                                    {
                                                        orderDetails.taxtype == 0 ?
                                                        <span >{language != null ? language.TAX_NOT_INCLUDED : ''} </span>
                                                        : null
                                                    }
                                                    {
                                                        orderDetails.taxtype == 1 ? 
                                                        <span>{language != null ? language.TAX_INCLUDED : ''}</span>
                                                        : null
                                                    }
                                                    </td>
                                                    <td><strong>{orderDetails.currency_symbol}{orderDetails.tax_price}</strong></td>
                                                </tr>
                                                {
                                                    orderDetails.servicefee_price > 0 ? 
                                                    <tr key={'5'}>
                                                        <td colSpan="2">{language != null ? language.SERVICE_FEE : ''} ({orderDetails.servicefee}%)</td>
                                                        <td><strong>{orderDetails.currency_symbol}{orderDetails.servicefee_price}</strong>
                                                        </td>
                                                    </tr>
                                                    : null
                                                }
                                                {
                                                    orderDetails.tips > 0 ? 
                                                    <tr key={'6'}>
                                                        <td colSpan="2">{language != null ? language.TIPS : ''}</td>
                                                        <td><strong>{orderDetails.currency_symbol}{orderDetails.tips}</strong>
                                                        </td>
                                                    </tr>
                                                    : null
                                                }
                                                {
                                                        orderDetails.discount > 0 ? 
                                                        <tr  key={'7'} className="clr-red">
                                                            <td colSpan="2">{language != null ? language.ORDER_TEMPLATE_DISCOUNT : ''}</td>
                                                            <td><strong>{orderDetails.currency_symbol}{orderDetails.discount}</strong>
                                                            </td>
                                                        </tr>
                                                        : null
                                                }
                                                {
                                                orderDetails.firstoffer > 0 ? 
                                                   <tr key={'8'} className="clr-red">
                                                        <td colSpan="2">{language != null ? language.DISCOUNT_FOR_FIRST_ORDER : ''}</td>
                                                        <td><strong>{orderDetails.currency_symbol}{orderDetails.firstoffer}</strong>
                                                        </td>
                                                    </tr>
                                                : null
                                                }
                                                <tr key={'9'} className={line_cut}>
                                                    <td colSpan="2" className="total_text">{language != null ? language.ORDER_TEMPLATE_TOTAL : ''}</td>
                                                    <td className="total_price_text">
                                                    <strong>
                                                        {orderDetails.currency_symbol}{orderDetails.total}
                                                    </strong>
                                                    </td>
                                                </tr>
                                                {
                                                    orderDetails.reward_wallet == 1 ? 
                                                    <tr key={'10'} className="reward-cart">
                                                        <td colSpan="2" className="total_text">{language != null ? language.PAY_BY_REWARDS : ''}</td>
                                                        <td className="total_price_text">
                                                        <strong>
                                                            {orderDetails.currency_symbol}{orderDetails.reward_json.deductPrice}
                                                        </strong>
                                                        </td>
                                                    </tr>
                                                    : null
                                                }
                                                {
                                                    orderDetails.reward_wallet == 1 ? 
                                                    <tr  key={'11'} className="reward-cart">
                                                        <td colSpan="2" className="total_text">{language != null ? language.ORDER_TEMPLATE_TOTAL : ''}</td>
                                                        <td className="total_price_text">
                                                        <strong>
                                                            {orderDetails.currency_symbol}{orderDetails.reward_json.amountPrice}
                                                        </strong>
                                                        </td>
                                                    </tr>
                                                    : null
                                                }
                                                </tbody>
                                                </Table>
                                            </div>



                                       </div>
                                   </div>                   




                                   



                                </div>
                            
                        </div>

                        {
                                cancelBtn === false ? 

                                <div className="flx-btn" >
                                    <Button  onClick={onCancelClick} className="sbm-btn cmn-button cncl-btn">{language != null ? language.CANCEL_ORDER : ''}</Button>
                                </div>

                                : ""

                        }

                        
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const DishData = React.memo(({ dishdata_arr, orderDetails, language}) => {

    return (
        <>
       
            
         {dishdata_arr.map((dishdata) => {

            let data_dishdata = dishdata.data;

                return(

                    data_dishdata.map((dish) => {

                        return(

                            <div key={dish.id} className="add-item" >
                                <h6>{dish.quantity} X {dish.name}
                                    {
                                    dish.spicy && dish.spicy === '1' ?
                                    <span >
                                        {
                                        dish.spicyquantity === '1' ?
                                        <img src={mirchiImage1} alt=""/>
                                        :  dish.spicyquantity === '2' ?
                                        <img src={mirchiImage2} alt=""/>
                                        : dish.spicyquantity === '3' ?
                                        <img src={mirchiImage3} alt=""/>
                                        : ""
                                        }
                                        
                                        </span>
                                    : ""
                                    }
                                
                                    <span className="price-flt-ryt">{orderDetails.currency_symbol}{dish.total}</span>
                                </h6>

                                <div className="iten-add-on">
                                     {
                                        dish.activeIngredients && dish.activeIngredients.length > 0 ? 

                                            <div className="iten-add-ons" >
                                              <label className="add-svg"><span>
                                                <svg version="1.1" id="Capa_1"  x="0px" y="0px" viewBox="0 0 512 512"
                                                style="enable-background:new 0 0 512 512;" >
                                                <g>
                                                    <g>
                                                    <polygon
                                                        points="276,236 276,0 236,0 236,236 0,236 0,276 236,276 236,512 276,512 276,276 512,276 512,236 "/>
                                                    </g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                <g>
                                                </g>
                                                </svg>
                                               </span> {language != null ? language.INGREDIENTS : ''} - {dish.activeIngredientsText}</label>
                                           </div>

                                        : ""
                                       } 

                                       { 
                                         dish.sets.map((set) => {  
                                            
                                            return(
                                              <div className="iten-add-ons" >
                                                    <label className="add-svg"><span>
                                                        <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512"
                                                        style="enable-background:new 0 0 512 512;" >
                                                        <g>
                                                            <g>
                                                            <polygon
                                                                points="276,236 276,0 236,0 236,236 0,236 0,276 236,276 236,512 276,512 276,276 512,276 512,236 " />
                                                            </g>
                                                        </g>
                                                        <g>
                                                        </g>
                                                        <g>
                                                        </g>
                                                        <g>
                                                        </g>
                                                        <g>
                                                        </g>
                                                        <g>
                                                        </g>
                                                        <g>
                                                        </g>
                                                        <g>
                                                        </g>
                                                        <g>
                                                        </g>
                                                        <g>
                                                        </g>
                                                        <g>
                                                        </g>
                                                        <g>
                                                        </g>
                                                        <g>
                                                        </g>
                                                        <g>
                                                        </g>
                                                        <g>
                                                        </g>
                                                        <g>
                                                        </g>
                                                        </svg>
                                                    </span> {set.name} - {set.choiceText}</label>
                                               </div>
                                            )
                                          })
                                        }

                                          
                                        {
                                            dish.notes != '' ? 
                                            <div className="iten-add-ons" >
                                                <p>{dish.notes}</p>
                                            </div>
                                            : ""
                                        }
                                           

                                        </div>


                            </div>

                        )
                        
                    })


                )    

            })
        }
                          


        </>
    )
})

export default OrderDetails;